import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const FormLabel = (props: { className: string; label: string; tooltip: string } & any) => {
  return (
    <p className={`mb-1 mt-3 d-flex flex-row justify-content-start align-items-center ${props.className}`} {...props}>
      <Tooltip placement="topRight" title={props.tooltip}>
        <InfoCircleOutlined />
      </Tooltip>
      <strong className="mx-3">{props.label}</strong>
    </p>
  )
}
