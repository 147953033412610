import Drawer from 'antd/lib/drawer'
import { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SiteDrawer } from '../../pages/sites/SiteDrawer'

export const SiteEditDrawer = ({ projectId, maxDistance, maxDistanceTravel }: any) => {
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    setVisible(true)
  }, [])

  const onClose = () => {
    setVisible(false)
  }
  const layoutChange = (e) => {
    if (!e) navigate('/project/' + projectId)
  }

  return (
    <Drawer
      afterVisibleChange={layoutChange}
      title="Edit Site"
      destroyOnClose={true}
      width={'40%'}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <SiteDrawer
        closeDrawer={onClose}
        projectId={projectId}
        maxDistance={maxDistance}
        maxDistanceTravel={maxDistanceTravel}
      ></SiteDrawer>
    </Drawer>
  )
}
