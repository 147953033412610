import { useRef, useEffect } from 'react'

export interface TextInputProps {
  autoFocus?: boolean
  value: string
  placeholder: string
  onChange: (s: string) => void
}

export function TextInput({ placeholder, onChange, value, autoFocus }: TextInputProps) {
  const inputRef = useRef<any>(null)

  const textInputStyle = {
    width: '90%',
    maxWidth: '600px',
    fontSize: '1.5rem',
    paddingBottom: '.2rem',
    backgroundColor: 'transparent',
    outline: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: '1px solid black',
  }

  useEffect(() => {
    if (autoFocus) inputRef.current.focus()
  }, [autoFocus])

  return (
    <input
      type="text"
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || ''}
      style={textInputStyle}
      value={value}
      ref={inputRef}
    />
  )
}
