import { InfoQuestion, LanguageISOCode } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionTitle } from './QuestionTitle'
import { QuestionBody } from './QuestionBody'
import { useState } from 'react'

export interface BirthdateQuestionFormProps {
  language: LanguageISOCode
  question: InfoQuestion
  onSubmit: (answer: string) => void
}

export function BirthdateQuestionForm({ language, question, onSubmit }: BirthdateQuestionFormProps) {
  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>

      <DatePicker onSubmit={onSubmit} question={question} language={language} />
    </div>
  )
}

function DatePicker({ onSubmit, question, language }) {
  const [date, setDate] = useState('01')
  const [month, setMonth] = useState('01')
  const [year, setYear] = useState('1900')
  const [touched, setTouched] = useState([false, true, true]) // one for each field
  const [errHighlight, setErrHighlight] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const [YEAR, MONTH, DAY] = [0, 1, 2]
  const minYear = 1900
  const curYear = new Date(Date.now()).getFullYear()
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const monthAbbrev = ['jan.', 'feb.', 'mar.', 'apr.', 'may', 'jun.', 'jul.', 'aug.', 'sep.', 'oct.', 'nov.', 'dec.']

  const getDaysInMonth = () => {
    const mo = parseInt(month)
    if (isNaN(mo)) {
      return 31
    }

    const yr = parseInt(year)
    if (isNaN(yr)) {
      return daysInMonth[mo - 1]
    }

    const isLeapYr = yr % 4 === 0
    if (mo === 2 && isLeapYr) {
      return 29
    }

    return daysInMonth[mo - 1]
  }

  const handleSubmit = () => {
    const allTouched = touched.every((fieldIsTouched) => fieldIsTouched)
    const noneTouched = touched.every((fieldIsTouched) => !fieldIsTouched)

    if (!question.required && noneTouched) {
      onSubmit('')
      setErrorMsg('')
      return
    } else if (noneTouched) {
      setErrorMsg('Please pick a date')
      setErrHighlight(true)
      return
    } else if (!noneTouched && !allTouched) {
      setErrorMsg('Please fill out your year of birth')
      setErrHighlight(true)
      return
    } else if (allTouched) {
      // combine to string as such: 'YYYY-MM-DD'
      const collatedValue = `${year.length === 0 ? '1900' : year}-${month.length === 0 ? '01' : month}-${
        date.length === 0 ? '01' : date
      }`

      onSubmit(collatedValue)
    }
  }

  const onDateChange = (newDate: string) => {
    const touchedUpdate = [...touched]
    touchedUpdate[DAY] = true
    setTouched(touchedUpdate)
    setDate(newDate)
  }

  const onMonthChange = (newMonth: string) => {
    const touchedUpdate = [...touched]
    touchedUpdate[MONTH] = true
    setTouched(touchedUpdate)
    setMonth(newMonth)
  }

  const onYearChange = (newYear: string) => {
    const touchedUpdate = [...touched]
    touchedUpdate[YEAR] = true
    setTouched(touchedUpdate)
    setYear(newYear)
  }

  const selectStyle = {
    padding: '.4em 2em .4em 1em',
    width: 'auto',
    borderRadius: '3px',
    fontSize: '1.1em',
    cursor: 'pointer',
  }

  const selectStyleMissing = {
    ...selectStyle,
    border: '2px solid red',
  }

  // Rendered style
  const yearStyle = {
    ...(errHighlight && !touched[YEAR] ? selectStyleMissing : selectStyle),
    color: touched[YEAR] ? 'black' : 'gray',
  }

  const dayStyle = {
    ...(errHighlight && !touched[DAY] ? selectStyleMissing : selectStyle),
    color: touched[DAY] ? 'black' : 'gray',
  }

  const monthStyle = {
    ...(errHighlight && !touched[MONTH] ? selectStyleMissing : selectStyle),
    color: touched[MONTH] ? 'black' : 'gray',
  }

  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        <select className="form-select" onChange={(e) => onYearChange(e.target.value)} style={yearStyle}>
          {Array.from(Array(curYear - minYear + 1).keys()).map((i: number) => (
            <option key={i} value={i + minYear}>
              {i + minYear}
            </option>
          ))}
        </select>
        &mdash;
        <select className="form-select" onChange={(e) => onMonthChange(e.target.value)} style={monthStyle}>
          {Array.from(Array(12).keys()).map((i: number) => {
            let label = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`
            return (
              <option key={label} value={label}>
                {monthAbbrev[i]}
              </option>
            )
          })}
        </select>
        &mdash;
        <select className="form-select" onChange={(e) => onDateChange(e.target.value)} style={dayStyle}>
          {Array.from(Array(getDaysInMonth()).keys()).map((i: number) => {
            let label = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`
            return (
              <option key={label} value={label}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={handleSubmit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </>
  )
}
