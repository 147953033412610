import { useEffect } from 'react'
import { FormLabel } from './FormLabel'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */
export const FormTextField = ({ data, formHook }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]

  const errorMessage = errorState && errorState.message ? errorState.message : data.errorMsg

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.id, data.value, setValue])

  return (
    <div className="w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <input
        className="ant-input"
        autoComplete="nope"
        placeholder={data.placeholder}
        key={data.id}
        {...register(data.id, data.validation)}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
