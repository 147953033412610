import { InfoQuestion, LanguageISOCode } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionTitle } from './QuestionTitle'
import { QuestionBody } from './QuestionBody'

export interface InfoQuestionFormProps {
  language: LanguageISOCode
  question: InfoQuestion
  onSubmit: (answer: string) => void
  isEndScreen?: boolean
}

export function InfoQuestionForm({ language, question, onSubmit, isEndScreen }: InfoQuestionFormProps) {
  return (
    <div className="w-100 d-flex flex-column justify-content-center">
      <div>
        <QuestionTitle>{question.title[language]}</QuestionTitle>
        <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>
        {isEndScreen ? (
          <></>
        ) : (
          <SubmitButton onClick={() => onSubmit('')}>
            {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
          </SubmitButton>
        )}
      </div>
    </div>
  )
}
