import { useEffect, useState } from 'react'
import Button from 'antd/lib/button'
import { LanguageISOCode, RadioQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'
import { Checkbox, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const CheckboxCreateForm = (props: TypedQuestionCreateFormProps<RadioQuestion>) => {
  let defaultItem = props.defaultFormFieldConfig
  let { register, setValue } = props.formHook
  let [options, setOptions] = useState<any[]>(props.question?.options || [])
  let [includingOptions, setIncludingOptions] = useState<number[]>(props.question?.includingOptions || [])
  let [excludingOptions, setExcludingOptions] = useState<number[]>(props.question?.excludingOptions || [])

  let [newOptionVal, setNewOptionVal] = useState<any>(
    Object.fromEntries(props.languages.map((lang: LanguageISOCode) => [lang, '']))
  )

  const formItem = {
    id: 'options',
    type: 'TEXT',
    placeholder: 'Option text',
    label: 'Options',
    validation: { required: true },
    errorMsg: 'Enter a selectable options for the question',
    ...defaultItem,
  }

  const inclusionFieldName = 'includingOptions'
  const exclusionFieldName = 'excludingOptions'

  useEffect(() => {
    register(formItem.id)
    register(inclusionFieldName)
    register(exclusionFieldName)
  }, [register, formItem.id])

  useEffect(() => {
    setValue(formItem.id, options)
  }, [options, setOptions, setValue, formItem.id])

  useEffect(() => {
    setValue(inclusionFieldName, includingOptions)
  }, [includingOptions, setIncludingOptions, setValue, inclusionFieldName])

  useEffect(() => {
    setValue(exclusionFieldName, excludingOptions)
  }, [excludingOptions, setExcludingOptions, setValue, exclusionFieldName])

  const onOptionChange = (lang: LanguageISOCode) => (e: any) => {
    let valsByLang = { ...newOptionVal }
    valsByLang[lang] = e.target.value
    setNewOptionVal(valsByLang)
  }

  const addOption = (e: any) => {
    e.preventDefault()
    let newOptions = [...options, newOptionVal]
    setNewOptionVal(Object.fromEntries(props.languages.map((lang: LanguageISOCode) => [lang, ''])))
    setOptions(newOptions)
  }

  const onInclusionChange = (i: number) => (e: any) => {
    if (includingOptions.includes(i)) {
      setIncludingOptions(includingOptions.filter((x) => x !== i))
    } else {
      setIncludingOptions([...includingOptions, i])
      setExcludingOptions(excludingOptions.filter((x) => x !== i))
    }
  }

  const onExclusionChange = (i: number) => (e: any) => {
    if (excludingOptions.includes(i)) {
      setExcludingOptions(excludingOptions.filter((x) => x !== i))
    } else {
      setExcludingOptions([...excludingOptions, i])
      setIncludingOptions(includingOptions.filter((x) => x !== i))
    }
  }

  const removeOption = (index: number) => {
    if (options.length === 1) {
      setOptions([])
    } else {
      const newOptions = [...options]
      newOptions.splice(index, 1)
      setOptions(newOptions)
    }
  }

  return (
    <div className={formItem.className + ' mt-4'}>
      <div className="d-flex align-items-center">
        <Tooltip placement="topRight" title="Add the different possible choices with text for all supported languages.">
          <InfoCircleOutlined />
        </Tooltip>
        <label htmlFor="options" className="mx-3">
          <strong>Options*</strong>
        </label>
      </div>
      {options.map((opt, index) => (
        <div
          key={index}
          className="px-3 py-2 mt-3 mb-4"
          style={{
            borderRadius: 3,
            backgroundColor: 'rgba(0,0,0,.04)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
          }}
        >
          <p style={{ fontSize: '11px' }}>
            <strong>Option {index}</strong>
          </p>
          <div className="w-100 d-flex justify-content-between mt-2 mb-3">
            <div className="w-100">
              {props.languages.map((lang: LanguageISOCode, i: number) => (
                <p key={i} className="my-2 d-flex">
                  <strong style={{ width: '2.5em' }}>{lang}</strong>
                  {opt[lang]}
                </p>
              ))}
            </div>

            <Button danger onClick={(e) => removeOption(index)}>
              Remove
            </Button>
          </div>

          <div className="d-flex flex-row align-items-center">
            <Checkbox
              id={`exclusion${index}`}
              checked={excludingOptions.includes(index)}
              onChange={onExclusionChange(index)}
            />
            <label htmlFor={`exclusion${index}`} style={{ marginLeft: '1.3em' }}>
              <strong>Exclusion</strong>
            </label>
            <Tooltip
              className="mx-2"
              placement="topRight"
              title="Check if answering this option is an exclusion criterion!"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>

          <div className="d-flex flex-row align-items-center">
            <Checkbox
              id={`inclusion${index}`}
              checked={includingOptions.includes(index)}
              onChange={onInclusionChange(index)}
            />
            <label htmlFor={`inclusion${index}`} style={{ marginLeft: '1.3em' }}>
              <strong>Inclusion</strong>
            </label>
            <Tooltip
              className="mx-2"
              placement="topRight"
              title="Check this if answering this option is an inclusion criterion!"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
      ))}

      <div className="d-flex align-items-center">
        <Tooltip
          placement="topRight"
          title="Fill user facing text for the new option and click 'Add Option' to add a new option to the list."
        >
          <InfoCircleOutlined />
        </Tooltip>
        <p className="mx-3">
          <strong>Add another option</strong>
        </p>
      </div>
      <div className="mt-2 mb-2 d-flex flex-column">
        {props.languages.map((lang: LanguageISOCode, i: number) => {
          return (
            <div key={i} className="d-flex align-items-center mt-1" style={{ width: '100%' }}>
              <p style={{ minWidth: '2.5em' }}>
                <strong>{lang}</strong>
              </p>
              <input
                className="ant-input"
                placeholder={formItem.placeholder + ` (${lang})`}
                id={`options${i}`}
                value={newOptionVal[lang]}
                onChange={onOptionChange(lang)}
              />
            </div>
          )
        })}

        <Button style={{ marginLeft: '2.5em' }} className="mt-2" onClick={addOption}>
          <strong>Add Option</strong>
        </Button>
      </div>
    </div>
  )
}

export default CheckboxCreateForm
