import { getReq } from '../service/API'
import useSWR from 'swr'
import { getFiles_endpoint, getProjectSettings_endpoint, getProject_endpoint } from '../service/api-gateway'

export const useProject = (projectId: string) => {
  const endpoint = projectId ? getProject_endpoint(projectId) : null
  const { data, error, mutate } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}

export const useProjectAndPopulate = (projectId: string) => {
  const endpoint = projectId ? getProjectSettings_endpoint(projectId) : null
  const { data, error, mutate } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}

export const useProjectGetFiles = (projectId: string) => {
  const endpoint = projectId ? getFiles_endpoint(projectId) : null
  const { data, error, mutate } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
