import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import { Link } from 'react-router-dom'
import PageTitle from '../../components/header/PageTitle'
import { QuestionEditDrawer } from './QuestionEditDrawer'
import { PROJECT_PAGE_VIEW, DEFAULT_LANG } from '../../constants'
import { useQuestionnaireAPI } from '../../hooks/useQuestionnaire'
import { Question } from '../../Types'
import { useProject } from '../../hooks/useProject'

export const QuestionnairePageEdit = () => {
  const drawerWidth = '100%'
  const { projectId } = useParams() as { projectId: string }
  const { questionnaire, addQuestion, deleteQuestion, updateQuestion } = useQuestionnaireAPI()
  const { data } = useProject(projectId)
  const languages = data.languages

  const [createDrawerIsVisible, setCreateDrawerIsVisible] = useState<boolean>(false)
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null)
  const [editDrawerIsVisible, setEditDrawerIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (questionnaire.active === true)
      alert(
        'WARNING: You are about to edit an active questionnaire, you should only fix spelling mistakes or perform minor text alterations - otherwise duplicate the questionnaire to create a new version!'
      )
  }, [questionnaire])

  const handleError = (error: Error) => {
    console.log(' QuestionnairePageEdit > error = ', error.message, error)
    alert(error.message)
  }

  const onQuestionCreateSubmit = (question: Question) =>
    addQuestion(question)
      .then(() => {
        setCreateDrawerIsVisible(false)
      })
      .catch((err) => {
        if (err.type === 'FEEDBACK' || err.type === 'VALIDATION') {
          alert(err.message)
        } else {
          handleError(err)
        }
      })

  const onQuestionEditSubmit = (question: Question) =>
    updateQuestion(question)
      .then((response) => console.log(response))
      .then(() => {
        setEditDrawerIsVisible(false)
        setEditingQuestion(null)
      })
      .catch((err) => {
        if (err.type === 'FEEDBACK' || err.type === 'VALIDATION') {
          alert(err.message)
        } else {
          handleError(err)
        }
      })

  const onDeleteQuestion = (question: Question) => deleteQuestion(question).catch(handleError)

  return (
    <>
      <Drawer
        title="Add New Question"
        destroyOnClose={true}
        width={drawerWidth}
        placement="right"
        onClose={() => setCreateDrawerIsVisible(false)}
        visible={createDrawerIsVisible}
      >
        <QuestionEditDrawer
          closeDrawer={() => setCreateDrawerIsVisible(false)}
          handleSubmitQuestion={onQuestionCreateSubmit}
          languages={languages}
        />
      </Drawer>

      <Drawer
        title="Edit Question"
        destroyOnClose={true}
        width={drawerWidth}
        placement="right"
        onClose={() => setEditDrawerIsVisible(false)}
        visible={editDrawerIsVisible && editingQuestion !== null}
      >
        <QuestionEditDrawer
          question={editingQuestion || undefined}
          closeDrawer={() => setEditDrawerIsVisible(false)}
          handleSubmitQuestion={onQuestionEditSubmit}
          languages={languages}
        />
      </Drawer>

      <div className="container mt-3 mb-1">
        <PageTitle className="my-4">Edit Questions</PageTitle>

        <div className="row item-shadow p-2">
          <div className="p-3">
            <QuestionCard
              deletable={false}
              question={questionnaire.start}
              onDelete={() => onDeleteQuestion(questionnaire.start)}
              onEdit={() => {
                setEditingQuestion(questionnaire.start)
                setEditDrawerIsVisible(true)
              }}
            />

            {questionnaire.questions
              .filter(
                (question: Question) =>
                  question._id !== questionnaire.start._id && question._id !== questionnaire.end._id
              )
              .map((question: Question, index: number) => (
                <QuestionCard
                  key={index}
                  deletable={true}
                  question={question}
                  onDelete={() => onDeleteQuestion(question)}
                  onEdit={() => {
                    setEditingQuestion(question)
                    setEditDrawerIsVisible(true)
                  }}
                />
              ))}

            <QuestionCard
              deletable={false}
              question={questionnaire.end}
              onDelete={() => onDeleteQuestion(questionnaire.end)}
              onEdit={() => {
                setEditingQuestion(questionnaire.end)
                setEditDrawerIsVisible(true)
              }}
            />
          </div>

          {questionnaire.active === true ? (
            <></>
          ) : (
            <div className="col-12 d-flex justify-content-center mt-4">
              <Button size="large" className="w-100" onClick={() => setCreateDrawerIsVisible(true)} type="primary">
                Add New Question
              </Button>
            </div>
          )}
        </div>

        <div className="mt-5 mb-5">
          <Link to={PROJECT_PAGE_VIEW.to.replace(':projectId', questionnaire.projectId)}>
            <Button size="large" type="default" className="w-100">
              Return to Project
            </Button>
          </Link>
          <div style={{ height: '4em' }}></div>
        </div>
      </div>
    </>
  )
}

const QuestionCard = (props: {
  question: Question
  onEdit: any
  onDelete: any
  key?: any
  className?: string
  deletable?: boolean
}) => (
  <div
    style={{
      borderRadius: 3,
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    }}
    className={props.className}
  >
    <div className="p-4 mt-3 d-flex flex-row justify-content-between align-items-center">
      <div className="pl-2 " style={{ width: '40%' }}>
        <p className="mb-0 p-small alpha-80">{props.question.type}</p>
        <h5 className="mb-0 font-weight-bold">
          {props.question.title[DEFAULT_LANG] || Object.entries(props.question.title)[0][1]}
        </h5>
      </div>

      <div className="d-flex">
        <div className="px-3 py-1 pointer" onClick={props.onEdit}>
          <EditOutlined style={{ fontSize: '1.5rem' }} />
        </div>

        {props.deletable ? (
          <div className="px-1 py-1 pointer" onClick={props.onDelete}>
            <CloseCircleOutlined style={{ fontSize: '1.5rem', color: 'darkRed' }} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  </div>
)
