import { getReq } from '../service/API'
import useSWR from 'swr'
import { statisticsQuestionnaire_endpoint } from '../service/api-gateway'

export const useStatistics = (questionnaireId: string | null) => {
  const endpoint = questionnaireId ? statisticsQuestionnaire_endpoint(questionnaireId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    statistics: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
