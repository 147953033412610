import React, { useState } from 'react'
import { LanguageISOCode, PhoneNumberQuestion } from '../../../Types'
import { QuestionBody } from './QuestionBody'
import { QuestionTitle } from './QuestionTitle'
import { SubmitButton } from './SubmitButton'
import { CheckOutlined } from '@ant-design/icons'
import './phone-number-input.scss'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

export interface PhoneNumberQuestionFormProps {
  language: LanguageISOCode
  question: PhoneNumberQuestion
  onSubmit: (answer: string) => void
}

export const PhoneNumberQuestionForm = ({ question, language, onSubmit }: PhoneNumberQuestionFormProps) => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [value, setValue] = useState('')
  const submit = () => {
    if (question.required && value === '') {
      setErrorMsg('Please enter your phone number')
    }
    //TODO: Change the structure of the IF statements, less nested more separate
    if (question.required && value) {
      if (value !== '' && value.length !== 0) {
        if (!isValidPhoneNumber(value)) {
          setErrorMsg('Please provide a valid phone number')
        } else {
          onSubmit(value)
          setErrorMsg('')
        }
      }
    } else if (!question.required) {
      onSubmit(value)
      setErrorMsg('')
    }
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>

      <PhoneInput
        defaultCountry="US"
        placeholder={''}
        addInternationalOption={false}
        countries={['US']}
        onChange={(s: string) => setValue(s)}
        value={value}
        style={{
          width: '90%',
          maxWidth: '600px',
          fontSize: '1.3rem',
          paddingBottom: '.2rem',
          backgroundColor: 'transparent',
          outline: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
        }}
      />
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={submit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}
