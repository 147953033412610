import Drawer from 'antd/lib/drawer'
import { useLayoutEffect, useState } from 'react'
import { SiteDrawer } from '../../pages/sites/SiteDrawer'

export const SiteAddDrawer = ({ closeDrawer, projectId, maxDistance, maxDistanceTravel }: any) => {
  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    setVisible(true)
  }, [setVisible])

  const onClose = () => {
    setVisible(false)
  }

  const layoutChange = (e) => {
    if (!e) {
      closeDrawer()
    }
  }

  return (
    <Drawer
      title="Add Site"
      destroyOnClose={true}
      width={'40%'}
      placement="right"
      onClose={onClose}
      visible={visible}
      afterVisibleChange={layoutChange}
    >
      <SiteDrawer
        closeDrawer={onClose}
        projectId={projectId}
        maxDistance={maxDistance}
        maxDistanceTravel={maxDistanceTravel}
      ></SiteDrawer>
    </Drawer>
  )
}
