import InputNumber from 'antd/lib/input-number'
import { useEffect } from 'react'
import { FormLabel } from './FormLabel'

export const FormNumber = ({ data, formHook }) => {
  const { register, setValue } = formHook

  useEffect(() => {
    register(data.id)

    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [register, setValue, data.id, data.value])

  const onChange = (value: number) => {
    console.log(' FormNumber > value = ', value)
    setValue(data.id, value)
  }

  return (
    <div className="mt-4">
      <FormLabel label={data.label} tooltip={data.tooltip} />
      <InputNumber
        className="mt-1"
        min={data.min !== null ? data.min : 1}
        max={data.max !== null ? data.max : 10}
        defaultValue={data.value !== null ? data.value : 5}
        onChange={onChange}
        style={{ marginLeft: '2.5em', width: 'auto' }}
      />
    </div>
  )
}
