import { Alert, Popconfirm } from 'antd'
import Button from 'antd/lib/button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { getFormType } from '../../components/form/formFactory'
import { PlaceSiteWrapper } from '../../components/map/PlaceSiteWrapper'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useSite } from '../../hooks/useSite'
import { siteAddConfig } from './SiteAddConfiguration'
import { siteEditConfig } from './SiteEditConfiguration'
import { createSiteSubmit, updateSiteSubmit } from './SitesAPI'

export const SiteDrawer = ({ closeDrawer, projectId, maxDistanceTravel, maxDistance }: any) => {
  const params: any = useParams()

  //
  // for some reason if useSite - is using suspense then the page JANKS and scrolls to the top
  //
  const { data, isError } = useSite(params.siteId || '', false)
  console.log(' SiteDrawer > data = ', data)
  //
  // if no site is is provided we are creating a new site and do not load any data
  //
  const isEdit = params.siteId ? true : false

  const formHook = useForm({
    //defaultValues: { position: { lng: 55.665894624, lat: 12.5685274735 } },
  })
  const { handleSubmit, reset } = formHook

  useEffect(() => {
    if (data) {
      reset({
        internalName: data.internalName,
        officialName: data.officialName,
        position: data.position,
        country: data.country,
        address: data.address,
        zipcode: data.zipcode,
        capacity: data.capacity,
        active: data.active,
      })
    } else {
      reset({
        active: false,
        position: { lat: 55.665894624, lng: 0.5685274735 },
      })
    }
  }, [data, reset])

  const [visiblePopconfirm, setVisiblePopconfirm] = useState<boolean>(false)
  const [errorState, setErrorState] = useState<any>()

  const showPopconfirm = () => {
    setVisiblePopconfirm(true)
  }

  const handleCancel = () => {
    setVisiblePopconfirm(false)
  }

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (isEdit) {
      const submitEdit = { ...data, projectId: projectId }

      updateSiteSubmit(params.siteId, submitEdit)
        .then(() => {
          onClose()
          setErrorState(undefined)
        })
        .catch((error) => {
          setErrorState(error)
        })
    } else {
      const submitCreate = { ...data, projectId: projectId, active: false }

      createSiteSubmit(submitCreate)
        .then(() => {
          onClose()
          setErrorState(undefined)
        })
        .catch((error) => {
          setErrorState(error)
        })
    }
  }

  const onClose = () => {
    setVisiblePopconfirm(false)
    closeDrawer()
  }

  const onError = (err, e) => {
    console.log(' ProjectPageCreate > onError = ', err)
    e.preventDefault()
  }

  //
  //  ERROR - Since we are not using suspense - we need to handle this our selfs
  //
  if (isError) {
    return <div className="">There was an error</div>
  }

  //
  //  LOADER - Since we are not using suspense - we need to handle this our selfs
  //
  console.log(' SiteDrawer >  isEdit && !data = ', isEdit && !data)
  const waitForData = isEdit && !data ? false : true
  if (!waitForData) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="col-12">
        {isEdit
          ? siteEditConfig.map((item, index) => {
              const comp = getFormType(item, index, formHook)
              return comp
            })
          : siteAddConfig.map((item, index) => {
              const comp = getFormType(item, index, formHook)
              return comp
            })}
      </div>
      <div className="col-12 mt-3">
        <PlaceSiteWrapper
          width={'100%'}
          height={'350px'}
          formHook={formHook}
          maxDistance={maxDistance}
          maxDistanceTravel={maxDistanceTravel}
        ></PlaceSiteWrapper>
        {errorState ? (
          <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
      {!isEdit ? (
        <div className="col-12 d-flex justify-content-between mt-3">
          <Button onClick={onClose} type="default" danger>
            Cancel creation
          </Button>
          <Button onClick={handleSubmit(onSubmit, onError)} type="primary">
            Create site
          </Button>
        </div>
      ) : (
        <div className="col-12 d-flex justify-content-between mt-3">
          <Button onClick={onClose} type="default" danger>
            Cancel edit
          </Button>

          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to update the site?"
            onConfirm={handleSubmit(onSubmit, onError)}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
            visible={visiblePopconfirm}
          >
            <Button onClick={showPopconfirm} type="primary">
              Update site
            </Button>
          </Popconfirm>
        </div>
      )}
    </form>
  )
}
