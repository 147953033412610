export const evaluatorConfigObj = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add evaluator name',
    label: 'Name',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Evaluator name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Evaluator email',
    label: 'Email',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Evaluator email required',
  },
]
