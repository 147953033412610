import { getReq, post, put, destroy, getUnsubReq, postFileUpload } from './API'
import { BASE_URL } from '../constants'
import { Questionnaire } from '../Types'

/**
 *
 * The convention of having the getProjects_endpoint as a string,
 * is to be able to use them as keys when querying with SWR
 *
 */

// PROJECTS
//---------------------------------------------------------------------------------------
export const getProjects_endpoint: string = BASE_URL + '/api/project'
export const getProjects = () => getReq(getProjects_endpoint)

export const getProject_endpoint = (id: string) => `${BASE_URL}/api/project/${id}`
export const getProject = (id: string) => getReq(getProject_endpoint(id))

export const createProject_endpoint: string = BASE_URL + '/api/project'
export const createProject = (data: any) => post(createProject_endpoint, data)

export const updateProject = (id: any, data: any) => put(getProject_endpoint(id), data)

//
// update sites on a project. Send the updated array of site ids to replace what on the project
//
export const updateSitesOnProject = (id: string, data: any) => {
  return put(`${BASE_URL}/api/project/updateSitesOnProject/${id}`, data)
}

export const getProjectMailTemplateCopyEmail_endpoint = (id: string) =>
  `${BASE_URL}/api/project/${id}/mail-template/copy-email`

export const sendCopyMail = (id: string, data: any) => post(getProjectMailTemplateCopyEmail_endpoint(id), data)
export const getProjectMailTemplates_endpoint = (id: string) => `${BASE_URL}/api/project/${id}/mail-templates`
export const updateProjectMailTemplates = (id: string, data: any) => put(getProjectMailTemplates_endpoint(id), data)

export const getProjectSettings_endpoint = (id: string) => `${BASE_URL}/api/project-settings/${id}`
export const updateProjectSettings = (id: any, data: any) => put(getProjectSettings_endpoint(id), data)

export const getUploadFile_endpoint = (id: any) => `${BASE_URL}/api/project/${id}/file-upload`
export const uploadFile = (id: string, data: any) => postFileUpload(getUploadFile_endpoint(id), data)

export const getRemoveFile_endpoint = (id: any) => `${BASE_URL}/api/project/${id}/file-remove/`
export const removeFile = (id: string, data: any) => post(getRemoveFile_endpoint(id), data)

export const getFiles_endpoint = (id: any) => `${BASE_URL}/api/project/${id}/get-files/`
export const getFiles = (id: string, data: any) => post(getFiles_endpoint(id), data)

export const downloadFile = (data) => post(`${BASE_URL}/api/project/file-download/`, data)

export const massEmail = (data) => post(`${BASE_URL}/api/project/mass-email/`, data)
export const sanityCheckMassEmail = (data) => post(`${BASE_URL}/api/project/sanity-check-mass-email/`, data)

// SITES
//---------------------------------------------------------------------------------------
export const getSites_endpoint: string = BASE_URL + '/api/sites'
export const getSites = () => getReq(getSites_endpoint)
//
// get the sites which is on a project - actiave or not active
//
export const getAllSites_endpoint = `${BASE_URL}/api/sites`
export const getSitesByProjectId_endpoint = (id: string) => `${BASE_URL}/api/sites/byProjectId/${id}`
export const getSitesByProjectId = (p) => getReq(p)

export const getSite_endpoint = (id: string) => `${BASE_URL}/api/sites/${id}`
export const getSite = (id: string) => getReq(getSite_endpoint(id))

export const createSite_endpoint: string = BASE_URL + '/api/sites'
export const createSite = (data: any) => post(createSite_endpoint, data)

export const updateSite = (id: any, data: any) => put(getSite_endpoint(id), data)

/**
 *
 * Investigators
 *
 */

export const getInvestigators_endpoint: string = BASE_URL + '/api/investigators'
export const getInvestigators = () => getReq('')

export const getInvestigator_endpoint = (id: string) => `${BASE_URL}/api/investigators/${id}`
export const getInvestigator = (id: string) => getReq(id)

export const createInvestigator_endpoint: string = BASE_URL + '/api/investigators'
export const createInvestigator = (data: any) => post(createInvestigator_endpoint, data)

export const updateInvestigator = (id: any, data: any) => put(getInvestigator_endpoint(id), data)

export const sendInvestigatorEmail = (investigatorId: string) =>
  getReq(`${BASE_URL}/api/investigators/send-invite/${investigatorId}`)

export const deleteInvestigator_endpoint = (id: string) => `${BASE_URL}/api/investigators/${id}`
export const deleteInvestigator = (investigatorId: string, data: { siteId: string }) =>
  destroy(deleteInvestigator_endpoint(investigatorId), data)

// Evaluators

export const evaluators = () => {
  return BASE_URL + `/api/evaluators`
}
export const evaluatorsByProjectId = (projectId: string) => {
  return BASE_URL + `/api/evaluators/${projectId}`
}
export const singleEvaluator = (evaluatorId: string, projectId: string) => {
  return BASE_URL + `/api/evaluators/${projectId}/id/${evaluatorId}`
}

export const addEvaluatorToProject = (projectId: string, id) => {
  return BASE_URL + `/api/evaluators/${projectId}/id/${id}/add`
}
export const removeEvaluatorFromProject = (projectId: string, id) => {
  return BASE_URL + `/api/evaluators/${projectId}/id/${id}/remove`
}

export const sendEvaluatorInvite = (evaluatorId: string) =>
  getReq(`${BASE_URL}/api/evaluators/send-invite/${evaluatorId}`)

// Questionnaire
//---------------------------------------------------------------------------------------
export const createQuestionnaire_endpoint = `${BASE_URL}/api/questionnaire`
export const createQuestionnaire = (data: Questionnaire) => post(createQuestionnaire_endpoint, data)

export const getQuestionnaires_endpoint = (projectId: string) => `${BASE_URL}/api/project/${projectId}/questionnaire`
export const getQuestionnaires = (projectId: string) => getReq(getQuestionnaires_endpoint(projectId))

export const getQuestionnaire_endpoint = (questionnaireId: string) => `${BASE_URL}/api/questionnaire/${questionnaireId}`
export const getQuestionnaire = (questionnaireId: string) => getReq(getQuestionnaire_endpoint(questionnaireId))

export const deleteQuestionnaire_endpoint = (questionnaireId: string) =>
  `${BASE_URL}/api/questionnaire/${questionnaireId}`
export const deleteQuestionnaire = (questionnaireId: string) => destroy(deleteQuestionnaire_endpoint(questionnaireId))

export const udpateQuestionnaire_endpoint = (questionnaireId: string) => getQuestionnaire_endpoint(questionnaireId)
export const updateQuestionnaire = (questionnaireId: string, data: any) =>
  put(udpateQuestionnaire_endpoint(questionnaireId), data)

export const duplicateQuestionnaire_endpoint = (questionnaireId: string) =>
  `${getQuestionnaire_endpoint(questionnaireId)}/duplicate`

export const answerQuestionnaire_endpoint = (questionnaireId: string) =>
  `${getQuestionnaire_endpoint(questionnaireId)}/answer`
export const answerQuestionnaire = (questionnaireId: string, data: any) =>
  post(answerQuestionnaire_endpoint(questionnaireId), data)

export const statisticsQuestionnaire_endpoint = (questionnaireId: string) =>
  `${getQuestionnaire_endpoint(questionnaireId)}/stats`
export const statisticsQuestionnaire = (questionnaireId: string) =>
  getReq(answerQuestionnaire_endpoint(questionnaireId))

export const getQuestionnaireFirstQuestion = (questionnaireId: string) =>
  getReq(`${BASE_URL}/api/test/questionnaire/${questionnaireId}/start`)

export const submitTestAnswerAndGetNext = (questionnaireId: string, data: any) =>
  post(`${BASE_URL}/api/test/questionnaire/${questionnaireId}/submit`, data)

// Questions
//---------------------------------------------------------------------------------------
export const getQuestions_endpoint = (questionnaireId: string) =>
  `${getQuestionnaire_endpoint(questionnaireId)}/question`
export const getQuestions = (questionnaireId: string) => getReq(getQuestions_endpoint(questionnaireId))

export const createQuestions_endpoint = `${BASE_URL}/api/question`
export const createQuestion = (data: any) => post(createQuestions_endpoint, data)

export const putQuestion_endpoint = (questionId: string) => `${createQuestions_endpoint}/${questionId}`
export const putQuestion = (questionId: string, data: any) => put(putQuestion_endpoint(questionId), data)

export const deleteQuestion_endpoint = (questionId: string) => `${createQuestions_endpoint}/${questionId}`
export const deleteQuestion = (questionId: string) => destroy(putQuestion_endpoint(questionId))

// Unsubscribe enrollment
//--------------------------------------------------------------------------------------
export const unsubscribeEnrollment_endpoint = (enrollmentId: string) => `${BASE_URL}/unsubscribe/` + enrollmentId
export const unsubscribeEnrollment = (enrollmentId: string) => getUnsubReq(unsubscribeEnrollment_endpoint(enrollmentId))

// EVENTS
//---------------------------------------------------------------------------------------
export const getEventsForEnrollment_endpoint = (enrollmentId: string) =>
  `${BASE_URL}/api/events/getEventsForEnrollment/` + enrollmentId
export const getEventsForEnrollment = (enrollmentId: string) => getReq(getEventsForEnrollment_endpoint(enrollmentId))

// Testing
//---------------------------------------------------------------------------------------
export const getTestingAnswers = (email: string) => getReq(`${BASE_URL}/api/test/${encodeURIComponent(email)}/answers`)
