import { NumberQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'
import { useEffect, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'
import { InfoCircleOutlined } from '@ant-design/icons'

export const NumberCreateForm = (props: TypedQuestionCreateFormProps<NumberQuestion>) => {
  const { setValue, register } = props.formHook

  const [inclusionRanges, setInclusionRanges] = useState<{ from: number; to: number }[]>(
    props.question?.includingRanges || []
  )
  const [exclusionRanges, setExclusionRanges] = useState<{ from: number; to: number }[]>(
    props.question?.excludingRanges || []
  )

  let defaultItem = props.defaultFormFieldConfig

  const formFieldConfigItems = [
    {
      id: 'min',
      type: 'NUMBER',
      placeholder: 'Minimum value',
      label: 'Minimum allowed answer value*',
      validation: { required: true },
      errorMsg: 'please enter a minimum allowed answer',
      ...defaultItem,
      value: props.question?.min,
      min: -(2 ** 31),
      max: 2 ** 31 - 1,
      tooltip: 'The minimum numerical value that the user is allowed to answer.',
    },
    {
      id: 'max',
      type: 'NUMBER',
      placeholder: 'Maximum allowed value',
      label: 'Maximum answer value*',
      validation: { required: true },
      errorMsg: 'please enter a maximum allowed answer',
      ...defaultItem,
      value: props.question?.max,
      min: -(2 ** 31),
      max: 2 ** 31 - 1,
      tooltip: 'The maximum numerical value that the user is allowed to answer.',
    },
  ]

  const includingRangesId = 'includingRanges'
  const excludingRangesId = 'excludingRanges'

  useEffect(() => {
    register(includingRangesId)
    register(excludingRangesId)
  }, [register])

  useEffect(() => {
    setValue(includingRangesId, inclusionRanges)
    setValue(excludingRangesId, exclusionRanges)
  })

  const addInclusionRange = (range: [from: number, to: number]) => {
    setInclusionRanges([...inclusionRanges, { from: range[0], to: range[1] }])
  }

  const removeInclusionInterval = (i: number) => () => {
    let oldRanges = [...inclusionRanges]
    oldRanges.splice(i, 1)
    setInclusionRanges(oldRanges)
  }

  const addExclusionRange = (range: [from: number, to: number]) => {
    setExclusionRanges([...exclusionRanges, { from: range[0], to: range[1] }])
  }

  const removeExclusionInterval = (i: number) => () => {
    let oldRanges = [...exclusionRanges]
    oldRanges.splice(i, 1)
    setExclusionRanges(oldRanges)
  }

  return (
    <div>
      <div>
        <div className="d-flex flex-column">
          {formFieldConfigItems.map((item, index) => (
            <div key={`number${index}`} className="w-100">
              {getTranslationAwareFormType(item, index, props.formHook, props.languages)}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="mt-4 d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <Tooltip placement="topLeft" title="Enter all ranges of values [from, to] that are inclusion criteria.">
              <InfoCircleOutlined />
            </Tooltip>
            <p className="mx-3">
              <strong>Including Intervals</strong>
            </p>
          </div>
          {inclusionRanges.map((range: { from: number; to: number }, i: number) => (
            <div
              key={i}
              className="d-flex px-3 py-2 mt-1 mb-1 d-flex justify-content-between w-50"
              style={{
                borderRadius: 3,
                backgroundColor: 'rgba(0,0,0,.04)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                marginLeft: '2.4em',
              }}
            >
              <div className="d-flex justify-content-between">
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.from}</p> -{' '}
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.to}</p>
              </div>
              <Button danger size="small" onClick={removeInclusionInterval(i)}>
                Remove
              </Button>
            </div>
          ))}

          <div className="d-flex w-50" style={{ marginLeft: '2.4em' }}>
            <RangeInput onSubmit={addInclusionRange} />
          </div>
        </div>

        <div className="mt-4 d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <Tooltip placement="topLeft" title="Enter all ranges of values [from, to] that are exclusion criteria.">
              <InfoCircleOutlined />
            </Tooltip>
            <p className="mx-3">
              <strong>Excluding Intervals</strong>
            </p>
          </div>
          {exclusionRanges.map((range: { from: number; to: number }, i: number) => (
            <div
              key={i}
              className="d-flex px-3 py-2 mt-1 mb-1 d-flex justify-content-between w-50"
              style={{
                borderRadius: 3,
                backgroundColor: 'rgba(0,0,0,.04)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                marginLeft: '2.4em',
              }}
            >
              <div className="d-flex justify-content-between">
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.from}</p> -{' '}
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.to}</p>
              </div>
              <Button danger size="small" onClick={removeExclusionInterval(i)}>
                Remove
              </Button>
            </div>
          ))}

          <div className="d-flex w-50" style={{ marginLeft: '2.4em' }}>
            <RangeInput onSubmit={addExclusionRange} />
          </div>
        </div>
      </div>
    </div>
  )
}

const RangeInput = (props: { onSubmit: (range: [from: number, to: number]) => void }) => {
  const [range, setRange] = useState<[number | null, number | null]>([null, null])
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (range[0] === undefined || range[1] === undefined) {
      setError('Please fill in both fields')
      return
    } else setError(null)

    props.onSubmit(range as [number, number])
    setRange([null, null])
  }

  return (
    <div className="w-100">
      <div className="d-flex align-items-center gap-3 mt-1 w-100">
        <p>
          <strong>From</strong>
        </p>
        <input
          type="number"
          className="ant-input"
          min={-(2 ** 31)}
          max={2 ** 31 - 1}
          onChange={(e) => {
            if (e.target.value !== '') setRange([e.target.valueAsNumber, range[1]])
            else setRange([null, range[1]])
          }}
          value={range[0] === null ? '' : range[0]}
        />
        <p>
          <strong>To</strong>
        </p>
        <input
          type="number"
          className="ant-input"
          min={-(2 ** 31)}
          max={2 ** 31 - 1}
          onChange={(e) => {
            if (e.target.value !== '') setRange([range[0], e.target.valueAsNumber])
            else setRange([range[0], null])
          }}
          value={range[1] === null ? '' : range[1]}
        />
        <Button onClick={handleSubmit}>
          <strong>Add Interval</strong>
        </Button>
      </div>
      {error !== null ? <p style={{ color: 'red' }}>{error}</p> : <></>}
    </div>
  )
}

export default NumberCreateForm
