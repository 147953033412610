import { FormTextField } from './FormTextField'
import { FormDropDown } from './FormDropDown'
import FormSwitch from './FormSwitch'
import { FormNumber } from './FormNumber'
import { FormSliderRange } from './FormSliderRange'
import { FormAutoComplete } from './FormAutoComplete'
import { FormSlider } from './FormSlider'
import { FormSelectMultiple } from './FormSelectMultiple'

export const getFormType = (formConfigObj: any, index: number, formHook: any) => {
  const type = formConfigObj.type

  switch (type) {
    case 'TEXT':
      return <FormTextField key={index} data={formConfigObj} formHook={formHook}></FormTextField>

    case 'DROPDOWN':
      return <FormDropDown key={index} data={formConfigObj} formHook={formHook}></FormDropDown>

    case 'SWITCH':
      return <FormSwitch key={index} data={formConfigObj} formHook={formHook}></FormSwitch>

    case 'NUMBER':
      return <FormNumber key={index} data={formConfigObj} formHook={formHook}></FormNumber>

    case 'SLIDER_RANGE':
      return <FormSliderRange key={index} data={formConfigObj} formHook={formHook}></FormSliderRange>

    case 'AUTOCOMPLETE':
      return <FormAutoComplete key={index} data={formConfigObj} formHook={formHook}></FormAutoComplete>

    case 'SLIDER':
      return <FormSlider key={index} data={formConfigObj} formHook={formHook}></FormSlider>

    case 'SELECT_MULTIPLE':
      return <FormSelectMultiple key={index} data={formConfigObj} formHook={formHook}></FormSelectMultiple>

    default:
      return (
        <div key={Math.random() * 10} className="">
          WHAT
        </div>
      ) //<div key={index}>{type}</div>
  }
}
