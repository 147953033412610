/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/08/2023.
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEvaluators } from '../../../hooks/useEvaluators'
import { useForm } from 'react-hook-form'
import { isValidEmail } from '../../../utils/isValidEmail'
import { addEvaluatorToProject, evaluators, evaluatorsByProjectId } from '../../../service/api-gateway'
import { post } from '../../../service/API'
import { openNotification } from '../../../utils/openNotification'
import { mutate } from 'swr'
import { ProjectEvaluatorDrawer } from './ProjectEvaluatorDrawer'
import Button from 'antd/lib/button'
import { evaluatorConfigObj } from './EvaluatorFormConfiguration'
import { getFormType } from '../../../components/form/formFactory'
import { FormLabel } from '../../../components/form/FormLabel'
import { Select } from 'antd'
import Alert from 'antd/lib/alert'
import { EvaluatorType } from '../../../Types'

/*type Props = {
  onClose?: () => void // passed in from the drawer
}*/

export const CreateAddEvaluator = () => {
  const [showCreate, setShowCreate] = useState<boolean>(false)

  const showCreateNew = () => {
    setShowCreate(true)
  }

  return (
    <ProjectEvaluatorDrawer
      headerContent={
        <Button type="primary" onClick={showCreateNew}>
          Create
        </Button>
      }
    >
      <Content showCreate={showCreate} setShowCreate={setShowCreate}></Content>
    </ProjectEvaluatorDrawer>
  )
}

type ContentProps = {
  showCreate: boolean
  setShowCreate: (value: boolean) => void
  onClose?: () => void
}

// Content component
//---------------------------------------------------------------------------------------

export const Content = ({ showCreate, setShowCreate, onClose }: ContentProps) => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useEvaluators()
  const [loading, setLoading] = useState(false)

  const evaluatorUsers = data
    .filter((item: EvaluatorType) => {
      const alreadyAssigned = item.projects.indexOf(projectId) > -1
      return alreadyAssigned ? false : true
    })
    .map((item) => {
      return {
        value: item._id,
        label: item.email,
      }
    })

  const formHook = useForm()

  const hideCreateNew = () => {
    setShowCreate(false)
  }

  const [selected, setSelected] = useState<EvaluatorType | null>(null)

  const { handleSubmit } = formHook

  const [errorState, setErrorState] = useState<any>()

  const handleChange = (value) => {
    const selectedItemArr = data.filter((item) => item._id === value)

    if (selectedItemArr.length === 1) {
      const item = selectedItemArr[0]
      setSelected(item)
    } else {
    }

    /*console.log(`selected ${value}`)
		console.log(`selected`, k)*/
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    console.log('ProjectEvaluatorCRUD > data = ', data)
    if (!isValidEmail(data.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    const dataObj = { ...data, projectId: projectId }

    setLoading(true)
    const endpoint: string = evaluators()

    return post(endpoint, dataObj)
      .then((result) => {
        console.log('result = ', result)
        setLoading(false)
        setErrorState(undefined)
        openNotification('The evaluator was created', '', 'happy')
        mutate(evaluatorsByProjectId(projectId))

        if (onClose) {
          onClose()
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const addToProject = () => {
    if (selected) {
      setLoading(true)
      const p = addEvaluatorToProject(projectId, selected._id)
      post(p, selected, 'PUT')
        .then((result) => {
          console.log('ProjectEvaluatorCRUD > result = ', result)
          openNotification('The evaluator was added to the project', '', 'happy')
          setLoading(false)
          if (onClose) {
            onClose()
          }
          mutate(evaluatorsByProjectId(projectId))
        })
        .catch((error) => {
          setLoading(false)
          console.log('ProjectEvaluatorCRUD > error = ', error)
          //  setErrorState(error)
        })
    } else {
      //setErrorState({ type: 'missing evaluator', message: 'Please select an evaluator' })
    }
  }

  return showCreate ? (
    <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="col-12 mb-4">
        <h5>Create a new evaluator</h5>
        <p className="opacity-75">
          If the evaluator has not already been created in the system, you can create them here.
        </p>
      </div>
      <div className="col-12">
        {evaluatorConfigObj.map((item, index) => {
          const comp = getFormType(item, index, formHook)
          return comp
        })}
      </div>

      <div className="col-12">
        <p className="opacity-75">
          Please note when the investigator has been created, you also have to trigger an invite
        </p>
      </div>

      <div className="col-12 d-flex justify-content-end mt-3">
        <Button type="default" className="me-2" onClick={hideCreateNew}>
          Choose from list
        </Button>
        <Button type="primary" loading={loading} onClick={handleSubmit(onSubmit, onError)}>
          Create new evaluator
        </Button>
      </div>
    </form>
  ) : (
    <div className="row" style={{}}>
      <div className="col-12">
        <h5 className="fw-bold">Add Evaluator to project</h5>
        <p className="mt-2 mb-4">
          Please select an evaluator from the dropdown.
          <br /> If the evaluator already exist in the system, select them from the drop down.
        </p>
      </div>

      <div className="col-12 mt-2">
        <FormLabel label="Select evaluator "></FormLabel>
        <Select defaultValue="" className="w-100" allowClear onChange={handleChange} options={evaluatorUsers} />
      </div>
      <div className="col-12 mt-3">
        {errorState ? (
          <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
      {selected ? (
        <div className="col-12 mt-3">
          <p className="p-small">You have selected</p>
          <hr className="my-2 opacity-25" />
          <div className="w-100 border px-2 py-2 d-flex just">
            <div className="">
              <p>{selected.name}</p>
              <p>{selected.email}</p>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button type="primary" loading={loading} onClick={addToProject} className="mt-2">
              Add to project
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
