import { useEffect, useState } from 'react'
import { BmiQuestion, UnitSet } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import { FormLabel } from '../translation-aware-form/FormLabel'
import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'
const { Option } = Select

export const BmiCreateForm = (props: TypedQuestionCreateFormProps<BmiQuestion>) => {
  const [defaultDisplayUnit, setDefaultDisplayUnit] = useState<UnitSet>(props.question?.defaultDisplayUnit || 'metric')
  const { register, setValue } = props.formHook

  const defaultDisplayUnitName = 'defaultDisplayUnit'
  useEffect(() => {
    register(defaultDisplayUnitName, { required: true })
  }, [register])

  useEffect(() => {
    setValue(defaultDisplayUnitName, defaultDisplayUnit)
  }, [setValue, defaultDisplayUnit])

  const [inclusionRanges, setInclusionRanges] = useState<{ from: number; to: number }[]>(
    props.question?.includingRanges || []
  )
  const [exclusionRanges, setExclusionRanges] = useState<{ from: number; to: number }[]>(
    props.question?.excludingRanges || []
  )

  const includingRangesId = 'includingRanges'
  const excludingRangesId = 'excludingRanges'

  useEffect(() => {
    register(includingRangesId)
    register(excludingRangesId)
  }, [register])

  useEffect(() => {
    setValue(includingRangesId, inclusionRanges)
    setValue(excludingRangesId, exclusionRanges)
  })

  const addInclusionRange = (range: [from: number, to: number]) => {
    setInclusionRanges([...inclusionRanges, { from: range[0], to: range[1] }])
  }

  const removeInclusionInterval = (i: number) => () => {
    let oldRanges = [...inclusionRanges]
    oldRanges.splice(i, 1)
    setInclusionRanges(oldRanges)
  }

  const addExclusionRange = (range: [from: number, to: number]) => {
    setExclusionRanges([...exclusionRanges, { from: range[0], to: range[1] }])
  }

  const removeExclusionInterval = (i: number) => () => {
    let oldRanges = [...exclusionRanges]
    oldRanges.splice(i, 1)
    setExclusionRanges(oldRanges)
  }

  const formItems = [
    {
      id: 'weightHeader',
      type: 'TEXT',
      placeholder: 'Weight label text',
      label: 'Weight Label*',
      validation: { required: true },
      errorMsg: 'Enter a weight label text!',
      ...props.defaultFormFieldConfig,
      value: props.question?.weightHeader || '',
      tooltip:
        'A BMI question is a combination of a weight and a height input, this is the label text shown on the field where we ask the user to input their weight, in all supported languages. This could for example be something like "Your Weight" in english',
    },
    {
      id: 'heightHeader',
      type: 'TEXT',
      placeholder: 'Height label text',
      label: 'Height Label*',
      validation: { required: true },
      errorMsg: 'Enter a Height label text!',
      ...props.defaultFormFieldConfig,
      value: props.question?.heightHeader || '',
      tooltip:
        'A BMI question is a combination of a weight and a height input, this is the label text shown on the field where we ask the user to input their height, in all supported languages. This could for example be something like "Your Height" in english',
    },
  ]

  return (
    <div>
      <div className="mt-4">
        <FormLabel
          label="Default Visible Units"
          tooltip="Choose whether the user should see imperial (inches/lb) - or metric (cm/kg) units when first visiting the question. They can change units when they answer the question, but this decides which one is shown at first. We always save values in metric so this is only a cosmetic choice."
        />
        <Select
          id="defaultDisplayUnit"
          placeholder="Default Display Unit"
          optionLabelProp="label"
          onChange={(e) => setDefaultDisplayUnit(e)}
          defaultValue={defaultDisplayUnit}
          style={{ marginLeft: '2.4em', minWidth: '20em' }}
        >
          <Option value={'metric'}>
            {' '}
            <p>metric</p>
          </Option>
          <Option value={'imperial'}>
            {' '}
            <p>imperial</p>
          </Option>
        </Select>
      </div>

      <div>
        {formItems.map((item, index) => (
          <div key={index} className={item.className}>
            {getTranslationAwareFormType(item, index, props.formHook, props.languages)}
          </div>
        ))}
      </div>

      <div>
        <div className="mt-4 d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <Tooltip placement="topLeft" title="Enter all ranges of values [from, to] that are inclusion criteria.">
              <InfoCircleOutlined />
            </Tooltip>
            <p className="mx-3">
              <strong>Including BMI Intervals (optional)</strong>
            </p>
          </div>
          {inclusionRanges.map((range: { from: number; to: number }, i: number) => (
            <div
              key={i}
              className="d-flex px-3 py-2 mt-1 mb-1 d-flex justify-content-between w-50"
              style={{
                borderRadius: 3,
                backgroundColor: 'rgba(0,0,0,.04)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                marginLeft: '2.4em',
              }}
            >
              <div className="d-flex justify-content-between">
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.from}</p> -{' '}
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.to}</p>
              </div>
              <Button danger size="small" onClick={removeInclusionInterval(i)}>
                Remove
              </Button>
            </div>
          ))}

          <div className="d-flex w-50" style={{ marginLeft: '2.4em' }}>
            <RangeInput onSubmit={addInclusionRange} />
          </div>
        </div>

        <div className="mt-4 d-flex flex-column gap-2">
          <div className="d-flex align-items-center">
            <Tooltip placement="topLeft" title="Enter all ranges of values [from, to] that are exclusion criteria.">
              <InfoCircleOutlined />
            </Tooltip>
            <p className="mx-3">
              <strong>Excluding BMI Intervals (optional)</strong>
            </p>
          </div>
          {exclusionRanges.map((range: { from: number; to: number }, i: number) => (
            <div
              key={i}
              className="d-flex px-3 py-2 mt-1 mb-1 d-flex justify-content-between w-50"
              style={{
                borderRadius: 3,
                backgroundColor: 'rgba(0,0,0,.04)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                marginLeft: '2.4em',
              }}
            >
              <div className="d-flex justify-content-between">
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.from}</p> -{' '}
                <p style={{ minWidth: '2em', textAlign: 'center' }}>{range.to}</p>
              </div>
              <Button danger size="small" onClick={removeExclusionInterval(i)}>
                Remove
              </Button>
            </div>
          ))}

          <div className="d-flex w-50" style={{ marginLeft: '2.4em' }}>
            <RangeInput onSubmit={addExclusionRange} />
          </div>
        </div>
      </div>
    </div>
  )
}

const RangeInput = (props: { onSubmit: (range: [from: number, to: number]) => void }) => {
  const [range, setRange] = useState<[number | null, number | null]>([null, null])
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (range[0] === undefined || range[1] === undefined) {
      setError('Please fill in both fields')
      return
    } else setError(null)

    props.onSubmit(range as [number, number])
    setRange([null, null])
  }

  return (
    <div className="w-100">
      <div className="d-flex align-items-center gap-3 mt-1 w-100">
        <p>
          <strong>From</strong>
        </p>
        <input
          type="number"
          className="ant-input"
          min={-(2 ** 31)}
          max={2 ** 31 - 1}
          onChange={(e) => {
            if (e.target.value !== '') setRange([e.target.valueAsNumber, range[1]])
            else setRange([null, range[1]])
          }}
          value={range[0] === null ? '' : range[0]}
        />
        <p>
          <strong>To</strong>
        </p>
        <input
          type="number"
          className="ant-input"
          min={-(2 ** 31)}
          max={2 ** 31 - 1}
          onChange={(e) => {
            if (e.target.value !== '') setRange([range[0], e.target.valueAsNumber])
            else setRange([range[0], null])
          }}
          value={range[1] === null ? '' : range[1]}
        />
        <Button onClick={handleSubmit}>
          <strong>Add Interval</strong>
        </Button>
      </div>
      {error !== null ? <p style={{ color: 'red' }}>{error}</p> : <></>}
    </div>
  )
}

export default BmiCreateForm
