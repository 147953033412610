import { BmiQuestion, LanguageISOCode } from '../../../Types'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionBody } from './QuestionBody'
import { QuestionTitle } from './QuestionTitle'
import { SubmitButton } from './SubmitButton'
import { createRef, useEffect, useState } from 'react'
import { NumberInput } from './NumberInput'

export interface BmiQuestionFormProps {
  language: LanguageISOCode
  question: BmiQuestion
  onSubmit: (answer: string) => void
}

export function BmiQuestionForm({ question, language, onSubmit }: BmiQuestionFormProps) {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [unitSet, setUnitSet] = useState(question.defaultDisplayUnit)
  const [{ weight, height }, setState] = useState({ weight: '', height: '' })

  useEffect(() => {
    setState({ weight: '', height: '' })
  }, [unitSet])

  const activeUnitStyle = {
    color: 'black',
    cursor: 'pointer',
    borderBottom: '1px solid black',
  }

  const inactiveUnitStyle = {
    color: 'gray',
    cursor: 'pointer',
  }

  const handleSubmit = () => {
    const h = parseFloat(height)
    const w = parseFloat(weight)

    if (question.required && (isNaN(h) || isNaN(w))) {
      setErrorMsg('Please fill out your height and weight')
      return
    } else if (!question.required && (isNaN(h) || isNaN(w))) {
      onSubmit('')
      setErrorMsg('')
      return
    }

    const hsquared = (h / 100) * (h / 100)
    const bmi = hsquared !== 0 ? Math.floor(w / hsquared) : 0

    onSubmit(
      JSON.stringify({
        height: height,
        weight: weight,
        bmi: bmi.toFixed(0),
      })
    )
    setErrorMsg('')
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>

      {unitSet === 'metric' ? (
        <MetricBmiInput
          onWeightChange={(e) => setState({ weight: e, height })}
          onHeightChange={(e) => setState({ height: e, weight })}
          {...{ question, language }}
        />
      ) : (
        <ImperialBmiInput
          onWeightChange={(e) => setState({ weight: e, height })}
          onHeightChange={(e) => setState({ height: e, weight })}
          {...{ question, language }}
        />
      )}

      <div className="d-flex mt-4 gap-3">
        <p onClick={() => setUnitSet('imperial')} style={unitSet === 'imperial' ? activeUnitStyle : inactiveUnitStyle}>
          in/lb
        </p>
        <p onClick={() => setUnitSet('metric')} style={unitSet === 'metric' ? activeUnitStyle : inactiveUnitStyle}>
          cm/kg
        </p>
      </div>
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={handleSubmit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}

function MetricBmiInput({ question, language, onWeightChange, onHeightChange }) {
  const [weight, setWeight] = useState('')
  const [height, setHeight] = useState('')

  const handleHeightChange = (newHeight: string) => {
    if (parseInt(newHeight) > 240) {
      setHeight('240')
      onHeightChange('240')
      return
    } else {
      setHeight(newHeight)
      onHeightChange(newHeight)
    }
  }

  const handleWeightChange = (newWeight: string) => {
    if (parseInt(newWeight) > 450) {
      setWeight('450')
      onWeightChange('450')
      return
    } else {
      setWeight(newWeight)
      onWeightChange(newWeight)
    }
  }

  return (
    <>
      <div>
        <p>{question.heightHeader[language]} (cm)</p>
        <NumberInput
          value={height}
          autoFocus={true}
          placeholder={'0'}
          onChange={handleHeightChange}
          min={0}
          max={240}
        />
      </div>

      <div className="mt-3">
        <p>{question.weightHeader[language]} (kg)</p>
        <NumberInput
          value={weight}
          autoFocus={false}
          placeholder={'0'}
          onChange={handleWeightChange}
          min={0}
          max={450}
        />
      </div>
    </>
  )
}

function ImperialBmiInput({ question, language, onWeightChange, onHeightChange }) {
  const [weight, setWeight] = useState('')
  const [feet, setFeet] = useState('')
  const [inches, setInches] = useState('')

  // used to set focus on the inches input field automaticlly
  const ref = createRef<HTMLInputElement>()

  //TODO: One line solution + one letter then the ' then he inches => then slice it and break it down
  const handleFeetChange = (newFeet: string) => {
    if (parseInt(newFeet) > 8) {
      setFeet('8')
      onHeightChange(parseInt('8') * 30.48 + parseInt(inches) * 2.54)
      setFocusOnFeet()
    } else {
      setFeet(newFeet)

      onHeightChange(parseInt(newFeet) * 30.48 + parseInt(inches) * 2.54)
      setFocusOnFeet()
    }
  }

  //TODO: One line solution + one letter then the ' then he inches => then slice it and break it down
  const handleInchesChange = (newInches: string) => {
    if (parseInt(newInches) > 11) {
      setInches('11')
      onHeightChange(parseInt(feet) * 30.48 + parseInt('11') * 2.54)
      return
    } else {
      setInches(newInches)
      onHeightChange(parseInt(feet) * 30.48 + parseInt(newInches) * 2.54)
    }
  }

  const handleWeightChange = (newWeight: string) => {
    if (parseInt(newWeight) > 1000) {
      setWeight('1000')
      onWeightChange(parseInt('1000') * 0.453592)
      return
    } else {
      setWeight(newWeight)
      onWeightChange(parseInt(newWeight) * 0.453592)
    }
  }

  const setFocusOnFeet = () => {
    if (ref && ref.current) {
      setInches('')
      ref.current?.focus()
    }
  }

  return (
    <>
      <div className="">
        <p>{question.heightHeader[language]} (feet, inches)</p>
        <div className="d-flex">
          <div style={{ maxWidth: '50px' }}>
            <NumberInput
              value={feet}
              autoFocus={true}
              placeholder={'0'}
              onChange={handleFeetChange}
              min={0}
              max={8}
              textAlign="right"
              inputName="removeArrows"
            />
            <span style={{ fontSize: '20px', float: 'right', position: 'absolute' }}>'</span>
          </div>
          <div style={{ maxWidth: '50px' }}>
            <NumberInput
              ref={ref}
              value={inches}
              autoFocus={false}
              placeholder={'0'}
              onChange={handleInchesChange}
              min={0}
              max={11}
              textAlign="right"
              inputName="removeArrows"
            />
            <span style={{ fontSize: '20px', float: 'right', position: 'absolute' }}>''</span>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <p>{question.weightHeader[language]} (lbs)</p>
        <NumberInput
          value={weight}
          autoFocus={false}
          placeholder={'0'}
          onChange={handleWeightChange}
          min={0}
          max={1500}
        />
      </div>
    </>
  )
}
