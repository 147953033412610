import { getReq } from '../service/API'
import useSWR from 'swr'
import { getInvestigator_endpoint } from '../service/api-gateway'

export const useInvestigator = (investigatorId: string) => {
  const endpoint = investigatorId ? getInvestigator_endpoint(investigatorId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
