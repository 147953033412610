/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/05/2022.
 */
import React from 'react'

type Props = {
  label: string
  children?: React.ReactElement[] | React.ReactElement | null
}

export const FormLabel = ({ label, children = null }: Props) => {
  return <div className="">{children ? children : <p className="mb-1 p-small ">{label}</p>}</div>
}
