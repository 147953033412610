/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'

const { Option } = Select

export const FormDropDown = ({ data, formHook }) => {
  const {
    setValue,
    register,
    formState: { errors },
    getValues,
  } = formHook

  const errorState = errors[data.id]
  const [state, setState] = useState<string | null>(null)

  const theValue = getValues(data.id)

  useEffect(() => {
    register(data.id, data.validation)

    if (theValue) {
      setState(theValue)
    }
  }, [register, data.id, data.validation, theValue])

  const items = data.options.map((item, index) => {
    return (
      <Option key={index} value={index}>
        <p>{item.toString() || 'wrong'}</p>
      </Option>
    )
  })

  const handleChange = (value) => {
    const selectedValue = data.options[value].toString()
    setValue(data.id, selectedValue)
    setState(selectedValue)
  }

  return (
    <div className="py-1">
      <FormLabel label={data.label} />
      <Select
        defaultValue={getValues(data.id)}
        value={state}
        style={{ width: '100%' }}
        onChange={handleChange}
        placeholder={data.placeholder}
      >
        {items}
      </Select>

      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
