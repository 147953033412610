import { ChangeEvent, forwardRef, KeyboardEvent, useEffect, useRef } from 'react'
import './RemoveNumberArrows.css'

export interface NumberInputProps {
  autoFocus?: boolean
  value: string
  placeholder: string
  min: number
  max: number
  onChange: (s: string) => void
  textAlign?: string
  inputName?: string
}

export const NumberInput = forwardRef(
  ({ placeholder, onChange, value, autoFocus, min, max, textAlign, inputName }: NumberInputProps, ref: any) => {
    const inputRef = useRef<any>(null)

    const numberInputStyle = {
      width: '90%',
      maxWidth: '600px',
      //fontSize: '1.5rem',
      //paddingBottom: '.2rem',
      //backgroundColor: 'transparent',
      outline: 'none',
      //borderLeft: 'none',
      //borderRight: 'none',
      borderTop: 'none',
      borderBottom: '1px solid black',
      textAlign: textAlign ? (textAlign as any) : '',
    }

    useEffect(() => {
      //if (autoFocus && inputRef) inputRef.current.focus()
    }, [autoFocus])

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.altKey && !e.metaKey && !e.key.match(/[0-9]|(Backspace)|(ArrowUp)|(ArrowDown)|(Tab)/))
        e.preventDefault()
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    }

    return (
      <input
        name={inputName ? inputName : ''}
        className="border-end-0 border-start-0 border-top-0 bg-transparent input-fontSize "
        type="number"
        style={numberInputStyle}
        placeholder={placeholder}
        value={value || ''}
        autoFocus={autoFocus}
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        ref={ref ? ref : inputRef}
      />
    )
  }
)
