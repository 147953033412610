import { Link, useParams } from 'react-router-dom'
import { Question, RadioQuestion, Translation } from '../../Types'
import { useQuestionnaire } from '../../hooks/useQuestionnaire'
import { useSWRConfig } from 'swr'
import { Button, Select } from 'antd'
import { getQuestionnaire_endpoint, putQuestion } from '../../service/api-gateway'
import { DEFAULT_LANG, PROJECT_PAGE_VIEW } from '../../constants'
import { Fragment } from 'react'
const { Option } = Select

export const QuestionnairePageFlow = () => {
  const { projectId, questionnaireId } = useParams() as { projectId: string; questionnaireId: string }
  const { mutate } = useSWRConfig()
  const { questionnaire } = useQuestionnaire(questionnaireId || null)
  const { questions } = questionnaire

  const handleQuestionChangeRadio = (q: RadioQuestion, i: number) => (e: number) => {
    let newQ = q
    while (newQ.next.length < q.options.length) newQ.next.push(questions[e]._id)
    newQ.next[i] = questions[e]._id

    putQuestion(newQ._id, newQ)
      .then(() => mutate(getQuestionnaire_endpoint(questionnaireId)))
      .catch((err) => {
        if (err.type === 'FEEDBACK') {
          alert(err.message)
        } else {
          console.error('QuestionnairePageFlow error > ', err)
        }
      })
  }

  const handleQuestionChange = (q: Question) => (e: number) => {
    let newQ = q
    if (newQ.next.length === 0) newQ.next.push('')
    newQ.next[0] = questions[e]._id

    putQuestion(newQ._id, newQ)
      .then(() => mutate(getQuestionnaire_endpoint(questionnaireId)))
      .catch((err) => {
        if (err.type === 'FEEDBACK') {
          alert(err.message)
        } else {
          console.error('QuestionnairePageFlow error > ', err)
        }
      })
  }

  const getNextQuestionRadio = (q: Question, i: number) => {
    let question = questions[questions.map((q: Question) => q._id).indexOf(q.next[i])]
    if (!question) return 'None'
    return question.title[DEFAULT_LANG] || Object.entries(question.title)[0][1]
  }

  const getNextQuestion = (q: Question) => {
    let question = questions[questions.map((q: Question) => q._id).indexOf(q.next[0])]
    if (!question) return 'None'
    return question.title[DEFAULT_LANG] || Object.entries(question.title)[0][1]
  }

  const QuestionCard = (props: { question: Question }) => (
    <div
      key={props.question._id}
      style={{
        borderRadius: 3,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div className="p-4 mt-3 d-flex flex-row justify-content-between align-items-start">
        <div className="pl-2 " style={{ width: '40%' }}>
          <p className="mb-0 p-small alpha-80">{props.question.type}</p>
          <h5 className="mb-0 font-weight-bold">
            {props.question.title[DEFAULT_LANG] || Object.entries(props.question.title)[0][1]}
          </h5>
        </div>
      </div>
      {props.question._id !== questionnaire.end?._id ? (
        <div className="d-flex flex-column align-items-start mt-0 pt-0 pb-3">
          {props.question.type === 'radio' || props.question.type === 'gender' ? (
            <>
              {(props.question as RadioQuestion).options.map((option: Translation, i: number) => (
                <div key={i} className="d-flex flex-row px-4 py-1 align-items-center">
                  <div style={{ minWidth: '75px' }}>{option[DEFAULT_LANG] || Object.entries(option)[0][1]}</div>
                  <div className="px-3"> &rarr; </div>
                  <Select
                    style={{ minWidth: '300px' }}
                    value={getNextQuestionRadio(props.question, i)}
                    onChange={handleQuestionChangeRadio(props.question as RadioQuestion, i)}
                  >
                    {questionnaire.questions.map((_: any, _i: number) =>
                      questionnaire.questions[_i]._id !== props.question._id ? (
                        <Option key={_i} value={_i}>
                          <p>
                            {questionnaire.questions[_i].title[DEFAULT_LANG] ||
                              Object.entries(questionnaire.questions[_i].title)[0][1]}
                          </p>
                        </Option>
                      ) : (
                        <Fragment key={_i} />
                      )
                    )}
                  </Select>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex flex-row px-4 py-1 align-items-center">
              <div className="px-3"> &rarr; </div>
              <Select
                style={{ minWidth: '300px' }}
                value={getNextQuestion(props.question)}
                onChange={handleQuestionChange(props.question)}
              >
                {questionnaire.questions.map((_: any, _i: number) =>
                  questionnaire.questions[_i]._id !== props.question._id ? (
                    <Option key={_i} value={_i}>
                      <p>
                        {questionnaire.questions[_i].title[DEFAULT_LANG] ||
                          Object.entries(questionnaire.questions[_i].title)[0][1]}
                      </p>
                    </Option>
                  ) : (
                    <Fragment key={_i} />
                  )
                )}
              </Select>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <div className="container mt-3 mb-5">
      <div className="container mt-3">
        <div className="row" style={{}}>
          <div className="col-12 mt-3">
            <h2>Edit Questionnaire Flow</h2>
          </div>
          <p className="w-50 mt-1 mb-3">
            <em>
              On this page you can set up the sequence of questions (what leads where), be sure to thoroughly verify
              that you have set up a questionnaire correctly before activating it!
            </em>
          </p>
        </div>
      </div>

      <div>
        <QuestionCard question={questionnaire.start} key="start" />
        {questions
          .filter(
            (question: Question) => question._id !== questionnaire.start._id && question._id !== questionnaire.end._id
          )
          .map((question: Question, index: number) => (
            <QuestionCard question={question} key={index} />
          ))}
        <QuestionCard question={questionnaire.end} key="end" />
      </div>
      <div style={{ height: '4em' }}></div>

      <div>
        <Link className="" to={PROJECT_PAGE_VIEW.to.replace(':projectId', projectId)}>
          <Button type="primary">Done</Button>
        </Link>
      </div>

      <div style={{ height: '4em' }}></div>
    </div>
  )
}
