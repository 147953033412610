import { Button } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { PROJECT_PAGE_VIEW, DEFAULT_LANG } from '../../constants'
import { useQuestionnaire } from '../../hooks/useQuestionnaire'
import { useStatistics } from '../../hooks/useStatistics'
import { Question } from '../../Types'

export const QuestionnairePageStats = () => {
  const { projectId, questionnaireId } = useParams() as { projectId: string; questionnaireId: string }
  const { questionnaire } = useQuestionnaire(questionnaireId)
  const { statistics } = useStatistics(questionnaireId)

  const statsById = Object.fromEntries(statistics)
  console.log(statsById)

  return (
    <div className="container my-3">
      <h3>Statistics</h3>

      <div>
        <div className="my-3 p-3">
          {statsById[questionnaire.end._id].dropped} / {statsById[questionnaire.start._id].completed} (
          {(statsById[questionnaire.end._id].dropped / statsById[questionnaire.start._id].completed || 1) * 100}%) of
          respondents answered all questions
        </div>
      </div>

      {questionnaire.questions.map((question: Question, i: number) =>
        questionnaire.end._id !== question._id ? (
          <div className="my-3 p-3 border item-shadow" key={i}>
            <div>{question.title[DEFAULT_LANG]}</div>

            <div className="mx-3">
              {statsById[question._id].completed} - {Math.round(statsById[question._id].completedPercent)}% completed
            </div>
            <div className="mx-3">
              {statsById[question._id].dropped} - {Math.round(statsById[question._id].droppedPercent)}% dropped
            </div>
          </div>
        ) : (
          <div key={i}> </div>
        )
      )}
      <div className="my-5">
        <Link className="" to={PROJECT_PAGE_VIEW.to.replace(':projectId', projectId)}>
          <Button type="primary">Done</Button>
        </Link>
      </div>
    </div>
  )
}
