import { InfoQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'

export const InfoCreateForm = (props: TypedQuestionCreateFormProps<InfoQuestion>) => {
  // const [base64, setBase64] = useState<string | undefined>(props.question?.image || undefined)
  // const imageSelectorName = 'image'
  // const { register, setValue } = props.formHook

  // useEffect(() => {
  //   register(imageSelectorName)
  // }, [register, imageSelectorName])

  // useEffect(() => {
  //   setValue(imageSelectorName, base64)
  // }, [setValue, imageSelectorName, base64])

  // const handleImageChange = (e: any) => {
  //   if (e.target.files === null || e.target.files.length === 0) {
  //     setBase64(undefined)
  //   } else {
  //     const fr = new FileReader()
  //     fr.readAsDataURL(e.target.files[0])
  //     fr.onload = () => setBase64(fr.result?.toString() || '')
  //   }
  // }

  return (
    // <div className="mt-4">
    //   <FormLabel
    //     label="Image (optional)"
    //     tooltip="Optionally upload an image to be shown beside the text, make sure you consider how this looks in the rendered question on a phone"
    //   />

    //   <div className="mt-2" style={{ marginLeft: '2.4em' }}>
    //     <input accept=".jpg,.png" type="file" className="w-100" onChange={handleImageChange} />
    //   </div>

    //   <div
    //     className="py-2 mt-3 mb-4"
    //     style={{
    //       borderRadius: 3,
    //       marginLeft: '2.4em',
    //     }}
    //   >
    //     {base64 ? (
    //       <div style={{ maxHeight: '200px' }}>
    //         <img src={base64} alt="img" width="auto" height="200px" />
    //       </div>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    // </div>
    <div></div>
  )
}

export default InfoCreateForm
