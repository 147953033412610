import { Alert, message, Popconfirm, Switch } from 'antd'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Modal from 'antd/lib/modal'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useProjectAndPopulate } from '../../hooks/useProject'
import { updateProjectSettingsSubmit } from './ProjectsAPI'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/05/2022.
 */

type Props = {
  isModalVisible: boolean
  closeModal: () => void
  onOk: () => void
}

export const ProjectSettings = ({ isModalVisible, closeModal, onOk }: Props) => {
  const params = useParams() as { projectId: string }
  const projectId = params.projectId
  const { data: project } = useProjectAndPopulate(projectId || '')
  const sites = project.sites
  const formHook = useForm()
  const { handleSubmit, control, reset } = formHook

  const [projectActive, setProjectActive] = useState<boolean>()
  const [errorState, setErrorState] = useState<any>(false)
  const [infoState, setInfoState] = useState<any>(false)

  const activateAllSites = (checked: boolean) => {
    sites.map((site) => {
      return formHook.setValue(site._id, checked)
    })
  }

  useEffect(() => {
    reset()
    setProjectActive(project.active)
  }, [isModalVisible, reset, project.active])

  const activateProject = (checked: boolean) => {
    if (project.questionnaires.length === 0) {
      setInfoState({ message: 'A project cannot be activated without one or more active questionnaires.' })
    }
    project.questionnaires.forEach((questionnaire) => {
      if (questionnaire.active) {
        setProjectActive(checked)
        setInfoState(undefined)
      } else if (checked === true && questionnaire.active === false) {
        setInfoState({ message: 'A project cannot be activated without one or more active questionnaires.' })
      } else if (project.active && !questionnaire.active) {
        setProjectActive(checked)
      }
    })
  }

  function cancel(e) {
    console.log(e)
    message.error('Project settings not updated')
  }

  const onSubmit = (formData, e) => {
    e.preventDefault()

    let canSubmit = false

    project.questionnaires.forEach((questionnaire) => {
      if (questionnaire.active === true || (questionnaire.active === false && !projectActive)) {
        canSubmit = true
      } else {
        setErrorState({ message: 'A project cannot be activated without an active questionnaire.' })
      }
    })

    if (canSubmit) {
      const obj = {
        projectSettings: formData,
        active: projectActive,
      }

      setErrorState(undefined)

      updateProjectSettingsSubmit(projectId, obj)
        .then(() => {
          onOk()
        })
        .catch((error) => {
          setErrorState(error)
        })
    }
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Modal
        title="Activate project settings"
        visible={isModalVisible}
        onCancel={closeModal}
        onOk={onOk}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={closeModal} style={{ margin: 5 }}>
            Cancel
          </Button>,
          <Popconfirm
            key={''}
            disabled={
              (project.active && !projectActive) ||
              (!project.active && projectActive) ||
              (project.active && projectActive)
                ? false
                : true
            }
            title="Are you sure to activate/deactive this project?"
            onConfirm={handleSubmit(onSubmit, onError)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              key="submit"
              type="primary"
              disabled={
                (project.active && !projectActive) ||
                (!project.active && projectActive) ||
                (project.active && projectActive)
                  ? false
                  : true
              }
            >
              Submit
            </Button>
          </Popconfirm>,
        ]}
      >
        <div className="container">
          <div className="row" style={{}}>
            <div className="col-12 mb-5 justify-content-between d-flex">
              <p>
                Start the <b>{project.title}</b> recruitment project
              </p>
              <Switch checked={projectActive} onChange={activateProject} />
            </div>
            <div className="col-12 justify-content-between d-flex">
              <p>Activate all sites</p>
              <Switch onChange={activateAllSites} />
            </div>
          </div>
          <Divider />
          <div className="row" style={{}}>
            {sites.map((item, index) => {
              return (
                <div key={index} className="col-12 d-flex justify-content-between mt-3">
                  {item.internalName}
                  <Controller
                    name={item._id}
                    control={control}
                    defaultValue={item.active ? item.active : false}
                    render={({ field: { onChange, value } }) => {
                      return <Switch checked={value} onChange={onChange} />
                    }}
                  />
                </div>
              )
            })}
          </div>
          {infoState ? <Alert className="mt-3" banner description={infoState.message} type="info" /> : <></>}
          {errorState ? <Alert className="mt-3" description={errorState.message} type="error" /> : <></>}
        </div>
      </Modal>
    </form>
  )
}
