import { LanguageISOCode, NumberQuestion } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { QuestionTitle } from './QuestionTitle'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionBody } from './QuestionBody'
import { useState } from 'react'
import { NumberInput } from './NumberInput'

export interface NumberQuestionFormProps {
  language: LanguageISOCode
  question: NumberQuestion
  onSubmit: (answer: string) => void
}

export function NumberQuestionForm({ question, language, onSubmit }: NumberQuestionFormProps) {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [value, setValue] = useState('')
  const [touched, setTouched] = useState(false)

  const noValueErrorMsg = 'Empty answers cannot be submitted'
  const mustBeNumberErrorMsg = 'Please enter a number'
  const lengthErrorMsg = 'Your answer must be between |MIN| and |MAX|'
    .replace('|MIN|', question.min.toString())
    .replace('|MAX|', question.max.toString())

  const handleChange = (s: string) => {
    setValue(s)
    setTouched(true)
  }

  const handleSubmit = () => {
    const answer = parseFloat(value.trim())

    if (question.required && !touched) {
      setErrorMsg(noValueErrorMsg)
    } else if (question.required) {
      if (isNaN(answer)) {
        setErrorMsg(mustBeNumberErrorMsg)
      } else if (answer < question.min || question.max < answer) {
        setErrorMsg(lengthErrorMsg)
      } else {
        onSubmit(answer.toString())
        setErrorMsg('')
      }
    } else {
      onSubmit(value)
      setErrorMsg('')
    }
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>
      <NumberInput
        value={value}
        autoFocus={true}
        placeholder={'0'}
        onChange={handleChange}
        min={question.min}
        max={question.max}
      />
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={handleSubmit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}
