import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import { useState } from 'react'
import { BASE_URL } from '../../constants'
import { getReq } from '../../service/API'

export const CurrentJobs = () => {
  const [result, setResult] = useState<[]>([])

  const p = BASE_URL + '/api/jobs'

  const handleClick = () => {
    setResult([])
    getReq(p)
      .then((result) => {
        setResult(result.data ? result.data : [])
      })
      .catch((error) => {
        message.error('Oops, something went wrong ', error)
      })
  }

  return (
    <>
      <div className="col-12">
        <Button type="primary" size="large" onClick={handleClick}>
          Get current jobs running on automation server
        </Button>
      </div>

      {result.map((job: any) => {
        return (
          <div className="col-4 mt-3">
            <div className="bg-white p-3">
              <div className="mb-3">
                <p className="p-small">Job name:</p>
                <p>
                  <b>{job.name}</b>
                </p>
              </div>
              <div className="mb-4">
                <p className="p-small">Job last ran at:</p>
                <p>
                  <b>{job.lastRunAt}</b>
                </p>
              </div>

              <div className="mb-4">
                <p className="p-small">Job next expected run at:</p>
                <p>
                  <b>{job.nextRunAt}</b>
                </p>
              </div>
              <div>
                <p className="p-small">Repeat interval: </p>
                <p>
                  <b>{job.repeatInterval}</b>
                </p>
              </div>
              <div className="mb-4">
                <p className="p-small">Last finished at:</p>
                <p>
                  <b>{job.lastFinishedAt}</b>
                </p>
              </div>
              {job.lastFailedAt && (
                <div className="mb-4">
                  <p className="p-small">Last failed at:</p>
                  <p>
                    <b>{job.lastFailedAt}</b>
                  </p>
                </div>
              )}
              {job.lastFailedReason && (
                <div className="mb-4">
                  <p className="p-small">Last fail reason:</p>
                  <p style={{ color: 'tomato' }}>
                    <b>{job.lastFailedReason}</b>
                  </p>
                </div>
              )}
              {job.failCount && (
                <div className="mb-4">
                  <p className="p-small">Total amount of fails:</p>
                  <p>
                    <b>{job.failCount}</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
