import { SmileOutlined, FrownOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import notification from 'antd/lib/notification'
import { useState } from 'react'
import { mutate } from 'swr'
import { FormLabel } from '../../components/form/FormLabel'
import { getProjectMailTemplateCopyEmail_endpoint, sendCopyMail } from '../../service/api-gateway'
import { authStore } from '../../store/auth/authStore'

export const ProjectMailTemplateFields = ({
  templateTitle,
  formHook,
  index,
  setErrorState,
  item,
  projectId,
  mailSender,
}: any) => {
  const user = authStore((state) => state.user)

  const [subject, setSubject] = useState<any>(item.subject)
  const [tempName, setTempName] = useState<any>(item.templateName)

  const successNotification = () => {
    notification.open({
      duration: 2,
      message: 'Email sent',
      description: 'You have sent yourself a copy of the email.',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    })
  }

  const errorNotification = () => {
    notification.open({
      duration: 4,
      message: 'Email could not be sent',
      description:
        'Check if the template name is correct. If the issue persists please contact your system administrator.',
      icon: <FrownOutlined style={{ color: '#dc3545' }} />,
    })
  }

  const sendYourselfCopy = () => {
    const email = user?.attributes.email || ''

    const mailObj = { email: email, subject: subject, templateName: tempName, fromEmail: mailSender }

    return sendCopyMail(projectId, mailObj)
      .then((result) => {
        mutate(getProjectMailTemplateCopyEmail_endpoint)
        successNotification()
        console.log('result = ', result)
        return Promise.resolve(result)
      })
      .catch((error) => {
        console.log('error = ', error)
        errorNotification()
        return Promise.reject(error)
      })
  }

  const handleSubject = (e: any) => {
    setSubject(e.target.value)
  }

  const handleTemplateName = (e: any) => {
    setTempName(e.target.value)
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="alpha-80" style={{ fontSize: '1rem' }}>
          {templateTitle + ' ' + item.templateLanguage}
        </h5>
        <Button type="dashed" onClick={sendYourselfCopy}>
          Send yourself a copy
        </Button>
      </div>
      <div className="w-100 py-1">
        <FormLabel label={'Subject'} />
        <input
          type="text"
          className="ant-input mb-2"
          autoComplete="nope"
          placeholder="Enter subject name"
          {...formHook.register(`form.${index}.subject`)}
          onChange={handleSubject}
        />
        <FormLabel label={'Template name'} />
        <input
          type="text"
          className="ant-input mb-1"
          autoComplete="nope"
          placeholder="Enter template name"
          {...formHook.register(`form.${index}.templateName`)}
          onChange={handleTemplateName}
        />
      </div>
      <Divider />
    </>
  )
}
