import { useEffect, useState } from 'react'
import { AutoComplete } from 'antd'
import { FormLabel } from './FormLabel'
import { languageOptions } from './LanguageOptions'

export const FormAutoComplete = ({ data, formHook }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]

  const [autoCompleteValue, setAutoCompleteValue] = useState('')

  useEffect(() => {
    register(data.id, data.validation)
  }, [data.id, data.validation, register])

  const onSelect = (value: string) => {
    setValue(data.id, value)
    setAutoCompleteValue(value)
  }

  const onChange = (value: string) => {
    setValue(data.id, value)
    setAutoCompleteValue(value)
  }

  return (
    <div className="w-100 py-2">
      <FormLabel label={data.label} />
      <AutoComplete
        value={data ? formHook.getValues('country') : autoCompleteValue}
        style={{ width: '100%' }}
        options={languageOptions}
        placeholder={data.placeholder}
        onSelect={onSelect}
        onChange={onChange}
        filterOption={(inputValue, languageOptions) =>
          languageOptions!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
