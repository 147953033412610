import { UseFormReturn, FieldValues } from 'react-hook-form'
import { QuestionCreateForm } from './QuestionCreateForm'
import { LanguageISOCode, Question } from '../../Types'

export const QuestionEditForm = (props: {
  formHook: UseFormReturn<FieldValues, any>
  question: Question
  languages: LanguageISOCode[]
}) => {
  const { register, setValue } = props.formHook

  const questionIdName = '_id'
  register(questionIdName)
  setValue(questionIdName, props.question._id)

  const questionnaireIdName = 'questionnaireId'
  register(questionnaireIdName)
  setValue(questionnaireIdName, props.question.questionnaireId)

  const typeSelectorName = 'type'
  register(typeSelectorName)
  setValue(typeSelectorName, props.question.type)

  return <QuestionCreateForm {...props} />
}

export default QuestionEditForm
