import { getReq } from '../service/API'
import useSWR from 'swr'
import { getEventsForEnrollment_endpoint } from '../service/api-gateway'

export const useEvent = (enrollmentId) => {
  const eventEndpoint = getEventsForEnrollment_endpoint(enrollmentId)

  const { data, error } = useSWR(eventEndpoint, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
