import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PROJECT_PAGE_VIEW_EDIT_SITE, PROJECT_PAGE_VIEW_INVESTIGATOR_LIST } from '../../constants'
import { Button, Popconfirm } from 'antd'
import { CloseCircleOutlined, TeamOutlined } from '@ant-design/icons'
import { SiteType } from '../../Types'
import Spinner from '../../components/spinner/Spinner'

const dotStyle = {
  width: '10px',
  height: '10px',
  display: 'inline-block',
  backgroundColor: 'green',
  borderRadius: '100%',
}

type Props = {
  site: SiteType
  isLoading: boolean
  remove: (siteId) => void
}

export const ProjectSiteListItem = ({ isLoading, site, remove }: Props) => {
  const [visiblePopconfirm, setVisiblePopconfirm] = useState<boolean>(false)

  const projectId = site.projectId

  const showPopconfirm = () => {
    setVisiblePopconfirm(true)
  }

  const handleCancel = () => {
    setVisiblePopconfirm(false)
  }

  const clicker = () => {
    remove(site._id)
  }

  return (
    <div className="bg-white mb-2 py-3 px-3 d-flex justify-content-between border">
      <div className="">
        <p>{site.internalName} </p>
      </div>

      {/* Active site dot */}
      <div className="d-flex">
        {!isLoading ? (
          <>
            <div className="px-2 mt-1">{site.active ? <span style={dotStyle}></span> : null}</div>
            <div className="px-1 pointer">
              <Link
                className="text-decoration-none"
                to={PROJECT_PAGE_VIEW_INVESTIGATOR_LIST.gotoPage?.(projectId, site._id) || 'undefined'}
              >
                <Button className="d-flex text-decoration-none">
                  <TeamOutlined className="me-2" style={{ fontSize: '1rem' }} />
                  <p className="">Investigators</p>
                </Button>
              </Link>
            </div>
            <div className="px-2 pointer">
              <Link to={PROJECT_PAGE_VIEW_EDIT_SITE.gotoPage?.(projectId, site._id) || 'undefined'}>
                {/*<ToolOutlined style={{ fontSize: '1.5rem' }} />*/}
                <Button>
                  <p className="">Edit site</p>
                </Button>
              </Link>
            </div>
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to remove this site?"
              onConfirm={clicker}
              onCancel={handleCancel}
              okText="Yes"
              cancelText="No"
              visible={visiblePopconfirm}
            >
              <div className="mr-3 pointer d-flex" onClick={showPopconfirm}>
                <div className="mr-1 px-1">
                  <CloseCircleOutlined style={{ fontSize: '1.5rem' }} />
                </div>
              </div>
            </Popconfirm>
          </>
        ) : (
          <div className="mx-3 mt-1 mb-3">
            <Spinner size={4} style={{ color: 'black' }}></Spinner>
          </div>
        )}
      </div>
    </div>
  )
}
