import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import { useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import { GroupHeader } from '../../components/display/GroupHeader'
import { PROJECT_PAGE_EDIT_MAILTEMPLATE } from '../../constants'
import { ProjectMailTemplateDrawer } from './ProjectMailTemplateDrawer'

export const ProjectMailTemplate = ({ project }: any) => {
  const navigate = useNavigate()

  const projectLanguages = project.languages
  const projectRetention = project.retention
  const projectMailSender = project.mailsender

  const [mailTemplateTimestamp, setMailTemplateTimestamp] = useState<number>()

  const onClose = () => {
    navigate('./')
  }
  const layoutChange = (e) => {
    if (!e) navigate('/project/' + project._id)
  }

  return (
    <>
      <Routes>
        <Route
          path="/template/:templateName"
          element={
            <Drawer
              afterVisibleChange={layoutChange}
              title={'Retention template with emails being sent every ' + mailTemplateTimestamp + ' hours'}
              destroyOnClose={true}
              width={'40%'}
              placement="right"
              onClose={onClose}
              visible={true}
            >
              <ProjectMailTemplateDrawer
                retententionArr={projectRetention}
                projectLanguages={projectLanguages}
                closeDrawer={onClose}
                mailSender={projectMailSender}
              />
            </Drawer>
          }
        />
      </Routes>

      <GroupHeader
        label="Project mail templates"
        header={<p className="opacity-75 p-small">Retention email templates for participants at F1 or F2</p>}
      >
        <div className="col-12 mb-3 bg-white mt-3">
          <div className="row" style={{}}>
            {projectRetention.map((item, index) => {
              const templateStr = item.template.map((template) => {
                return template.templateName.length > 2 ? [template.templateName] : ''
              })

              return (
                <div key={index} className="col-6 ">
                  <div className="border py-3 px-2 mt-2 d-flex justify-content-between align-items-center">
                    <div className="flex-grow-1 pe-3 overflow-hidden">
                      <p className="fw-bold p-small alpha-80">{item.title} </p>
                      <span className="d-inline-block text-truncate p-small" style={{ maxWidth: '100%' }}>
                        {templateStr.toString()}
                      </span>
                    </div>

                    <Link
                      className=""
                      to={PROJECT_PAGE_EDIT_MAILTEMPLATE.gotoPage?.(project._id, item._id) || 'undefined'}
                    >
                      <Button
                        type="primary"
                        className="px-2"
                        onClick={() => setMailTemplateTimestamp(Math.floor(item.timestamp / 60))}
                      >
                        <p className="p-small">Edit template</p>
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </GroupHeader>
    </>
  )
}
