import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'
import { NameQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'

export const NameCreateForm = (props: TypedQuestionCreateFormProps<NameQuestion>) => {
  const items = [
    {
      id: 'placeholder',
      type: 'TEXT',
      placeholder: 'Text shown on the texfield intially, like this very paragraph...',
      label: 'Placeholder (optional)',
      validation: { required: false },
      errorMsg: 'Please give your question a placeholder text!',
      ...props.defaultFormFieldConfig,
      value: props.question?.placeholder,
      tooltip: 'Text to be shown on the textfield before anything is entered...',
    },
  ]

  return (
    <>
      {items.map((item, index) => (
        <div key={`radio${index}`} className={item.className}>
          {getTranslationAwareFormType(item, index, props.formHook, props.languages)}
        </div>
      ))}
    </>
  )
}

export default NameCreateForm
