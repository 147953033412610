import { getReq } from '../service/API'
import useSWR from 'swr'
import { singleEvaluator } from '../service/api-gateway'

export const useEvaluator = (evaluatorId: string, projectId: string) => {
  //const endpoint = projectId ? BASE_URL + '/api/' + projectId + '/evaluators' : null
  const endpoint = evaluatorId ? singleEvaluator(evaluatorId, projectId) : null
  console.log('useEvaluator > endpoint = ', endpoint)

  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
