import { getReq } from '../service/API'
import useSWR from 'swr'
import { evaluatorsByProjectId } from '../service/api-gateway'

export const useEvaluatorsByProjectId = (projectId: string) => {
  //const endpoint = projectId ? BASE_URL + '/api/' + projectId + '/evaluators' : null
  const endpoint = projectId ? evaluatorsByProjectId(projectId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
