/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Header } from './components/header/Header'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import {
  USER_MANAGEMENT_PAGE,
  PROJECT_PAGE,
  PROJECT_PAGE_CREATE,
  PROJECT_PAGE_VIEW,
  QUESTIONNAIRE_PAGE_EDIT,
  QUESTIONNAIRE_PAGE_FLOW,
  QUESTIONNAIRE_PAGE_STATS,
  QUESTIONNAIRE_PAGE_TEST,
  SITES_PAGE,
  TESTING_ANSWER_LOOKUP,
  TESTING_PAGE,
} from './constants'
import { QuestionnairePageEdit } from './pages/questionnaire/QuestionnairePageEdit'
import { QuestionnairePageFlow } from './pages/questionnaire/QuestionnairePageFlow'
import { ProjectPage } from './pages/project/ProjectPage'
import { ProjectContainer } from './pages/project/ProjectContainer'
import { QuestionnairePageStats } from './pages/questionnaire/QuestionnairePageStats'
import { FlowBuilder } from './components/flow-builder/FlowBuilder'
import { QuestionnaireTester } from './pages/questionnaire/questionnaireTester/QuestionnaireTester'
import { AnswerLookup } from './pages/testing/AnswerLookup'
import { TestingPage } from './pages/testing/TestingPage'
import { SitePage } from './pages/sites/SitePage'
import { UserManagementPage } from './pages/testing/UserManagementPage'

export const Main = () => {
  console.log(' Main > Test - new featyre - testing deploy = ')
  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Navigate replace to={PROJECT_PAGE.path} />} />

        <Route
          path={PROJECT_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectPage></ProjectPage>
            </Suspense>
          }
        />

        <Route
          path={PROJECT_PAGE_CREATE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectContainer></ProjectContainer>
            </Suspense>
          }
        />

        <Route
          path={PROJECT_PAGE_VIEW.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectContainer></ProjectContainer>
            </Suspense>
          }
        />

        <Route
          path={QUESTIONNAIRE_PAGE_EDIT.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <QuestionnairePageEdit></QuestionnairePageEdit>
            </Suspense>
          }
        />

        <Route
          path={SITES_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SitePage></SitePage>
            </Suspense>
          }
        />

        <Route
          path={QUESTIONNAIRE_PAGE_FLOW.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <QuestionnairePageFlow></QuestionnairePageFlow>
            </Suspense>
          }
        />

        <Route
          path={QUESTIONNAIRE_PAGE_STATS.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <QuestionnairePageStats></QuestionnairePageStats>
            </Suspense>
          }
        />

        <Route
          path={QUESTIONNAIRE_PAGE_TEST.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <QuestionnaireTester></QuestionnaireTester>
            </Suspense>
          }
        />

        <Route
          path={TESTING_ANSWER_LOOKUP.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <AnswerLookup />
            </Suspense>
          }
        />

        <Route
          path={TESTING_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <TestingPage></TestingPage>
            </Suspense>
          }
        />

        <Route
          path={USER_MANAGEMENT_PAGE.path}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <UserManagementPage></UserManagementPage>
            </Suspense>
          }
        />

        <Route
          path="/test-flow"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <FlowBuilder></FlowBuilder>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
