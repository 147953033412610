import notification from 'antd/lib/notification'
import { SmileOutlined } from '@ant-design/icons'
import React from 'react'

export const openNotification = (
  msg: string = 'Email sent',
  description: string = 'The email has been sent to the investigator.',
  smiley: 'happy' | 'sad' | '' = ''
) => {
  notification.open({
    duration: 2.5,
    message: msg,
    description: description,
    icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
  })
}
