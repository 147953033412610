import { LanguageISOCode, RadioQuestion, Translation } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { QuestionTitle } from './QuestionTitle'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionBody } from './QuestionBody'
import { useEffect, useState } from 'react'
import { QuestionOption } from './QuestionOption'

export interface RadioQuestionFormProps {
  language: LanguageISOCode
  question: RadioQuestion
  onSubmit: (answer: string) => void
}

export function RadioQuestionForm({ question, language, onSubmit }: RadioQuestionFormProps) {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [value, setValue] = useState<number>(-1)
  const validIndices = question.options.map((_, i: number) => i.toString())

  useEffect(() => {
    document.addEventListener('keydown', onRadioKeyDown)
    return () => document.removeEventListener('keydown', onRadioKeyDown)
  })

  const onRadioKeyDown = (e) => {
    if (validIndices.includes(e.key)) {
      setValue(parseInt(e.key))
    }
  }

  const submit = () => {
    if (value === -1) {
      setErrorMsg('Please select an option')
    } else {
      onSubmit(value.toString())
      setErrorMsg('')
    }
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>

      <div>
        {question.options.map((option: Translation, i: number) => (
          <div key={i} style={{ maxWidth: '400px' }}>
            <QuestionOption
              label={option[language]}
              index={i}
              onSelect={(index: number) => setValue(index)}
              selected={value === i}
            />
          </div>
        ))}
      </div>
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={submit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}
