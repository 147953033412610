import { LanguageISOCode } from '../../Types'
import { TranslationAwareTextField } from './FormTranslationTextField'
import { TranslationAwareTextArea } from './FormTranslationTextArea'
import { FormNumber } from './FormNumber'

export const getTranslationAwareFormType = (
  formConfigObj: any,
  index: number,
  formHook: any,
  countryCodes: LanguageISOCode[]
) => {
  const type = formConfigObj.type

  switch (type) {
    case 'TEXT':
      return (
        <TranslationAwareTextField key={index} data={formConfigObj} formHook={formHook} countryCodes={countryCodes} />
      )

    case 'TEXTAREA':
      return (
        <TranslationAwareTextArea key={index} data={formConfigObj} formHook={formHook} countryCodes={countryCodes} />
      )

    case 'NUMBER':
      return <FormNumber key={index} data={formConfigObj} formHook={formHook} />

    default:
      return (
        <div key={Math.random() * 10} className="">
          ERROR!! No form found (getTranslationAwareFormType)
        </div>
      )
  }
}
