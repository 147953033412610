import Button from 'antd/lib/button'
import { Link } from 'react-router-dom'
import { PROJECT_PAGE_CREATE, PROJECT_PAGE_VIEW } from '../../constants'
import { useProjects } from '../../hooks/useProjects'

export const ProjectPage = () => {
  const { data } = useProjects()

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 d-flex justify-content-between mt-4 mb-3">
          <div className="">
            <h4 className="font-weight-bold">Projects</h4>
          </div>
          <div className="mr-4">
            <Link to={PROJECT_PAGE_CREATE.to}>
              <Button>
                <p className=" mb-0">Create project</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="row" style={{}}>
        {data.map((item: any, index: number) => {
          return (
            <div className="col-12 mb-3" key={index}>
              <div className="w-100 p-4 d-flex item-shadow">
                <div className="pl-2 " style={{ width: '40%' }}>
                  <p className="mb-0 p-mini alpha-80 fw-bold">Project </p>
                  <h5 className="mb-1 fw-bold text-black">{item.title}</h5>
                  {/*<p className="mb-0 p-mini alpha-80"> </p>*/}
                </div>

                <div className="w-25">
                  <p className="mb-0 p-mini alpha-80">Activated</p>
                  <p className="mb-0 fw-bold alpha-80 ">{item.active ? 'active' : 'Waiting'}</p>
                </div>

                <div className="w-25"></div>

                <div className="w-25 d-flex justify-content-end align-items-center">
                  <div className="pr-4"></div>

                  <Link className="" to={PROJECT_PAGE_VIEW.to.replace(':projectId', item._id)}>
                    <Button type="primary">View project</Button>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
