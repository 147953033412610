/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/08/2022.
 */
import React, { useEffect, useState } from 'react'
import { Project, SiteType } from '../../Types'
import { GroupHeader } from '../../components/display/GroupHeader'
import { Button, Dropdown, Menu } from 'antd'
import { Route, Routes, useParams } from 'react-router-dom'
import { SiteEditDrawer } from '../../components/drawers/SiteEditDrawer'
import { InvestigatorDrawerComponent } from '../../components/drawers/InvestigatorDrawerComponent'
import { SiteAddDrawer } from '../../components/drawers/SiteAddDrawer'
import { DownOutlined } from '@ant-design/icons'
import { SiteMap } from '../../components/map/SiteMap'
import { useSites } from '../../hooks/useSites'
import { ProjectSiteListItem } from './ProjectSiteListItem'
import {
  getProjectSettings_endpoint,
  getSitesByProjectId_endpoint,
  updateSitesOnProject,
} from '../../service/api-gateway'
import { useSWRConfig } from 'swr'

type Props = {
  project: Project
  maxDistance: number
  maxDistanceTravel: number
}

export const ProjectSites = ({ maxDistance, maxDistanceTravel }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const { mutate } = useSWRConfig()
  const { data } = useSites(projectId)

  const [isLoading, setIsLoading] = useState<string>('')
  const [selectedSites, setSelectedSites] = useState<SiteType[]>([])

  const activeSitesOnproject = data.selectedSiteIds

  useEffect(() => {
    const k: SiteType[] = data.availbleSites.filter((site) => {
      const isSelected = activeSitesOnproject.indexOf(site._id.toString()) > -1
      return isSelected
    })

    setSelectedSites(k)
  }, [setSelectedSites, activeSitesOnproject, data.availbleSites])

  //
  // this is the sites which are shown in the dropdown
  // WHen a  site is selected it's removed from availbleSites and shown in the selctedList
  //
  const availbleSites: SiteType[] = data.availbleSites.filter((site) => {
    const isSelected = activeSitesOnproject.indexOf(site._id.toString()) === -1
    return isSelected
  })

  //
  // control when "add site" drawer is open
  //
  const [viewAddSiteDrawer, setViewAddSiteDrawer] = useState<boolean>(false)

  //
  // Remove a site from the list
  //
  const removeSiteFromList = (siteId: string) => {
    const update = selectedSites.filter((site) => {
      return site._id !== siteId
    })

    const idsArr = update.map((site) => site._id)
    setIsLoading(siteId)
    updateSitesPut(projectId, update, idsArr)
  }

  //
  // Remove a site from the list
  //
  const addSiteClick = (e) => {
    const selectedIndex = Number(e.key)
    const item = availbleSites[selectedIndex]
    const update = selectedSites.concat([item])

    const idsArr = update.map((site) => site._id)
    setIsLoading(item._id)
    setSelectedSites(update)
    updateSitesPut(projectId, update, idsArr)
  }

  //
  // Call to back to update
  //
  const updateSitesPut = (pId, updatedSiteArray, idsArr) => {
    Promise.allSettled([
      updateSitesOnProject(pId, { siteIds: idsArr }),
      new Promise((resolve) => setTimeout(resolve, 800)),
    ])

      .then(() => {
        setIsLoading('')
        setSelectedSites(updatedSiteArray)
        mutate(getSitesByProjectId_endpoint(pId))
        mutate(getProjectSettings_endpoint(pId))
      })
      .catch((error) => {
        console.log(' ProjectSiteDropDown > adding site with = ', error)
        alert(error)
      })
  }

  const markers =
    selectedSites.length === 0
      ? []
      : selectedSites.map((site) => {
          return {
            lat: Math.round(site.position.lat * 100000) / 100000,
            lng: Math.round(site.position.lng * 100000) / 100000,
            active: site.active,
            internalName: site.internalName,
          }
        })

  const closeAddDrawer = () => {
    setViewAddSiteDrawer(false)
  }
  const showAddDrawer = () => {
    setViewAddSiteDrawer(true)
  }

  //
  // create the content of the drop down menu
  //
  const menu = availbleSites.map((item, index) => {
    return <Menu.Item key={index}>{item.internalName}</Menu.Item>
  })

  return (
    <GroupHeader
      label="Project sites"
      header={
        <Button onClick={showAddDrawer} type="primary">
          <p className="p-small mb-0">Add new site</p>
        </Button>
      }
    >
      <>
        <Routes>
          <Route
            path="/site/:siteId"
            element={
              <SiteEditDrawer projectId={projectId} maxDistance={maxDistance} maxDistanceTravel={maxDistanceTravel} />
            }
          />
          <Route path="/site/:siteId/investigators/*" element={<InvestigatorDrawerComponent />} />
        </Routes>

        {viewAddSiteDrawer && (
          <SiteAddDrawer
            closeDrawer={closeAddDrawer}
            projectId={projectId}
            maxDistance={maxDistance}
            maxDistanceTravel={maxDistanceTravel}
          ></SiteAddDrawer>
        )}

        <div className="col-12 mt-3 mb-3">
          <Dropdown overlay={<Menu onClick={addSiteClick}>{menu}</Menu>} className="w-100 ">
            <Button className="d-flex w-100 justify-content-between pt-3" style={{ height: 54 }}>
              <p className="fw-bold">Select a sites</p>
              <div className="mt-n1">
                <DownOutlined />
              </div>
            </Button>
          </Dropdown>
        </div>

        <div className="col-12 ">
          {selectedSites.map((site, index) => {
            const itemLoading = site._id === isLoading
            return (
              <ProjectSiteListItem
                key={index}
                isLoading={itemLoading}
                site={site}
                remove={removeSiteFromList}
              ></ProjectSiteListItem>
            )
          })}
        </div>

        {selectedSites.length > 0 ? (
          <div className="col-12">
            <hr className="mt-1 mb-2" />
          </div>
        ) : null}
        <SiteMap
          width={'100%'}
          height={'400px'}
          markers={markers}
          maxDistance={maxDistance}
          maxDistanceTravel={maxDistanceTravel}
        ></SiteMap>
      </>
    </GroupHeader>
  )
}
