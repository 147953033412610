import { getReq } from '../service/API'
import useSWR from 'swr'
import { getProjects_endpoint } from '../service/api-gateway'

export const useProjects = () => {
  const p = getProjects_endpoint

  const { data, error } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
