import { Question, LanguageISOCode } from '../../../Types'
import { InfoQuestionForm, InfoQuestionFormProps } from './InfoQuestionForm'
import { NumberQuestionForm, NumberQuestionFormProps } from './NumberQuestionForm'
import { BmiQuestionForm, BmiQuestionFormProps } from './BmiQuestionForm'
import { CheckboxQuestionForm, CheckboxQuestionFormProps } from './CheckboxQuestionForm'
import { RadioQuestionForm, RadioQuestionFormProps } from './RadioQuestionForm'
import { TextQuestionForm, TextQuestionFormProps } from './TextQuestionForm'
import { PhoneNumberQuestionForm, PhoneNumberQuestionFormProps } from './PhoneNumberQuestionForm'
import { BirthdateQuestionForm, BirthdateQuestionFormProps } from './BirthdateQuestionForm'

export interface QuestionFormProps {
  language: LanguageISOCode
  question: Question
  onSubmit: (answer: string) => void
  isEndScreen?: boolean
}

export function QuestionForm(props: QuestionFormProps) {
  let SpecializedForm = <p>Question Type Not Found!!</p>

  switch (props.question.type) {
    case 'info':
      SpecializedForm = InfoQuestionForm(props as InfoQuestionFormProps)
      break
    case 'text':
      SpecializedForm = TextQuestionForm(props as TextQuestionFormProps)
      break
    case 'radio':
      SpecializedForm = RadioQuestionForm(props as RadioQuestionFormProps)
      break
    case 'checkbox':
      SpecializedForm = CheckboxQuestionForm(props as CheckboxQuestionFormProps)
      break
    case 'number':
      SpecializedForm = NumberQuestionForm(props as NumberQuestionFormProps)
      break
    case 'bmi':
      SpecializedForm = BmiQuestionForm(props as BmiQuestionFormProps)
      break
    case 'name':
      SpecializedForm = TextQuestionForm(props as TextQuestionFormProps)
      break
    case 'phonenumber':
      SpecializedForm = PhoneNumberQuestionForm(props as PhoneNumberQuestionFormProps)
      break
    case 'gender':
      SpecializedForm = RadioQuestionForm(props as RadioQuestionFormProps)
      break
    case 'birthdate':
      SpecializedForm = BirthdateQuestionForm(props as BirthdateQuestionFormProps)
      break
  }

  return <QuestionFormContainer>{SpecializedForm}</QuestionFormContainer>
}

function QuestionFormContainer({ children }) {
  return (
    <div className="container w-100 d-flex flex-column justify-content-center" style={{ height: '100%' }}>
      {children}
    </div>
  )
}
