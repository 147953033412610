import { ImageQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'

export const ImageCreateForm = (props: TypedQuestionCreateFormProps<ImageQuestion>) => {
  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12 mt-5 ">
          <h5>ImageCreateForm</h5>
        </div>
      </div>
    </div>
  )
}

export default ImageCreateForm
