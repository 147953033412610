/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/07/2022.
 */

import { Alert, Button, Spin } from 'antd'
import React, { useState } from 'react'
import { FormLabel } from '../../components/form/FormLabel'
import { sanityCheckMassEmail, massEmail } from '../../service/api-gateway'
import notification from 'antd/lib/notification'
import { SmileOutlined, FrownOutlined } from '@ant-design/icons'
import { Input } from 'antd'

const { TextArea } = Input

export const ProjectMassEmail = () => {
  const [subjectIds, setSubjectIds] = useState<any>('')
  const [tempName, setTempName] = useState<any>('')
  const [subject, setSubject] = useState<any>('')
  const [sender, setSender] = useState<any>('')
  const [sentEmailResult, setSentEmailResult] = useState<any>(true)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>()

  const successNotification = () => {
    notification.open({
      duration: 5,
      message: 'Emails have been successfully sent',
      description: 'You have sent the selected recipients an email',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    })
  }

  const errorNotification = () => {
    notification.open({
      duration: 5,
      message: 'Error while sending emails',
      description:
        'Check if all provided parameters are correct. If the issue persists please contact your system administrator.',
      icon: <FrownOutlined style={{ color: '#dc3545' }} />,
    })
  }

  const convertStringToArray = (subjectIdsString) => {
    const elementsArray = subjectIdsString.split(',')
    return elementsArray
  }

  const sanityCheck = () => {
    const idsArray = convertStringToArray(subjectIds)
    const payload = { ids: idsArray, templateName: tempName, subject: subject, sender: sender }

    setLoading(true)

    setSentEmailResult(true)

    return setTimeout(() => {
      return sanityCheckMassEmail(payload)
        .then((result) => {
          console.log('🚀 = .then = result:', result)
          setLoading(false)
          setResult(result.data)
          setSentEmailResult(result.data.mailSent)
          return result
        })
        .catch((error) => {
          setResult(error)
          setSentEmailResult(false)
          setLoading(false)
          console.log('error = ', error)
        })
    }, 1000)
  }

  const triggerMassEmail = () => {
    const idsArray = convertStringToArray(subjectIds)
    const payload = { ids: idsArray, templateName: tempName, subject: subject, sender: sender }

    setLoading(true)

    return setTimeout(() => {
      return massEmail(payload)
        .then((result) => {
          setLoading(false)
          if (result.data) {
            successNotification()
          } else {
            errorNotification()
          }
          return result
        })
        .catch((error) => {
          console.log('error = ', error)
          setLoading(false)
          errorNotification()
        })
    }, 1000)
  }

  const handleSubjectIds = (e) => {
    setSubjectIds(e.target.value)
  }

  const handleTemplateName = (e) => {
    setTempName(e.target.value)
  }

  const handleSubject = (e) => {
    setSubject(e.target.value)
  }

  const handleSenderEmail = (e) => {
    setSender(e.target.value)
  }

  const validation = () => {
    if (
      subjectIds.length > 0 &&
      tempName.length > 0 &&
      subject.length > 0 &&
      sender.length > 0 &&
      isStudiesandMeEmail(sender) &&
      hasAtsign(sender)
    ) {
      return false
    } else {
      return true
    }
  }

  const hasAtsign = (str) => {
    return str.indexOf('@') > -1
  }

  const isStudiesandMeEmail = (str: string) => {
    const sandme: string = 'studiesandme.com'
    const start = str.length - sandme.length
    const k = str.substring(start, str.length)
    return k.toString() === sandme
  }

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-4">
          <div className="w-100 py-1">
            <FormLabel label={'Please specify the template name'} />
            <input
              type="text"
              className="ant-input mb-1 rounded"
              autoComplete="nope"
              placeholder="Enter template name"
              onChange={handleTemplateName}
            ></input>
            {sentEmailResult ? (
              <></>
            ) : (
              <div style={{ color: 'red' }}>
                <FormLabel label={'Please ensure that the template name is correct'} />
              </div>
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="w-100 py-1">
            <FormLabel label={'Please specify the subject for the emails'} />
            <input
              type="text"
              className="ant-input mb-1 rounded"
              autoComplete="nope"
              placeholder="Enter subject"
              onChange={handleSubject}
            ></input>
          </div>
        </div>
        <div className="col-4">
          <div className="w-100 py-1">
            <FormLabel label={'Please specify sender email address'} />
            <input
              type="text"
              className="ant-input mb-1 rounded"
              autoComplete="nope"
              placeholder="Enter sender email address"
              onChange={handleSenderEmail}
            ></input>
            {sender.length > 0 && !hasAtsign(sender) ? (
              <div style={{ color: 'red' }}>
                <FormLabel label={'Missing an @ sign'} />
              </div>
            ) : (
              <></>
            )}
            {sender.length > 0 && !isStudiesandMeEmail(sender) ? (
              <div style={{ color: 'red' }}>
                <FormLabel label={'Email address to be a valid studiesandme.com email'} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="w-100 py-1 mt-2">
        <FormLabel label={'Please provide a list of subject ids you would like to send emails to'} />
        <TextArea
          className="ant-input mb-1 rounded"
          autoSize={{ minRows: 2, maxRows: 15 }}
          placeholder="Enter a list of subject ids"
          onChange={handleSubjectIds}
        />
      </div>
      <div className="col-12 mt-3">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-8">
              {!loading && result ? (
                <>
                  {result.errorData ? (
                    <Alert
                      message={result.errorData.message}
                      className="rounded mt-2 mb-3"
                      description={
                        <ul>
                          {result.errorData.invalidIds.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      type="error"
                      showIcon
                    />
                  ) : (
                    <></>
                  )}

                  {result.emailSent === true ? (
                    <Alert
                      className="rounded mt-2 mb-3"
                      description="All parameters are correct and emails have been sent out successfully"
                      type="success"
                      showIcon
                      style={{ maxWidth: 650 }}
                      action={
                        <Button className="rounded" onClick={triggerMassEmail}>
                          ✉️ Dispatch emails
                        </Button>
                      }
                    />
                  ) : (
                    <Alert
                      className="rounded mt-2 mb-3"
                      description="Oops something went wrong and we didn't send out emails."
                      type="error"
                      showIcon
                      style={{ maxWidth: 650 }}
                    />
                  )}

                  {result.mismatchedIds ? (
                    <Alert
                      message={`There is a mismatch with recipients that do not exist in the Database: `}
                      className="rounded mt-2 mb-3"
                      description={
                        <ul>
                          {result.mismatchedIds.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      type="warning"
                      showIcon
                    />
                  ) : (
                    <></>
                  )}

                  {result.invalidSubjectIds ? (
                    <Alert
                      message={`These subs have no emails found in Cognito: `}
                      className="rounded mt-2 mb-3"
                      description={
                        <ul>
                          {result.invalidSubjectIds.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      }
                      type="warning"
                      showIcon
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col-4 d-flex justify-content-end">
              <Button
                onClick={sanityCheck}
                className="mt-2 mb-5 rounded"
                style={{ width: 275 }}
                disabled={validation()}
              >
                👥 Sanity check & send test emails
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  )
}
