export function QuestionTitle(props) {
  const questionTitleStyle = {
    marginBottom: '3rem',
    fontSize: '20px',
    fontWeight: '600',
    maxWidth: '600px',
  }

  return <h3 style={questionTitleStyle}>{props.children}</h3>
}
