import { Alert, Button, Divider, Drawer } from 'antd'
import { useState } from 'react'
import { post } from '../../service/API'
import { BASE_URL } from '../../constants'
import Modal from 'antd/lib/modal/Modal'
import Input from 'antd/lib/input/Input'
import { authStore } from '../../store/auth/authStore'
import { Timeline } from 'antd'
import { useEvent } from '../../hooks/useEvent'
import hrt from 'human-readable-time'
import { useNavigate } from 'react-router-dom'

export const UserLookup = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [deleteText, setDeleteText] = useState<string>()
  const [deleteTextError, setDeleteTextError] = useState<boolean>(false)
  const [showWarning, setShowWarning] = useState(false)
  const [deleteResult, setDeleteResult] = useState<any>()

  const user = authStore((state) => state.user)

  const name = user?.attributes.email || 'System user'

  const [showAditionalInfo, setShowAditionalInfo] = useState<boolean>(false)

  const showSubjectAditionalInfo = () => {
    setShowAditionalInfo(true)
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const handleGet = () => {
    setDeleteResult(null)

    if ((email.length > 4 && email.indexOf('@') > -1) === false) {
      setShowWarning(true)
    } else {
      setShowWarning(false)

      const obj = { email: email }

      console.log(' UserLookup > obj = ', obj)

      post(BASE_URL + '/api/delete-user/', obj)
        .then((result) => {
          console.log('UserLookup > result = ', result)
          setData(result.data)
        })
        .catch((error) => {
          setError(error)
          console.log('UserLookup > error = ', error)
          return Promise.reject(error)
        })
    }
  }

  const handleDelete = () => {
    if (deleteText === 'permanently delete') {
      setLoading(true)
      setDeleteTextError(false)
      setTimeout(() => {
        const obj = {
          email: data.cognito.email,
          subjectSub: data.cognito.sub,
          deletionDoneBy: name,
          subjectId: data.subject,
        }

        post(BASE_URL + '/api/delete-user/remove-cognito-pii', obj)
          .then((result) => {
            setDeleteResult(result)
            console.log('UserLookup > delete = ', result)
          })
          .catch((error) => {
            setError(error)
            console.log('UserLookup > error = ', error)
            return Promise.reject(error)
          })

        setLoading(false)
        setOpenModal(false)
      }, 3000)
    } else {
      setDeleteTextError(true)
    }
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDeleteText(e.target.value)
  }

  return (
    <div className="row my-2">
      <div className="col-12">
        <p>Email</p>
        <div className="mt-2 d-flex justify-content-between">
          <div className="col-10">
            <input type="text" className="ant-input rounded" onChange={(e) => setEmail(e.target.value)} />
          </div>

          <Button className="rounded" onClick={handleGet} style={{ width: '15%' }}>
            🧑 Get user
          </Button>
        </div>

        {showWarning ? (
          <div className="mt-4 rounded">
            <Alert message="Email invalid" description="Please provide a valid email address" type="warning" showIcon />
          </div>
        ) : null}

        {data === null ? (
          <div className="mt-4">
            <Alert
              className="rounded"
              message="User does not exist"
              description="We could not find a user with the provided email"
              type="error"
              showIcon
            />
          </div>
        ) : null}

        {error ? (
          <div>{error}</div>
        ) : data ? (
          <>
            <SubjectInfo
              data={data}
              showModal={showModal}
              showSubjectAditionalInfo={showSubjectAditionalInfo}
            ></SubjectInfo>

            {showAditionalInfo ? <SubjectAditionalInfo data={data} /> : <></>}

            <Modal
              bodyStyle={{ borderRadius: '25px' }}
              visible={openModal}
              title="Are you sure you want to delete this subject's PII?"
              onOk={handleDelete}
              onCancel={handleCancel}
              footer={[
                <Button className="rounded" key="back" onClick={handleCancel}>
                  Return
                </Button>,
                <Button className="rounded" key="submit" type="primary" loading={loading} onClick={handleDelete}>
                  Submit
                </Button>,
              ]}
            >
              <p>
                This action must only be done if a subject has <b>directly</b> contacted us regarding their PII and
                <b> requested the deletion of all their PII data</b>
              </p>
              <Divider dashed />
              <p className="mt-3">
                To permanently delete a subject's PII, please write <b>permanently delete</b> in the input field below
              </p>
              <Input className="mt-4 rounded" placeholder="permanently delete" onChange={onChange} />
              {deleteTextError ? (
                <Alert
                  className="mt-3 rounded"
                  message="Cannot submit before writing the required phrase"
                  type="warning"
                  showIcon
                />
              ) : null}
            </Modal>
          </>
        ) : (
          <></>
        )}

        {deleteResult ? (
          <div className="mt-4">
            <Alert
              className="rounded"
              message="Deletion result"
              description={deleteResult.data.response}
              type="info"
              showIcon
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const SubjectInfo = ({ data, showModal, showSubjectAditionalInfo }) => {
  return (
    <div className="col-12">
      <div className="w-100 d-flex item-shadow mt-3 p-3 justify-content-between">
        <div className=" ps-1 my-1" style={{ width: '15%' }}>
          <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
            Name
          </p>
          <p className="mb-1 fw-bold p-small text-black">
            {data.cognito.name ? (data.cognito.name ? data.cognito.name : 'No name') : 'No Cognito'}
          </p>
        </div>
        <div className=" ps-1 my-1" style={{ width: '30%' }}>
          <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
            Email
          </p>
          <p className="mb-1 fw-bold p-small text-black">
            {data.cognito ? (data.cognito.email ? data.cognito.email : 'No email') : 'No Cognito'}
          </p>
        </div>
        <div className=" ps-1 my-1" style={{ width: '20%' }}>
          <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
            Enrollment ID
          </p>
          <p className="mb-1 fw-bold p-small text-black">
            {data.enrollment ? (data.enrollment._id ? data.enrollment._id : 'No id') : 'No Cognito'}
          </p>
        </div>

        <div className="w-25 d-flex align-items-center justify-content-between">
          <Button danger style={{ width: '60%' }} onClick={showModal}>
            Delete user
          </Button>
          <Button type="default" style={{ width: '30%' }} onClick={showSubjectAditionalInfo}>
            🔍
          </Button>
        </div>
      </div>
    </div>
  )
}

const SubjectAditionalInfo = ({ data }) => {
  const [showTimeline, setShowTimeline] = useState<boolean>(false)

  const enrollmentData = data.enrollment ? data.enrollment : {}

  const showDrawer = () => {
    setShowTimeline(true)
  }

  return (
    <div className="container col-12">
      <div className="row">
        <div className="col-12">
          <div className="item-shadow mt-4 p-3 d-flex justify-content-between">
            <div className=" ps-1 my-1 mt-2" style={{ width: '35%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Project
              </p>
              <p className="fw-bold p-mini text-black mb-2 alpha-80" style={{ fontSize: '.75em' }}>
                {enrollmentData.project ? enrollmentData.project.toUpperCase() : '-'}
              </p>
            </div>
            <div className=" ps-1 my-1  mt-2" style={{ width: '65%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Subject ID
              </p>
              <p className="fw-bold p-mini text-black mb-2" style={{ fontSize: '.75em' }}>
                {enrollmentData.subjectId ? enrollmentData.subjectId : '-'}
              </p>
            </div>
            <div className=" ps-1 my-1 mt-2" style={{ width: '40%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Subject state
              </p>
              <p className="mb-1 fw-bold p-small text-black">
                {enrollmentData.stateType ? enrollmentData.stateType : '-'}
              </p>
            </div>
            <div className=" ps-1 my-1  mt-2" style={{ width: '40%' }}>
              <p className="p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Display name
              </p>
              <p className="fw-bold p-mini text-black mb-2" style={{ fontSize: '.75em' }}>
                {enrollmentData.displayName ? enrollmentData.displayName : '-'}
              </p>
            </div>
            <div className=" ps-1 my-1  mt-2" style={{ width: '40%' }}>
              <p className="p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Language
              </p>
              <p className="fw-bold p-mini text-black mb-2" style={{ fontSize: '.75em' }}>
                {enrollmentData.language ? enrollmentData.language.toUpperCase() : '-'}
              </p>
            </div>
            <div className=" ps-1 my-1  mt-2" style={{ width: '20%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold" style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                Zipcode
              </p>
              <p className="fw-bold p-mini text-black mb-2" style={{ fontSize: '.75em' }}>
                {enrollmentData.zipcode ? enrollmentData.zipcode : '-'}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <Button onClick={showDrawer}>Event history</Button>
              {data.enrollment ? (
                <RenderTimeline data={data} showTimeline={showTimeline} setShowTimeline={setShowTimeline} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderTimeline = ({ data, showTimeline, setShowTimeline }) => {
  const { data: eventData } = useEvent(data.enrollment._id)

  const navigate = useNavigate()

  const layoutChange = (e) => {
    if (!e) navigate('/user-management/')
  }

  const onClose = () => {
    setShowTimeline(false)
  }

  return (
    <Drawer
      afterVisibleChange={layoutChange}
      title={'Event history'}
      destroyOnClose={true}
      width={'30%'}
      placement="right"
      onClose={onClose}
      visible={showTimeline}
    >
      <Timeline mode="left">
        {eventData.map((event) => {
          const eventCreatedAt = hrt(new Date(event.updatedAt), '%hh%:%mm%:%ss% on %D% %month% %YYYY%')
          return (
            <Timeline.Item className="mr-2">
              <p className="fw-bold p-small">{event.eventType}</p>
              <p className="p-small">{eventCreatedAt}</p>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </Drawer>
  )
}
