/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/11/2022.
 */

import React from 'react'
import { format, formatDistance } from 'date-fns'
import { useAllSites } from '../../hooks/useAllSites'

export const SitePage = () => {
  const { data } = useAllSites()

  const groupedByProject = data.reduce((acc, siteWithProject) => {
    const projectName = siteWithProject.project.title
    const projectId = siteWithProject.project._id

    if (!acc[projectName]) {
      acc[projectName] = { projectTitle: projectName, _id: projectId, sites: [] }
    }

    const current = acc[projectName]
    current.sites.push(siteWithProject)

    return acc
  }, {})

  console.log(' SitePage > groupedByProject = ', groupedByProject)

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 d-flex justify-content-between mt-4 mb-3">
          <div className="">
            <h4 className="font-weight-bold">Site activity</h4>
          </div>
          <div className="mr-4"></div>
        </div>
      </div>

      {Object.keys(groupedByProject).map((projectKey, index) => {
        //const projectName = projectGroup.project.title

        const list = groupedByProject[projectKey]

        return (
          <div key={index} className="row mb-3 pb-4 bg-white  " style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
            <div className="col-12">
              <div className="ps-2 my-3 ">
                <p className="mb-0 p-small opacity-75 fw-bold">project</p>
                <h4 className="mb-1 fw-bold text-black">{list.projectTitle}</h4>
                <p className="mb-1 p-mini opacity-50 ">{list._id}</p>
              </div>
            </div>

            {list.sites.map((item, subIndex) => {
              const lastMove = item.lastMoveAt ? new Date(item.lastMoveAt) : 0
              const lastStatus = item.lastStatusAt ? new Date(item.lastStatusAt) : 0
              const lastAction = lastMove > lastStatus ? lastMove : lastStatus

              const investigators = item.investigators
              console.log(' SitePage > investigators = ', investigators)

              return (
                <div key={subIndex} className="col-12 px-4 mb-3">
                  <div
                    className="row border pb-2 bg-white"
                    style={{ boxShadow: '0px 4px 4px -5px rgba(0, 0, 0, 0.4)' }}
                  >
                    <div className="col-12">
                      <div className="w-100 pt-4 pb-3 px-2 d-flex ">
                        <div className=" " style={{ width: '30%' }}>
                          <p className="mb-0 p-mini alpha-80 fw-bold">{item.internalName}</p>
                          <p className="mb-1 fw-bold text-black">{item.officialName}</p>
                        </div>

                        <div className="" style={{ width: '30%' }}>
                          <p className="mb-0 p-mini alpha-80">project</p>
                          <p className="mb-0 fw-bold text-primary ">{item.project?.title}</p>
                        </div>

                        <div className="" style={{ width: '30%' }}>
                          <p className="mb-0 p-mini alpha-80">Last activity</p>
                          <p className="mb-0 fw-bold text-primary ">
                            {lastAction !== 0
                              ? formatDistance(lastAction, new Date(), { addSuffix: true })
                              : 'No activity'}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 px-4 ">
                      <div className="row " style={investigators.length > 0 ? { borderTop: '1px solid #E2E2E2' } : {}}>
                        {investigators.map((investigator, index) => {
                          return (
                            <div key={index} className="col-12 d-flex  py-4 mb-2" style={{ background: '#f6f6f6' }}>
                              <div className="" style={{ width: '30%' }}>
                                <p className="p-mini opacity-75 text-capitalize">{investigator.name}</p>
                                <p className="p-small ">{investigator.email}</p>
                              </div>

                              <div className="" style={{ width: '30%' }}>
                                <p className="p-mini opacity-75">Traning completed</p>
                                <p className="p-small">
                                  {investigator.trainingComplete
                                    ? format(new Date(investigator.trainingComplete), 'MM/dd/yyyy')
                                    : 'not completed'}
                                </p>
                              </div>

                              <div className="" style={{ width: '20%' }}>
                                <p className="p-mini opacity-75">Last active</p>
                                <p className="p-small">
                                  {investigator.lastActiveAt
                                    ? formatDistance(new Date(investigator.lastActiveAt), new Date(), {
                                        addSuffix: true,
                                      })
                                    : 'No activity'}
                                </p>
                              </div>
                              <div className="" style={{ width: '20%' }}>
                                <p className="p-mini opacity-75">Invite sent</p>
                                <p className="p-small">
                                  {investigator.inviteSent
                                    ? formatDistance(new Date(investigator.inviteSent), new Date(), {
                                        addSuffix: true,
                                      })
                                    : 'Not sent'}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}

      {/*<div className="row" style={{}}>
        {data.map((item: any, index: number) => {
          //console.log(' SitePage > item = ', item)

          const lastMove = item.lastMoveAt ? new Date(item.lastMoveAt) : 0
          const lastStatus = item.lastStatusAt ? new Date(item.lastStatusAt) : 0
          const lastAction = lastMove > lastStatus ? lastMove : lastStatus

          const investigators = item.investigators

          return (
            <div className="col-12 mb-3" key={index}>
              <div className="w-100 p-4 d-flex item-shadow">
                <div className="pl-2 " style={{ width: '40%' }}>
                  <p className="mb-0 p-mini alpha-80 fw-bold">{item.internalName}</p>
                  <p className="mb-1 fw-bold text-black">{item.officialName}</p>
                </div>

                <div className="" style={{ width: '30%' }}>
                  <p className="mb-0 p-mini alpha-80">project</p>
                  <p className="mb-0 fw-bold text-primary ">{item.project?.title}</p>
                </div>

                <div className="" style={{ width: '30%' }}>
                  <p className="mb-0 p-mini alpha-80">Last activity</p>
                  <p className="mb-0 fw-bold text-primary ">
                    {lastAction !== 0 ? formatDistance(lastAction, new Date(), { addSuffix: true }) : 'No activity'}
                  </p>
                </div>
              </div>
              <div className="bg-white p-2">
                <div className="" style={{ background: '#f5f5f5' }}>
                  <div className="d-flex justify-content-between">
                    <p className="p-small pt-3 mb-0 ms-2 fw-bold py-2" style={{ width: '40%' }}>
                      Investigators
                    </p>
                    <p className="p-small pt-3 mb-0 ms-2 fw-bold py-2" style={{ width: '30%' }}>
                      Completed training
                    </p>
                    <p className="p-small pt-3 mb-0 ms-2 fw-bold py-2 text-end" style={{ width: '30%' }}>
                      Last active
                    </p>
                  </div>
                  <hr className="my-1" />
                  {investigators.map((investigator, index) => {
                    return (
                      <div key={index} className="col-12 d-flex justify-content-between px-2 py-2">
                        <p className="p-small" style={{ width: '43%' }}>
                          {investigator.name}
                        </p>
                        <p className="p-small" style={{ width: '40%' }}>
                          {investigator.trainingComplete
                            ? format(new Date(investigator.trainingComplete), 'MM/dd/yyyy')
                            : 0}
                        </p>
                        <p className="text-end p-small" style={{ width: '20%' }}>
                          {investigator.lastActiveAt
                            ? formatDistance(new Date(investigator.lastActiveAt), new Date(), { addSuffix: true })
                            : '-'}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>*/}
    </div>
  )
}
