import { Button } from 'antd'
import { useState } from 'react'
import { getTestingAnswers } from '../../service/api-gateway'

export function AnswerLookup() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const handleClick = () => {
    getTestingAnswers(email)
      .then((result) => setData(result))
      .catch((err) => setError(err.errorData?.message || err.message))
  }

  return (
    <div className="row my-2">
      <div className="col-12 mb-3">
        <h6 className="fw-bold ">Look up answers</h6>
      </div>
      <div className="col-12">
        <p>Email</p>
        <div className="d-flex ">
          <input type="text" className="ant-input" onChange={(e) => setEmail(e.target.value)} />
          <Button onClick={handleClick}>Get Answers</Button>
        </div>

        {error ? (
          <div>{error}</div>
        ) : data ? (
          (data as any).data.map((d: any, i: number) => (
            <div key={i} className="item-shadow mt-3 p-3">
              <p style={{ color: 'rgba(0,0,0,.5)', fontSize: '.75em' }}>
                {d.question.title['en'] || Object.entries(d.question.title)[0][1]}
              </p>

              <p>{d.value || '<No Value>'}</p>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
