/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05/11/2022.
 */
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { GroupHeader } from '../../components/display/GroupHeader'
import { ProjectAddLinkDrawer } from './ProjectAddLinkDrawer'

type Props = {
  formHook: any
  isLoading: boolean
  handleSubmit: () => void
}

export const ProjectLinks = ({ formHook, handleSubmit, isLoading }: Props) => {
  const [viewLinkDrawer, setViewLinkDrawer] = useState<boolean>(false)

  const [links, setLinks] = useState(formHook.getValues('links'))

  useEffect(() => {})

  const showDrawer = () => {
    setViewLinkDrawer(true)
  }

  const closeDrawer = () => {
    setViewLinkDrawer(false)
  }

  const addLink = (obj: any) => {
    const newLinks = links.concat([obj])
    formHook.setValue('links', newLinks)
    setLinks(newLinks)
    handleSubmit()
  }

  const removeLink = (url: string) => {
    const newLinks = links.filter((link) => {
      return link.url !== url
    })

    formHook.setValue('links', newLinks)
    setLinks(newLinks)
    handleSubmit()
  }

  return (
    <GroupHeader
      label="Project links"
      header={
        <Button onClick={showDrawer} type="primary">
          <p className="p-small mb-0">Edit or add links</p>
        </Button>
      }
    >
      <>
        {viewLinkDrawer ? (
          <ProjectAddLinkDrawer
            closeDrawer={closeDrawer}
            addLink={addLink}
            links={links}
            removeLink={removeLink}
            isLoading={isLoading}
          ></ProjectAddLinkDrawer>
        ) : null}

        <div className="col-12 mb-3 bg-white mt-1 px-4">
          <div className="row ">
            {links.map((item, index) => {
              return (
                <div key={index} className="col-4 mt-2">
                  <div className="p-4 border">
                    <h6 className="mb-0 fw-bold text-capitalize">{item.title}</h6>
                    <p className="p-small opacity-75 text-truncate">{item.url}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
    </GroupHeader>
  )
}
