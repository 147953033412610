/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/07/2022.
 */
import { Button } from 'antd'
import React from 'react'
import { GroupHeader } from '../../components/display/GroupHeader'
import { FormTextField } from '../../components/form/FormTextField'
import Spinner from '../../components/spinner/Spinner'

type Props = { formHook: any; handleSubmit: () => void; isLoading: boolean }

export const ProjectMailSender = ({ formHook, handleSubmit, isLoading }: Props) => {
  const obj = {
    id: 'mailsender',

    placeholder: 'example@studiesandme.com',
    label: 'Specify a sender email address',
    prefix: '',
    postfix: '',
    defaultValue: '',
    value: '',
    validation: {
      validate: {
        hasAtsign: (str) => str.indexOf('@') > -1 || 'Email address must have an @ sign',
        isstudiesandMeEmail: (str: string) => {
          const sandme: string = 'studiesandme.com'
          const start = str.length - sandme.length
          const k = str.substring(start, str.length)
          return k.toString() === sandme || 'Must be an studiesandme.com email address'
        },
      },
    },

    errorMsg: 'please provide an valid address',
  }

  return (
    <GroupHeader
      label="Mail sender "
      header={
        <p className="opacity-75 p-small">
          The email address which sends <output>all emails</output>
        </p>
      }
    >
      <div className="col-12 mb-3 bg-white mt-1 d-flex align-items-center">
        <FormTextField data={obj} formHook={formHook}></FormTextField>
        <div className="ps-2">
          <Button onClick={handleSubmit} className="mt-4" style={{ width: 100 }} type="primary" disabled={isLoading}>
            {!isLoading ? (
              'Save'
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <Spinner size={3} style={{ color: 'white' }}></Spinner>
              </div>
            )}
          </Button>
        </div>
      </div>
    </GroupHeader>
  )
}
