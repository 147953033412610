import { useEffect, useState } from 'react'
import { SiteMap } from './SiteMap'

export const PlaceSiteWrapper = ({ formHook, width, height, maxDistanceTravel, maxDistance }) => {
  const { getValues, setValue } = formHook

  const [sitePosition, setSitePosition] = useState<{ lat: number; lng: number }[]>([])

  const active = getValues('active') ? getValues('active') : false

  const setMarker = (value) => {
    setValue('position', { lng: value.lng, lat: value.lat })
    //
    // by dennis - you would assume we did not need setSitePosition here, since it should be called automaticlly
    // in the useEffect hook when the formHook value is updated.
    //
    // i am guessing this is caused by the useFormhook which limits rerenders
    //
    setSitePosition([{ lng: value.lng, lat: value.lat }])
  }

  const pos = getValues().position
  useEffect(() => {
    const hasPos = pos ? [pos] : []
    if (hasPos) {
      setSitePosition(hasPos)
    }
  }, [getValues, pos])

  return (
    <SiteMap
      setMarker={setMarker}
      width={width}
      height={height}
      formHook={formHook}
      markers={sitePosition}
      active={active}
      maxDistance={maxDistance}
      maxDistanceTravel={maxDistanceTravel}
    ></SiteMap>
  )
}
