import Button from 'antd/lib/button'
import { Alert, Divider } from 'antd'

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { unsubscribeEnrollment } from '../../service/api-gateway'

import support from '../../assets/support.png'

export const UnsubscribeSuccess = () => {
  const params = useParams()

  const [errorState, setErrorState] = useState<any>()
  const [unsubscribedSuccess, setUnsubscribedSuccess] = useState<any>(false)

  const unsubscribe = () => {
    if (params.token) {
      unsubscribeEnrollment(params.token)
        .then((res) => {
          console.log('UnsubscribeSuccess = > success', res)
          setUnsubscribedSuccess(true)
        })
        .catch((error) => {
          console.log('UnsubscribeSuccess = > fail', error)
          setErrorState(error)
          setUnsubscribedSuccess(false)
        })
    } else {
      setUnsubscribedSuccess(false)
    }
  }

  return (
    <>
      <div className="container-fluid " style={{ background: '#FAEFE5', minHeight: '100%' }}>
        <div className="container ">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-12 h-50">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-8 mt-5 mb-0 mb-md-4 ">
                  <div className="shadow w-100 h-100 d-flex flex-column" style={{ background: '#FFFFFF' }}>
                    {!unsubscribedSuccess ? (
                      <>
                        <h2 className="font-weight-bold text-center p-4 fw-bold">
                          <b>Unsubscribe</b>
                        </h2>
                        <div className="w-100 p-4 ">
                          <p style={{ textAlign: 'center' }}>
                            We are sorry to hear that you are no longer interested in receiving mails from us.
                          </p>
                          <p className="mt-4" style={{ textAlign: 'center' }}>
                            We hope to see you again.
                          </p>
                          <p className="mt-4" style={{ textAlign: 'center' }}>
                            Click the button below and you will no longer receive emails about the study.
                          </p>
                          <Divider style={{ marginTop: '40px' }} />
                          <div style={{ marginTop: '45px' }}>
                            <Button
                              block
                              shape="default"
                              size="large"
                              ghost
                              style={{ background: 'black', borderRadius: '6px' }}
                              onClick={unsubscribe}
                            >
                              <b>Unsubscribe</b>
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="font-weight-bold text-center p-4">
                          <b>You have unsubscribed</b>
                        </h3>
                        <div className="w-100 p-4 ">
                          <p style={{ textAlign: 'center' }}>
                            We are sorry to see you go but we respect your unsubscription from our mailing list.
                          </p>
                          <p className="mt-4" style={{ textAlign: 'center' }}>
                            If you have signed up for other types of email services from us, they will continue
                            unchanged.
                          </p>
                          <Divider style={{ marginTop: '50px' }} />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-5 col-lg-4 mt-5 mb-5">
                  <div className="shadow w-100 p-4" style={{ background: '#FFFFFF' }}>
                    <h5 className="font-weight-bold text-center">
                      <b>The support team is always ready to help</b>
                    </h5>
                    <div className="mt-3" style={{ textAlign: 'center' }}>
                      <img alt="" src={support} style={{ maxHeight: '120px' }} />
                    </div>
                    <p className="mt-3" style={{ textAlign: 'center' }}>
                      Please send your your inquiry via email. Our study personnel will get back to you within three
                      working days.
                    </p>
                    <Divider />
                    <a href="mailto:support@studiesandme.com" target="_blank" rel="noopener noreferrer">
                      <p className="font-weight-bold text-black text-center text-decoration-none">
                        <b>support@studiesandme.com</b>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              {errorState ? (
                <Alert
                  className="mt-3"
                  showIcon
                  message={'An error occured'}
                  description={'There was an error with unsubscribing, please contact our support team.'}
                  type="error"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
