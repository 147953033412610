import { CheckboxQuestion, LanguageISOCode, Translation } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { QuestionTitle } from './QuestionTitle'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionBody } from './QuestionBody'
import { useEffect, useState } from 'react'
import { QuestionOption } from './QuestionOption'

export interface CheckboxQuestionFormProps {
  language: LanguageISOCode
  question: CheckboxQuestion
  onSubmit: (answer: string) => void
}

export function CheckboxQuestionForm({ question, language, onSubmit }: CheckboxQuestionFormProps) {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [values, setValues] = useState<boolean[]>(question.options.map(() => false))
  const validIndices = question.options.map((_, i: number) => i.toString())

  useEffect(() => {
    document.addEventListener('keydown', onCheckboxKeyDown)
    return () => document.removeEventListener('keydown', onCheckboxKeyDown)
  })

  const onCheckboxKeyDown = (e) => {
    if (validIndices.includes(e.key)) {
      let clicked = parseInt(e.key)
      toggleValue(clicked)
    }
  }

  const submit = () => {
    const selectedValues = values.map((_, i: number) => i).filter((i) => values[i])

    if (question.required && selectedValues.length === 0) {
      setErrorMsg('Please select at least one option')
    } else {
      onSubmit(selectedValues.join(','))
      setErrorMsg('')
    }
  }

  const toggleValue = (i: number) => {
    setErrorMsg('')
    let newValues = [...values]
    newValues[i] = !newValues[i]
    setValues(newValues)
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>

      <div>
        {question.options.map((option: Translation, i: number) => (
          <div key={i} style={{ maxWidth: '400px' }}>
            <QuestionOption label={option[language]} index={i} onSelect={toggleValue} selected={values[i]} />
          </div>
        ))}
      </div>
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={submit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}
