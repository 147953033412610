import { Avatar, Button, Divider } from 'antd'
import { Suspense } from 'react'
import { Link } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { PROJECT_PAGE_VIEW_INVESTIGATOR } from '../../constants'
import { useInvestigators } from '../../hooks/useInvestigators'
import hrt from 'human-readable-time'

export const InvestigatorList = ({ showChildDrawer, siteId, projectId }: any) => {
  const { data } = useInvestigators()

  const eligbleInvestigators = data ? data.filter((investigator) => investigator.sites.indexOf(siteId) > -1) : []

  const editInvestigator = () => {
    showChildDrawer()
  }

  return (
    <>
      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
        <div className="col-12">
          {eligbleInvestigators.map((investigator, index) => {
            const inviteSent = investigator.inviteSent
              ? 'Invite sent: ' + hrt(new Date(investigator.inviteSent), 'At %hh%:%mm%:%ss% on %D% %month% %YYYY%')
              : 'Invite not sent'

            return (
              <div key={index} className="col-12">
                <div className="bg-white w-100 d-flex">
                  <div className="mt-1" style={{ width: '15%' }}>
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                  </div>
                  <div className="pl-2 " style={{ width: '100%' }}>
                    <p className="mb-0 fw-bold alpha-80 ">{investigator.name} </p>{' '}
                    <p className="mb-0  alpha-60 ">{[investigator.email]} </p>
                    <p className="mb-0 p-small alpha-60 "> {inviteSent}</p>
                  </div>

                  <div className="w-25 d-flex justify-content-end align-items-center">
                    <Link
                      to={PROJECT_PAGE_VIEW_INVESTIGATOR.gotoPage?.(projectId, siteId, investigator._id) || 'undefined'}
                    >
                      <Button type="text" onClick={editInvestigator}>
                        View
                      </Button>
                    </Link>
                  </div>
                </div>
                <Divider />
              </div>
            )
          })}
        </div>
      </Suspense>
    </>
  )
}
