/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/04/2022.
 */
import React from 'react'
import { Switch } from 'antd'
import { Controller } from 'react-hook-form'
import { FormLabel } from './FormLabel'

const FormSwitch = ({ data, formHook }) => {
  const { control } = formHook

  return (
    <div className="mt-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <Controller
        name={data.id}
        control={control}
        render={({ field: { onChange, value } }) => {
          return <Switch checked={value} onChange={onChange} />
        }}
      />
    </div>
  )
}
export default FormSwitch
