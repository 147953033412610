import React from 'react'
import { Circle, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { GOOGLE_MAPS_API_KEY } from '../../constants'

const getMapStartCenter = (markers) => {
  return markers.length > 0
    ? {
        lat: Math.round(markers[0].lat * 100000000) / 100000000, // google maps does not like to precise locations
        lng: Math.round(markers[0].lng * 100000000) / 100000000,
      }
    : {
        lat: 38.30466,
        lng: -92.4371,
      }
}

export const SiteMap = ({ width, height, setMarker, markers = [], active, maxDistance, maxDistanceTravel }: any) => {
  const mapStartPosition = getMapStartCenter(markers)
  //  console.log(' SiteMap > mapStartPosition = ', mapStartPosition)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY as string,
  })

  const onClick = (e: any) => {
    if (!setMarker) return

    let latlng = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }

    if (setMarker) {
      setMarker(latlng)
    }
  }

  if (loadError) return <div>Error loading map</div>

  if (!isLoaded) return <div>Loading map</div>

  return (
    <>
      <div className="mb-3 ">
        <GoogleMap
          mapContainerStyle={{ width, height }}
          zoom={4}
          center={mapStartPosition}
          onClick={onClick}
          onTilesLoaded={() => getMapStartCenter(markers)}
        >
          {markers.map((site, i) => {
            const isActive = site.active ? site.active : active
            return (
              <Marker key={i} position={site} title={site.internalName ? site.internalName : ''}>
                <Circle
                  onClick={onClick}
                  center={site}
                  radius={Number(maxDistanceTravel)}
                  options={{
                    fillColor: '#676767',
                    strokeColor: '#d2d2d2',
                    strokeOpacity: 0,
                    fillOpacity: 0.14,
                  }}
                ></Circle>
                <Circle
                  onClick={onClick}
                  center={site}
                  radius={Number(maxDistance)}
                  options={{
                    strokeColor: isActive ? '#19cb19' : '#ff0000',
                    fillOpacity: 0.1,
                  }}
                ></Circle>
              </Marker>
            )
          })}
        </GoogleMap>
      </div>
    </>
  )
}
