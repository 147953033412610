export const investigatorForm = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add investigator name',
    label: 'Name',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Investigator name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add investigator email',
    label: 'Email',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Investigator email required',
  },
  // {
  //   id: 'phone',
  //   type: 'TEXT',
  //   placeholder: 'Add investigator phone number',
  //   label: 'Phone number',
  //   prefix: '',
  //   postfix: '',
  //   value: '',
  //   validation: { required: true },
  //   errorMsg: 'Investigator phone number required',
  // },
]
