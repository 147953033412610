export interface QuestionOptionProps {
  label: string
  index: number
  onSelect: (i: number) => void
  selected: boolean
}

export function QuestionOption({ label, index, onSelect, selected }: QuestionOptionProps) {
  const radioOptionStyle = {
    margin: '1rem 0',
    padding: '.3rem .4rem',
    borderColor: selected ? 'rgba(0,0,0,.6)' : 'rgba(0,0,0,.03)',
    boxShadow: selected ? '0px 0px 1px 1px rgba(0,0,0,.04)' : 'none',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: selected ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.05)',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .2s',
  }

  return (
    <div style={radioOptionStyle} onClick={() => onSelect(index)}>
      <p style={{ marginLeft: '.4rem' }}>{label}</p>
    </div>
  )
}
