export interface ProgressBarProps {
  progress: number
}

export function ProgressBar({ progress }: ProgressBarProps) {
  const progressBarStyle = {
    width: `${progress * 100}%`,
    height: '5px',
    backgroundColor: 'rgba(0,0,150,.5)',
  }

  const progressBarShadowStyle = {
    width: `${(1 - progress) * 100}%`,
    height: '5px',
    backgroundColor: 'rgba(0,0,0,.1)',
  }

  const progressBarTextStyle = {
    fontSize: '12px',
    lineHeight: '14px',
    margin: '0 10px 0 0',
    fontWeight: '900',
  }

  return (
    <div className="d-flex align-items-center" style={{ width: '100%', padding: '1rem' }}>
      <p style={progressBarTextStyle}>{(progress * 100).toFixed(0)}%</p>
      <div style={progressBarStyle}></div>
      <div style={progressBarShadowStyle}></div>
    </div>
  )
}
