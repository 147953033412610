import useSWR from 'swr'
import { getAllSites_endpoint, getSitesByProjectId } from '../service/api-gateway'
//import { errorStore } from '../store/ErrorStore'

export const useAllSites = () => {
  const path = getAllSites_endpoint

  const { data, error } = useSWR(path, getSitesByProjectId, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
