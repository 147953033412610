import { LanguageISOCode, QuestionType } from './Types'

export const DEFAULT_LANG: LanguageISOCode = 'en'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
export const REACT_APP_ENV = process.env.REACT_APP_ENV

export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#007bff'
export const COLOR_NOT_SELECTED = '#bbbbbb'

export const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
export const MULTIPLE_RESPONSE = 'MULTIPLE_RESPONSE'
export const TEXT_FREE = 'TEXT_FREE'
export const INFORMATION = 'INFORMATION'

// export const QUESTION_TYPES: QuestionType[] = ['text', 'checkbox', 'radio', 'photo', 'number', 'slider', 'info']
// Only supported question types should appear in UI
export const QUESTION_TYPES: QuestionType[] = [
  'text',
  'checkbox',
  'radio',
  'number',
  'info',
  'bmi',
  'phonenumber',
  'name',
  'gender',
  'birthdate',
  'image',
]

export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage?: (...rest) => string
}

export const FRONT_PAGE: PageType = { title: 'Front', path: `/`, to: `/`, visible: true }
export const PROJECT_PAGE: PageType = { title: 'Projects', path: `projects`, to: `/projects`, visible: true }

export const SITES_PAGE: PageType = {
  title: 'Sites',
  path: `/sites`,
  to: `/sites`,
  visible: true,
  gotoPage: (id) => {
    return `/site/` + id
  },
}

export const PROJECT_PAGE_VIEW: PageType = {
  title: 'Projects',
  path: `/project/:projectId/*`,
  to: `/project/:projectId`,
  visible: true,
  gotoPage: (id) => {
    return `/project/` + id
  },
}

export const PROJECT_PAGE_VIEW_EDIT_SITE: PageType = {
  title: 'Project edit site',
  path: `/project/:projectId/site/:siteId`,
  to: `/project/:projectId/site/:siteId`,
  visible: true,
  gotoPage: (projectId, siteId) => {
    return `/project/` + projectId + `/site/` + siteId
  },
}

export const PROJECT_PAGE_EDIT_MAILTEMPLATE: PageType = {
  title: 'Project edit mail template',
  path: `/`,
  to: ``,
  visible: true,
  gotoPage: (projectId, templateName) => {
    return `/project/` + projectId + `/template/` + templateName
  },
}

export const PROJECT_PAGE_VIEW_INVESTIGATOR_LIST: PageType = {
  title: 'Project view investigator list',
  path: `/project/:projectId/site/:siteId/investigators`,
  to: `/project/:projectId/site/:siteId/investigators`,
  visible: true,
  gotoPage: (projectId, siteId) => {
    return `/project/` + projectId + `/site/` + siteId + `/investigators`
  },
}

export const PROJECT_PAGE_CREATE_INVESTIGATOR: PageType = {
  title: 'Project create investigator',
  path: `/project/:projectId/site/:siteId/investigators/create/:investigatorId`,
  to: `/project/:projectId/site/:siteId/investigators/create/:investigatorId`,
  visible: true,
  gotoPage: (projectId, siteId, investigatorId) => {
    return `/project/` + projectId + `/site/` + siteId + `/investigators/create/`
  },
}

export const PROJECT_PAGE_VIEW_INVESTIGATOR: PageType = {
  title: 'Project edit investigator',
  path: `/project/:projectId/site/:siteId/investigators/view/:investigatorId`,
  to: `/project/:projectId/site/:siteId/investigators/view/:investigatorId`,
  visible: true,
  gotoPage: (projectId, siteId, investigatorId) => {
    return `/project/` + projectId + `/site/` + siteId + `/investigators/view/` + investigatorId
  },
}

export const QUESTIONNAIRE_PAGE_EDIT: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_FLOW: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit/flow`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit/flow`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_STATS: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/stats`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/stats`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_TEST: PageType = {
  title: 'Questionnaire',
  path: `/questionnaire/:questionnaireId/test/:language`,
  to: `/questionnaire/:questionnaireId/test/:language`,
  visible: true,
}

export const PROJECT_PAGE_CREATE: PageType = {
  title: 'Projects',
  to: PROJECT_PAGE.to + `/create`,
  path: PROJECT_PAGE.path + `/create`,
  visible: false,
}

export const LOGIN = { title: 'Login', to: `/`, base: '/', visible: false }
export const LOGIN_NEW_PASSWORD = {
  title: 'New-password',
  path: `/new-password`,
  to: '/login/new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD_SUBMIT = {
  title: 'submit-new-password',
  to: `/login/submit-new-password`,
  path: '/submit-new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD = {
  title: 'forgot password',
  path: 'forgot-password',
  to: `/login/forgot-password`,
  visible: false,
}

export const UNSUBSCRIBE_PAGE = {
  title: 'Unsubscribe page',
  path: 'unsubscribe',
  to: '/unsubscribe',
  visible: true,
}

export const TESTING_PAGE = {
  title: 'Testing ',
  path: '/testing',
  to: '/testing',
  visible: false,
}

export const USER_MANAGEMENT_PAGE = {
  title: 'User Management ',
  path: '/user-management',
  to: '/user-management',
  visible: false,
}

export const TESTING_ANSWER_LOOKUP = {
  title: 'Testing ',
  path: '/testing/answerlookup',
  to: '/testing/answerlookup',
  visible: false,
}

export const LOGIN_MFA_CODE = { title: 'mfa', path: '/mfa', to: `/login/mfa`, visible: false }

export const PAGES = [PROJECT_PAGE, SITES_PAGE, TESTING_PAGE, USER_MANAGEMENT_PAGE]
