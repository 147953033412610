/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/05/2022.
 */
import React from 'react'

type Props = {
  label?: string
  children?: React.ReactChildren | React.ReactChild
  header?: React.ReactChildren | React.ReactChild
  className?: string
}

export const GroupHeader = ({ label = 'Label', children, header, className = '' }: Props) => {
  const clName = className ? className : 'col-12'
  return (
    <>
      {children ? (
        <>
          <div
            className="col-12 py-3 d-flex justify-content-between align-items-center"
            style={{
              borderBottom: '1px solid rgb(234, 237, 237)',
              background: 'rgba(250, 250, 250)',
              boxShadow: '0 0px 1px 0 rgba(0,28,36,.3)',
            }}
          >
            <p className="fw-bold mb-0">{label}</p>
            {header ? header : <div className=""></div>}
          </div>
          {children}
        </>
      ) : (
        <div
          className={`${clName} py-3 `}
          style={{
            borderBottom: '1px solid rgb(234, 237, 237)',
            background: 'rgba(250, 250, 250)',
            boxShadow: '0 0px 1px 0 rgba(0,28,36,.3)',
          }}
        >
          <p className="fw-bold mb-0 pb-1">{label}</p>
        </div>
      )}
    </>
  )
}
