export const siteAddConfig = [
  {
    id: 'internalName',
    type: 'TEXT',
    placeholder: 'Enter site title',
    label: 'Site title',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Site title is required',
  },
  {
    id: 'officialName',
    type: 'TEXT',
    placeholder: 'Enter official site name',
    label: 'Official site name',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Public site title required',
  },
  {
    id: 'country',
    type: 'DROPDOWN',
    placeholder: 'Select a country',
    label: 'Country',
    options: ['USA', 'Denmark', 'Europe'],
    prefix: '',
    postfix: '',
    className: 'col-12',
    validation: { required: true },
    value: '',
    errorMsg: 'A country must be selected',
  },
  {
    id: 'address',
    type: 'TEXT',
    placeholder: 'Enter site address',
    label: 'Site location displayed to the user',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: true },
    errorMsg: 'Site address required',
  },
  {
    id: 'zipcode',
    type: 'TEXT',
    placeholder: 'Enter site zipcode',
    label: 'Site zipcode ',
    prefix: '',
    postfix: '- currently not used',
    value: '',
    validation: { required: true },
    errorMsg: 'Site zipcode required',
  },
  {
    id: 'capacity',
    type: 'TEXT',
    placeholder: 'Enter site capacity',
    label: 'Site capacity  ',
    prefix: '',
    postfix: '- the maximum number of users waiting at a site ',
    value: '',
    validation: { validate: (v) => /^[-]?\d+$/.test(v) || 'It must be a number', required: true },
    errorMsg: 'Site capacity required',
  },
]

export const defaults = { active: false }
