import { getReq } from '../service/API'
import useSWR from 'swr'
import { getInvestigators_endpoint } from '../service/api-gateway'

export const useInvestigators = () => {
  const { data, error } = useSWR(getInvestigators_endpoint, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
