import { useRef } from 'react'

export function SubmitButton(props) {
  const submitButtonRef = useRef<any>(null)
  const submitButtonStyle = {
    marginTop: '2rem',
    minWidth: '80px',
    borderRadius: '2px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(0,0,0,.7',
    padding: '4px 13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '700',
    transition: 'all .1s',
  }

  const onFocus = () => {
    submitButtonRef.current.style.boxShadow = '0px 0px 1px 1px rgba(0,0,0,.4)'
  }

  const onBlur = () => {
    submitButtonRef.current.style.boxShadow = 'none'
  }

  const onClick = () => {
    submitButtonRef.current.style.backgroundColor = 'black'
    submitButtonRef.current.style.color = 'white'
    submitButtonRef.current.style.outline = 'none'

    setTimeout(() => {
      props.onClick()

      // Reset if object still exists, in case of wrong value
      if (submitButtonRef.current !== null) {
        submitButtonRef.current.style.backgroundColor = 'transparent'
        submitButtonRef.current.style.color = 'black'
      }
    }, 100)
  }

  return (
    <button
      style={submitButtonStyle}
      type="button"
      onMouseEnter={onFocus}
      onMouseLeave={onBlur}
      onClick={onClick}
      ref={submitButtonRef}
    >
      {props.children}
    </button>
  )
}
