import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import Space from 'antd/lib/space'
import { useLayoutEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { PROJECT_PAGE_CREATE_INVESTIGATOR } from '../../constants'
import { InvestigatorList } from '../../pages/sites/InvestigatorList'
import { InvestigatorEditChildDrawer } from '../../pages/sites/InvestigatorEditChildDrawer'

export const InvestigatorDrawerComponent = () => {
  const navigate = useNavigate()
  const { siteId, projectId } = useParams() as {
    projectId: string
    siteId: string
  }

  const [visible, setVisible] = useState(false)
  const [childrenDrawer, setChildrenDrawer] = useState(false)

  useLayoutEffect(() => {
    setVisible(true)
  }, [])

  const onClose = () => {
    setVisible(false)
  }
  const returnToProject = (e) => {
    if (!e) navigate('/project/' + projectId)
  }

  const returnToInvestigators = (e?: any) => {
    if (!e) navigate(`/project/` + projectId + `/site/` + siteId + `/investigators`)
  }

  const showChildrenDrawer = () => {
    setChildrenDrawer(true)
  }

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false)
  }

  return (
    <>
      <Drawer
        afterVisibleChange={returnToProject}
        title="Site Investigator information"
        destroyOnClose={true}
        width={'40%'}
        placement="right"
        onClose={onClose}
        visible={visible}
        extra={
          <Space>
            <Link to={PROJECT_PAGE_CREATE_INVESTIGATOR.gotoPage?.(projectId, siteId) || 'undefined'}>
              <Button type="primary" onClick={showChildrenDrawer}>
                Create new Investigator
              </Button>
            </Link>
          </Space>
        }
      >
        {/*
          ------------------------
          The list of investigators
          ------------------------
        */}
        <InvestigatorList showChildDrawer={showChildrenDrawer} siteId={siteId} projectId={projectId} />

        <Routes>
          <Route
            path="view/:investigatorId"
            element={
              <InvestigatorEditChildDrawer
                isView={true}
                closeDrawer={onChildrenDrawerClose}
                showDrawer={childrenDrawer}
                returnToInvestigators={returnToInvestigators}
              />
            }
          />

          <Route
            path="create/"
            element={
              <InvestigatorEditChildDrawer
                isView={false}
                closeDrawer={onChildrenDrawerClose}
                showDrawer={childrenDrawer}
                returnToInvestigators={returnToInvestigators}
              />
            }
          />
        </Routes>
      </Drawer>
    </>
  )
}
