//TODO: antd or antd/lib/<>
import { Alert, Divider, Switch } from 'antd'
import Button from 'antd/lib/button'
import { Suspense, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupHeader } from '../../components/display/GroupHeader'
import { getFormType } from '../../components/form/formFactory'
import PageTitle from '../../components/header/PageTitle'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { PROJECT_PAGE } from '../../constants'
import { useProject } from '../../hooks/useProject'
import { defaults, projectForm } from './ProjectConfiguration'
import { ProjectMailSender } from './ProjectMailSender'
import { ProjectMailTemplate } from './ProjectMailTemplate'
import ProjectQuestionnaire from './ProjectQuestionnaire'
import { createProjectSubmit, updateProjectSubmit } from './ProjectsAPI'
import { ProjectSettings } from './ProjectSettingsModal'
import { ProjectSites } from './ProjectSites'
import Spinner from '../../components/spinner/Spinner'
import { ProjectLinks } from './ProjectLinks'
import { ProjectFileUpload } from './ProjectFileUpload'
import { ProjectImageEvaluator } from './evaluator/ProjectImageEvaluator'

export const ProjectContainer = () => {
  const params: any = useParams()
  const { data } = useProject(params.projectId || '')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const projectData = data
  if (!projectData && params.projectId) {
    throw new Error(`Project does not exist ${params.projectId}`)
  }

  console.log(' ProjectContainer > data = ', data)

  const navigate = useNavigate()
  const isEdit = params.projectId ? true : false
  const defaultObj = isEdit
    ? {
        active: projectData.active,
        projectRef: projectData.projectRef,
        title: projectData.title,
        condition: projectData.condition,
        age: projectData.age,
        maxDistance: projectData.maxDistance,
        maxDistanceToTravel: projectData.maxDistanceToTravel,
        languages: projectData.languages,
        mailsender: projectData.mailsender,
        links: projectData.links || [],
        isSanosProject: projectData.isSanosProject,
        //sites: projectData.mailsender,
      }
    : defaults

  const formHook = useForm({
    defaultValues: defaultObj,
  })

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const { handleSubmit } = formHook

  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (data, e) => {
    if (e) {
      e.preventDefault()
    }

    setIsLoading(true)
    if (isEdit) {
      const submitObj = { ...data, retention: projectData.retention }
      Promise.allSettled([
        updateProjectSubmit(params.projectId, submitObj),
        new Promise((resolve) => setTimeout(resolve, 800)),
      ])
        .then(() => {
          setErrorState(undefined)
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          setErrorState(error)
        })
    } else {
      createProjectSubmit(data)
        .then((res) => {
          setErrorState(res)
          setIsLoading(false)
          navigate(PROJECT_PAGE.to, { state: true })
        })
        .catch((error) => {
          setIsLoading(false)
          setErrorState(error)
        })
    }
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const onOk = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {isEdit ? (
        <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
          <ProjectSettings isModalVisible={isModalVisible} closeModal={closeModal} onOk={onOk} />
        </Suspense>
      ) : (
        <></>
      )}

      <div className="container">
        <div className="row" style={{}}>
          <div className="col-12 my-4 justify-content-between d-flex">
            {isEdit ? (
              <>
                <PageTitle>Edit recruitment project</PageTitle>
                <Button onClick={showModal} type="primary" danger={projectData.active}>
                  {projectData.active ? 'Shutdown project' : 'Activate project'}
                </Button>
              </>
            ) : (
              <>
                <h4 className="fw-bold mb-0">Create Recruitment project</h4>
                {isEdit && <Switch className="mt-2" disabled />}
              </>
            )}
          </div>
        </div>
        <div className="row">
          <form className="col-12" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="row group-item">
              <GroupHeader label="Project details"></GroupHeader>
              {projectForm.map((item, index) => {
                const comp = getFormType(item, index, formHook)
                return (
                  <div key={index} className={item.className}>
                    {comp}
                  </div>
                )
              })}
              <Divider />
              <div className="col-12 d-flex mb-3" style={{ justifyContent: 'flex-end' }}>
                <Button
                  onClick={handleSubmit(onSubmit, onError)}
                  className="d-flex"
                  type="primary"
                  disabled={isLoading && isEdit}
                >
                  {!isLoading ? (
                    isEdit ? (
                      'Update Project details'
                    ) : (
                      'Create Project'
                    )
                  ) : (
                    <div className="d-flex justify-content-center " style={{ width: 144 }}>
                      <Spinner size={3} style={{ color: 'white' }}></Spinner>
                    </div>
                  )}
                </Button>
              </div>
            </div>

            {/*
             ****************
              INVESTIGATORS
            ******************
            */}

            {isEdit ? (
              <>
                <div className="row mt-4 group-item" style={{}}>
                  <Suspense fallback={<Spinner></Spinner>}>
                    <ProjectImageEvaluator formHook={formHook} isLoading={isLoading}></ProjectImageEvaluator>
                  </Suspense>
                </div>
              </>
            ) : null}

            {isEdit ? (
              <>
                <div className="row mt-4 group-item" style={{}}>
                  <ProjectMailSender
                    formHook={formHook}
                    handleSubmit={handleSubmit(onSubmit, onError)}
                    isLoading={isLoading}
                  />
                </div>
                <div className="row mt-4 group-item" style={{}}>
                  <ProjectMailTemplate project={projectData} />
                </div>
                <div className="row mt-4 group-item" style={{}}>
                  <ProjectQuestionnaire project={projectData}></ProjectQuestionnaire>
                </div>
              </>
            ) : null}

            {isEdit ? (
              <>
                <div className="row mt-4 group-item ">
                  <ProjectLinks
                    formHook={formHook}
                    handleSubmit={handleSubmit(onSubmit, onError)}
                    isLoading={isLoading}
                  ></ProjectLinks>
                </div>
                <div className="row mt-4 group-item">
                  <ProjectFileUpload />
                </div>
                <div className="row mt-4 group-item">
                  <ProjectSites
                    project={projectData}
                    maxDistance={projectData.maxDistance}
                    maxDistanceTravel={projectData.maxDistanceToTravel}
                  ></ProjectSites>
                </div>
              </>
            ) : null}
            {errorState ? (
              <Alert
                className="mt-3"
                showIcon
                message={errorState.type}
                description={errorState.message}
                type="error"
              />
            ) : null}
          </form>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 my-5"></div>
        </div>
      </div>
    </>
  )
}
