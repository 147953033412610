import { PhoneNumberQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'
import { Checkbox } from 'antd'
import { useEffect, useState } from 'react'
// import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'

export const PhoneNumberCreateForm = (props: TypedQuestionCreateFormProps<PhoneNumberQuestion>) => {
  // Still just conforms exactly to base question, but I'll leave this here
  // so we can just add things if we need
  // console.debug('Phone number create form with', props) // mainly to make the linter happy

  const formId = 'languages'

  const languages = props.languages
  const { register, setValue, getValues } = props.formHook
  const [state, setState] = useState<{ lang: string; active: boolean }[]>([])
  const [errorState, setErrorState] = useState<boolean>(false)

  const handleChange = (index: number, checked: boolean) => {
    const copy = state.concat([])
    const item = copy[index]
    copy[index] = { ...item, active: checked }
    setState(copy)
    setValue(formId, copy)
  }

  const validateSelectedLanguages = (languages) => {
    const result = languages.filter((element) => element.active === true)

    if (result.length === 0) {
      setErrorState(true)
      return false
    } else {
      setErrorState(false)
      return true
    }
  }

  useEffect(() => {
    register(formId, {
      validate: {
        validationSelectedLanguages: () => validateSelectedLanguages(getValues(formId)) === true,
      },
    })

    const savedLangs = getValues(formId)
    if (savedLangs && savedLangs.length) {
      const k = savedLangs.map((item, index) => {
        setValue(formId + '.' + index, false)
        return item
      })
      setValue(formId, k)
      setState(k)
    } else {
      const k = props.languages.map((item, index) => {
        setValue(formId + '.' + index, false)
        return { lang: item, active: false }
      })
      setValue(formId, k)
      setState(k)
    }
  }, [register, getValues, props.languages, setValue])

  return (
    <div className="container-fluid px-0">
      <div className="row" style={{}}>
        <div className="col-12">
          <hr />
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 mb-2">
          <p>Please select which formats is accepted:</p>
        </div>
      </div>
      <div className="row" style={{}}>
        {languages.map((item, index) => {
          const isSelected = state[index]?.active || false

          return (
            <div key={index} className="col-12 d-flex">
              <p className="fw-bold pe-2">{item}</p>
              <Checkbox
                checked={isSelected}
                onChange={(value) => {
                  handleChange(index, value.target.checked)
                }}
              />
            </div>
          )
        })}
      </div>
      {errorState ? <div className="p-small text-danger">At least one language must be selected</div> : null}
    </div>
  )
}

export default PhoneNumberCreateForm
