/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/08/2023.
 */
import React, { useState } from 'react'
import Button from 'antd/lib/button'
import { useParams } from 'react-router'
import { useEvaluator } from '../../../hooks/useEvaluator'
import {
  evaluatorsByProjectId,
  getInvestigators_endpoint,
  removeEvaluatorFromProject,
  sendEvaluatorInvite,
} from '../../../service/api-gateway'
import { post } from '../../../service/API'
import { openNotification } from '../../../utils/openNotification'
import { mutate } from 'swr'
import hrt from 'human-readable-time'

type Props = { onClose?: () => void }

export const EditEvaluator = ({ onClose }: Props) => {
  const { projectId, evaluatorId } = useParams() as { projectId: string; evaluatorId: string }

  const [loading, setLoading] = useState(false)
  const { data } = useEvaluator(evaluatorId, projectId)

  //const [errorState, setErrorState] = useState<any>()

  const sendInviteClick = () => {
    sendEvaluatorInvite(evaluatorId)
      .then((result) => {
        mutate(getInvestigators_endpoint)
        openNotification('The email has been sent to the investigator.', '', 'happy')
        closeDrawer()
      })
      .catch((error) => {
        //setErrorState(error)
        alert(error)
      })
  }

  const removeFromProject = () => {
    const p = removeEvaluatorFromProject(projectId, evaluatorId)
    setLoading(true)
    post(p, {}, 'PUT')
      .then((result) => {
        setLoading(false)

        closeDrawer()
        mutate(evaluatorsByProjectId(projectId))
      })
      .catch((error) => {
        setLoading(false)
        return Promise.reject(error)
      })
  }

  const closeDrawer = () => {
    if (onClose) {
      onClose()
    }
  }

  const inviteSent = data?.inviteSent
    ? 'Invite sent: ' + hrt(new Date(data.inviteSent), 'At %hh%:%mm%:%ss% on %D% %month% %YYYY%')
    : 'Invite not sent'

  return (
    <div className="container-fluid">
      {/*<form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="col-12">
          {evaluatorConfigObj.map((item, index) => {
            const comp = getFormType(item, index, formHook)
            return comp
          })}
        </div>
      </form>*/}
      <div className="row" style={{}}>
        <div className="col-12">
          <h5 className="fw-bold mb-3">Update access for this evaluator</h5>
          <p className="">{data.name}</p>
          <p className="">{data.email}</p>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center  mt-3">
          <Button type="primary" loading={loading} onClick={removeFromProject}>
            Remove from project
          </Button>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 mt-3">
          <hr />
        </div>

        <div className="col-12 mt-2">
          <h5 className="fw-bold">Invite the evaluator</h5>
          <p>For the evaluator to use the system, they must have been invited</p>
        </div>

        <div className="col-12 d-flex justify-content-between align-items-center  mt-3">
          <div className="">
            <p className="mb-0 p-small alpha-60 ">{inviteSent}</p>
          </div>

          <Button type="primary" onClick={sendInviteClick}>
            Send invite
          </Button>
        </div>
      </div>
    </div>
  )
}
