import React, { useLayoutEffect, useState } from 'react'
import Drawer from 'antd/lib/drawer'
import Button from 'antd/lib/button'
import { Input } from 'antd'
import Spinner from '../../components/spinner/Spinner'

type Props = {
  closeDrawer: () => void
  links: any[]
  addLink: (obj: any) => void
  removeLink: (url: string) => void
  isLoading: boolean
}

const initLink = { title: '', url: '' }
export const ProjectAddLinkDrawer = ({ closeDrawer, links, addLink, removeLink, isLoading }: Props) => {
  const [visible, setVisible] = useState(false)

  const [newLink, setNewLink] = useState(initLink)

  const [showAdd, setShowAdd] = useState(false)

  useLayoutEffect(() => {
    setVisible(true)
  }, [setVisible])

  const onClose = () => {
    setVisible(false)
  }

  const layoutChange = (e) => {
    if (!e) {
      closeDrawer()
    }
  }

  const showEdit = () => {
    setShowAdd(true)
  }
  const hideEdit = () => {
    setShowAdd(false)
  }

  const inputChange = (v) => {
    const name = v.target.name
    const value = v.target.value

    setNewLink({ ...newLink, [name]: value })
  }

  const saveNewLink = () => {
    //console.log(' ProjectAddLinkDrawer > newLink = ', newLink)

    console.log(' ProjectAddLinkDrawer > newLink.title.length = ', newLink.title.length)
    if (newLink.title.length < 3) {
      return
    }

    if (newLink.url.length < 5) {
      return
    }

    setShowAdd(false)
    setNewLink(initLink)
    addLink({ ...newLink })
  }

  const removeSelectedLink = (url) => {
    removeLink(url)
  }

  return (
    <Drawer
      title="Add Site"
      destroyOnClose={true}
      width={'40%'}
      placement="right"
      onClose={onClose}
      visible={visible}
      afterVisibleChange={layoutChange}
    >
      <div className="container-fluid">
        <div className="row" style={{}}>
          {links.map((item, index) => {
            return <LinkItem key={index} item={item} removeLink={removeSelectedLink}></LinkItem>
          })}

          {isLoading ? (
            <div className="col-12 d-flex justify-content-center mb-4">
              <Spinner></Spinner>
            </div>
          ) : null}
        </div>

        {showAdd ? (
          <div className="row " style={{}}>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12  ">
              <div className="border px-3 pt-2 pb-3" style={{ background: '#efefef' }}>
                <p className="mt-2 mb-2">Add a new link</p>
                <Input
                  className="mb-2"
                  name="title"
                  value={newLink.title}
                  onChange={inputChange}
                  placeholder="The dispalay title"
                />
                <Input name="url" value={newLink.url} onChange={inputChange} placeholder="The URL" />
                <div className="d-flex justify-content-end">
                  <Button className="mt-2 me-2" onClick={hideEdit} type="default">
                    cancel
                  </Button>
                  <Button className="mt-2" onClick={saveNewLink} type="primary">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row" style={{}}>
          {!isLoading && !showAdd ? (
            <>
              <div className="col-12">
                <hr />
              </div>

              <div className="col-12 d-flex justify-content-end">
                <Button onClick={showEdit} type="primary">
                  Add new link
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Drawer>
  )
}

const LinkItem = ({ item, removeLink }) => {
  const remove = () => {
    removeLink(item.url)
  }

  return (
    <div className="col-12 mb-2">
      <div className="border px-3 py-4 position-relative">
        <button
          onClick={remove}
          type="button"
          className="position-absolute btn-close"
          style={{ width: 5, top: 4, right: 10, zIndex: 10 }}
          aria-label="Close"
        ></button>
        <p className="fw-bold">{item.title}</p>
        <p className="p-small">{item.url}</p>
      </div>
    </div>
  )
}
