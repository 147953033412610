import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'
import { TextQuestion } from '../../Types'
import { TypedQuestionCreateFormProps } from './TypeSpecificCreateForm'

export const TextCreateForm = (props: TypedQuestionCreateFormProps<TextQuestion>) => {
  const items = [
    {
      id: 'placeholder',
      type: 'TEXT',
      placeholder: 'Text shown on the texfield intially, like this very paragraph...',
      label: 'Placeholder (optional)',
      validation: { required: false },
      ...props.defaultFormFieldConfig,
      value: props.question?.placeholder || '',
      tooltip: 'Text to be shown on the textfield before anything is entered...',
    },
    {
      id: 'minLength',
      type: 'NUMBER',
      placeholder: 'Minimum length',
      label: 'Minimum Answer Length (optional)',
      validation: { required: false },
      ...props.defaultFormFieldConfig,
      value: props.question?.minLength || '',
      tooltip: 'Minimum allowed answer length (amount of characters) - if "required" is not enabled this does nothing',
    },
    {
      id: 'maxLength',
      type: 'NUMBER',
      placeholder: 'Maximum length',
      label: 'Maximum Answer Length (optional)',
      validation: { required: false },
      ...props.defaultFormFieldConfig,
      value: props.question?.maxLength || '',
      tooltip: 'Maximum allowed answer length (amount of characters)',
    },
  ]

  return (
    <>
      {items.map((item, index) => (
        <div key={`radio${index}`} className={item.className}>
          {getTranslationAwareFormType(item, index, props.formHook, props.languages)}
        </div>
      ))}
    </>
  )
}

export default TextCreateForm
