import { useEffect, useState } from 'react'
import { UseFormReturn, FieldValues } from 'react-hook-form'
import { Checkbox, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getTranslationAwareFormType } from '../translation-aware-form/translationAwareFormFactory'
import { LanguageISOCode, Question, QuestionType } from '../../Types'
import { QUESTION_TYPES } from '../../constants'
import TypeSpecificCreateForm from './TypeSpecificCreateForm'

const { Option } = Select

/** Default settings for form items in question creation components */
export const defaultFormFieldConfig = {
  prefix: '',
  postfix: '',
  defaultValue: '',
  value: '',
  className: 'col-12 w-100',
}

export type QuestionCreateFormProps = {
  formHook: UseFormReturn<FieldValues, any>
  question?: Question
  languages: LanguageISOCode[]
}

/**
 * Form for creating and/or editing questions based on
 * whether an existing question is given through props
 */
export const QuestionCreateForm = (props: QuestionCreateFormProps) => {
  console.log('QuestionCreateForm > props = ', props)

  const [selectedType, setSelectedType] = useState<QuestionType | null>(props.question?.type || null)

  const baseQuestionCreateFormItems = [
    {
      id: 'title',
      type: 'TEXT',
      placeholder: 'Question Header Text',
      label: 'Question Header*',
      validation: { required: true },
      errorMsg: 'Enter a question title!',
      ...defaultFormFieldConfig,
      value: props.question?.title,
      tooltip: 'The leading header text for the question - should typically be used for the main question text.',
    },
    {
      id: 'body',
      type: 'TEXTAREA',
      placeholder: 'Optionally add body text (optional)',
      label: 'Body (optional)',
      validation: { required: false },
      ...defaultFormFieldConfig,
      value: props.question?.body,
      tooltip:
        'Explanatory body of text shown below the header, use this if you need to explain or inform in more detail how the user should proceed.',
    },
    {
      id: 'continuation',
      type: 'TEXT',
      placeholder: 'Continuation Button Text',
      label: 'Continuation Text*',
      validation: { required: true },
      errorMsg: 'Enter a continuation text  title!',
      ...defaultFormFieldConfig,
      value: props.question?.continuation,
      tooltip:
        'The text shown on the button that allows continuing or submitting the answer - for the end screen this does nothing.',
    },
  ]

  const onTypeChanged = (type: QuestionType) => {
    setSelectedType(type)
  }

  /*const typeSpecificFormFragment = selectedType ? (
    <TypeSpecificCreateForm {...props} defaultFormFieldConfig={defaultFormFieldConfig} type={selectedType} />
  ) : (
    <div className="mt-3">
      <em>No form type selected yet</em>
    </div>
  )*/

  return (
    <div className="container">
      {selectedType === 'radio' || selectedType === 'gender' ? (
        <></>
      ) : (
        <RequiredCheckbox formHook={props.formHook} value={props.question?.required} label="Required" />
      )}
      <div className="mt-3 d-flex flex-row justify-content-start align-items-center">
        <Tooltip
          placement="topLeft"
          title="The type of question - different question types are handled differently by us and presented differently to our users."
        >
          <InfoCircleOutlined />
        </Tooltip>
        <TypeSelectorDropDown
          onTypeSelected={onTypeChanged}
          label="Question Type*"
          value={selectedType}
          formHook={props.formHook}
        />
      </div>

      {baseQuestionCreateFormItems.map((item, index) => (
        <div key={index} className={item.className}>
          {getTranslationAwareFormType(item, index, props.formHook, props.languages)}
        </div>
      ))}

      {selectedType ? (
        <TypeSpecificCreateForm {...props} defaultFormFieldConfig={defaultFormFieldConfig} type={selectedType} />
      ) : (
        <div className="mt-3">
          <em>No form type selected yet</em>
        </div>
      )}
    </div>
  )
}

const RequiredCheckbox = (props: {
  className?: string
  label: string
  value?: boolean
  formHook: UseFormReturn<FieldValues, any>
}) => {
  const { register, setValue } = props.formHook
  const [checked, setChecked] = useState<boolean>(props.value === undefined ? true : props.value!)

  const requiredSelectorName = 'required'
  useEffect(() => {
    register(requiredSelectorName)
  }, [register, requiredSelectorName])

  useEffect(() => {
    setValue(requiredSelectorName, checked)
  }, [setValue, requiredSelectorName, checked])

  const handleChange = (e: any) => {
    console.log(e.target.checked)
    setChecked(e.target.checked)
  }

  return (
    <div className="d-flex flex-row justify-content-start align-items-center">
      <Tooltip
        placement="topLeft"
        title="Should be checked if the user should only be allowed to proceed after filling out this question!"
      >
        <InfoCircleOutlined />
      </Tooltip>
      <p className="mx-3">
        <strong>{props.label}</strong>
      </p>
      <Checkbox checked={checked} onChange={handleChange} />
    </div>
  )
}

const TypeSelectorDropDown = (props: {
  onTypeSelected: (type: QuestionType) => void
  className?: string
  label: string
  value?: QuestionType | null
  formHook: UseFormReturn<FieldValues, any>
}) => {
  const {
    setValue,
    register,
    formState: { errors },
  } = props.formHook

  const typeSelectorName = 'type'
  useEffect(() => {
    register(typeSelectorName)
  }, [register, typeSelectorName])

  const handleTypeChange = (type: QuestionType) => {
    setValue(typeSelectorName, type)
    props.onTypeSelected(type)
  }

  return (
    <>
      <p className="mb-0 mx-3">
        <strong>{props.label}</strong>
      </p>
      <Select className={props.className} onChange={handleTypeChange} style={{ minWidth: '17em' }} value={props.value}>
        {QUESTION_TYPES.map((item: QuestionType, index) => (
          <Option key={index} value={item}>
            <p>{item}</p>
          </Option>
        ))}
      </Select>

      {errors[typeSelectorName] ? <div className="p-small text-danger">Please select a question type!</div> : null}
    </>
  )
}

//export default QuestionCreateForm
