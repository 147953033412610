/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/02/2023.
 */
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

type Props = { setFile: (fileBlob: File) => void }

export const UploadBtn = ({ setFile }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      setFile(file)
    },
    [setFile]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button type="primary">
        {isDragActive ? (
          <p className="p-small mb-0">Drop the files here ...'</p>
        ) : (
          <p className="p-small d-flex align-items-center">
            Upload new file <PlusOutlined className="ms-1" />
          </p>
        )}
      </Button>
    </div>
  )
}
