import { getReq } from '../service/API'
import useSWR, { useSWRConfig } from 'swr'
import { createQuestion, deleteQuestion, getQuestionnaire_endpoint, putQuestion } from '../service/api-gateway'
import { useParams } from 'react-router'
import { Question } from '../Types'

export const useQuestionnaire = (questionnaireId: string | null) => {
  const endpoint = questionnaireId ? getQuestionnaire_endpoint(questionnaireId) : null
  const { data, error } = useSWR(endpoint, getReq, { suspense: true })

  return {
    questionnaire: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    endpoint: endpoint,
  }
}

/** Simple hook that abstracts away AJAX and simplifies creating/editing questionnaires considerably */
export const useQuestionnaireAPI = () => {
  const { questionnaireId } = useParams() as { questionnaireId: string }
  const { questionnaire, isError, endpoint } = useQuestionnaire(questionnaireId)
  const { mutate } = useSWRConfig()

  const refreshChildren = () => mutate(endpoint)

  return {
    questionnaire: questionnaire,
    isError: isError,
    addQuestion: (question: Question) => createQuestion({ ...question, questionnaireId }).then(refreshChildren),
    updateQuestion: (question: Question) => putQuestion(question._id, question).then(refreshChildren),
    deleteQuestion: (question: Question) => deleteQuestion(question._id).then(refreshChildren),
  }
}
