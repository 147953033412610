import useSWR from 'swr'
import { getSitesByProjectId, getSitesByProjectId_endpoint } from '../service/api-gateway'
//import { errorStore } from '../store/ErrorStore'

export const useSites = (projectId: string) => {
  const path = getSitesByProjectId_endpoint(projectId)

  const { data, error } = useSWR(path, getSitesByProjectId, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
  }
}
