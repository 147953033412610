/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18/08/2023.
 */

import Drawer from 'antd/lib/drawer'
import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  children: React.ReactElement
  headerContent?: React.ReactNode
}

export const ProjectEvaluatorDrawer = ({ children, headerContent }: Props) => {
  const [visible, setVisible] = useState(false)

  const navigate = useNavigate()

  useLayoutEffect(() => {
    setVisible(true)
  }, [setVisible])

  const onClose = () => {
    setVisible(false)
  }

  const layoutChange = (e) => {
    console.log('ProjectEvaluatorDrawer > e = ', e)
    if (!e) {
      navigate('../')
      // closeDrawer()
    }
  }

  return (
    <Drawer
      title="Evaluator "
      destroyOnClose={true}
      width={'40%'}
      placement="right"
      onClose={onClose}
      visible={visible}
      afterVisibleChange={layoutChange}
      extra={headerContent || null}
    >
      {React.cloneElement(children, { onClose: onClose })}
    </Drawer>
  )
}
