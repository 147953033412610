import { mutate } from 'swr'
import {
  createSite,
  createSite_endpoint,
  updateSite,
  getSites_endpoint,
  getSite_endpoint,
  getProjectSettings_endpoint,
  getSitesByProjectId_endpoint,
} from '../../service/api-gateway'

export const createSiteSubmit = (data: any) => {
  console.log(' SiteCreateDrawer > data = ', data)
  return createSite(data)
    .then((result) => {
      console.log('result = ', result)
      mutate(getSitesByProjectId_endpoint(data.projectId || ''))
      mutate(createSite_endpoint)
      return Promise.resolve(result)
    })
    .catch((error) => {
      console.log('error = ', error)
      return Promise.reject(error)
    })
}

export const updateSiteSubmit = (siteId: string, data: any) => {
  console.log(' UpdateSite > data = ', data)
  return updateSite(siteId, data)
    .then((result) => {
      mutate(getSites_endpoint)
      mutate(getProjectSettings_endpoint(data.projectId || ''))
      mutate(getSitesByProjectId_endpoint(data.projectId || ''))
      mutate(getSite_endpoint(siteId || ''))
      console.log('result = ', result)
      return Promise.resolve(result)
    })
    .catch((error) => {
      console.log('error = ', error)
      return Promise.reject(error)
    })
}
