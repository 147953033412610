import { useFieldArray } from 'react-hook-form'
import { LanguageISOCode } from '../../Types'
import { FormLabel } from './FormLabel'

export const TranslationAwareTextArea = (props: { data: any; formHook: any; countryCodes: LanguageISOCode[] }) => {
  const {
    control,
    register,
    formState: { errors },
  } = props.formHook

  useFieldArray({ control, name: props.data.id }) // makes translations work

  const errorState = errors[props.data.id]

  return (
    <div className="w-100 py-1 mt-2 gap-2 d-flex flex-column">
      <FormLabel label={props.data.label} tooltip={props.data.tooltip} />
      {props.countryCodes.map((lang: LanguageISOCode, i: number) => (
        <div key={i} className="d-flex gap-2 align-items-start">
          <p style={{ minWidth: '2em' }}>
            <strong>{lang}</strong>
          </p>
          <textarea
            className="ant-input"
            autoComplete="off"
            placeholder={props.data.placeholder}
            key={lang}
            {...register(`${props.data.id}.${props.countryCodes[i]}`, props.data.validation)}
          />
        </div>
      ))}

      {errorState ? <div className="p-small text-danger">{props.data.errorMsg}</div> : null}
    </div>
  )
}
