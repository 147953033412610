import { ReactChildren } from 'react'

// Allows us to change the layout of all titles by parameterizing this as standalone component

/** A text container for the title of a page */
export const PageTitle = (props: { children: ReactChildren } & any) => (
  <h4 className={`fw-bold ${props.className || ''}`}>{props.children}</h4>
)

export default PageTitle
