import React from 'react'
import { UserLookup } from './UserLookup'
import { Alert, Tabs, Typography } from 'antd'
import { ProjectMassEmail } from '../project/ProjectMassEmail'

const { Text } = Typography

const { TabPane } = Tabs

export const UserManagementPage = () => {
  return (
    <>
      <div className="container">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Delete PII from Cognito & Database" key="1">
            <DeletePIICognito></DeletePIICognito>
          </TabPane>
          <TabPane tab="Mass email selected subjects" key="2">
            <MassEmail></MassEmail>
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}

const DeletePIICognito = () => {
  return (
    <div className="row" style={{}}>
      <div className="col-12 mt-4">
        <h4 className="fw-bold">User management section</h4>

        <hr />

        <p className="mt-2">
          In the user management section users are able to view extensive information about a given signup and also
          <b> delete all PII from Cognito</b>.
        </p>
        <p className="mt-2">It is important to note that no PII is saved anywhere but in Cognito.</p>

        <hr />

        <p>Additional information for a given subject can be seen by clicking on the 🔍 button.</p>
        <p className="mt-2">
          This will show an additional container with information beyond the initial one already in display.
        </p>
        <p className="mt-2">
          By clicking on the <Text code>Event history button</Text>, a drawer with all state update events will be
          shown. These events are triggered by either site staff or a system administrator.
        </p>

        <hr />

        <p>
          When deleting <b>subject PII</b>, user will be prompted with a popup. This popup is used as a guard in case of
          accidental deletions.
        </p>
        <p className="mt-2">
          After the deletion process is finished, all <b>subject PII</b> will be removed from Cognito and a timestamp
          will be added to the <Text code>removedAt</Text> field.
        </p>

        <hr />

        <Alert
          className="rounded"
          message="Deletion"
          description="Please remember that deleting a subject PII is a non reversible process."
          type="warning"
          showIcon
        />

        <hr />
      </div>
      <UserLookup />
    </div>
  )
}

const MassEmail = () => {
  return (
    <div className="col-12 mt-4">
      <h4 className="fw-bold">Mass emailing</h4>
      <hr />
      <p className="mt-2">
        Create a folder on Google Drive under Records for the specific study (if applicable) {'>'} Participant Facing
        Material {'>'} Communication and name it with the template name and date. In the folder, there has to be a
        document containing the following information:
      </p>

      <p className="mt-2">
        <ul>
          <li>Must meet criteria to be on the audience list</li>
          <li>Name of the email template Email body (text of the email) </li>
          <li>Subject line</li>
          <li>Sender email address (has to be avalid studiesandme.com email address)</li>
        </ul>
      </p>

      <hr />

      <p className="mt-3">
        <b>Step 1 - Create template:</b> Create the template and write the email in <b>Mailchimp</b>. Remember to send
        the template to <b>Mandrill</b>
      </p>

      <p className="mt-3">
        <b>Step 2 - Select the audience:</b> Identify audience and acquire the subject IDs in a CSV format. Please note
        that the subject IDs should only be separated by commas and not space
      </p>

      <p className="mt-3">
        <b>Step 3 - Set up admin:</b> Enter the template name; subject line; sender email. Paste the subject IDs from
        the CSV file.
      </p>

      <p className="mt-3">
        <b>Step 4 - Test:</b> Clicking the “Sanity check & send test emails” button will trigger a test email to the
        recruitment team if there is no error in the set-up. A confirmation message will appear: “All parameters are
        correct”
      </p>

      <p className="mt-3">
        <b>Step 5 - Send:</b> Once the test email was sent and confirmed, click Dispatch emails to send the email to the
        selected audience
      </p>

      <hr />

      <p className="mt-3">
        <b>Error messages:</b>
        <p className="mt-2">
          <ul>
            <li>Template name missing: template needs to be sent to Mandrill</li>
            <li>Provided ID is not a valid ID: no user exists with the subject ID</li>
            <li>These subs have no emails found in Cognito: Delete the Subject ID from the list</li>
          </ul>
        </p>
      </p>

      <hr />
      <div className="mb-1">
        <ProjectMassEmail />
      </div>
    </div>
  )
}
