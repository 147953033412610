/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/08/2022.
 */
import { Button } from 'antd'
import React, { useState } from 'react'
import { post } from '../../service/API'
import { BASE_URL } from '../../constants'

const generateEmail = (prefix: string = 'test') => {
  const randomId = new Date().getTime().toString() + Math.random() * Math.round(Math.random() * 10000)
  // Dennis Owns the 'integration-test@studiesandme.com' email address
  const rndEmail = prefix + '+' + randomId + '_@studiesandme.com'
  return rndEmail
}

export const SignupUserTest = () => {
  const [state, setState] = useState<any>({
    name: 'John doe ' + new Date().getTime(),
    email: 'will be generated on click - if not changed',
    zip: '33401',
    projectRef: 'strides',
    locationId: 'is the same as zip',
    acceptConsent: true,
    acceptTerms: true,
    language: 'en',
  })

  const [error, setError] = useState<string>('')
  const [result, setResult] = useState<string>('')
  const [url, setUrl] = useState<string>('')

  const handleClick = () => {
    const data = {
      name: state.name,
      email: state.email.indexOf('@') > -1 ? state.email : generateEmail(),
      zipcode: state.zip,
      locationId: state.zip,
      position: {
        ip: '94.127.55.27',
        country_code: 'DK',
        latitude: 55.7123,
        longitude: 12.0564,
      },
      project: state.projectRef,
      acceptTerms: true,
      acceptConsent: true,
      language: state.language,
      grantType: 'password',
    }

    const p = BASE_URL + '/users/signup'

    console.log(' SignupUserTest > data = ', data)
    console.log(' SignupUserTest > p = ', p)

    post(p, data)
      .then((result) => {
        const authChallenge = result.data.authChallenge
        const email = encodeURIComponent(result.data.email)
        const projectId = result.data.projectId

        // language is not returned so we need to set it manually
        const lang = state.language
        console.log(' SignupUserTest > result = ', result)
        const urlRaw = `/${lang}/?authChallenge=${authChallenge}&email=${email}&projectId=${projectId}`

        const url = urlRaw
        setUrl(url)
        setResult(JSON.stringify(result.data))
        return result
      })
      .catch((error) => {
        console.log('SignupUserTest > error = ', error)
        setError(JSON.stringify(error))
        return Promise.reject(error)
      })
  }

  const onChange = (e) => {
    //setState(state)
    setState((prevState) => {
      const key = e.target.name

      prevState[key] = e.target.value
      return { ...prevState }

      // return (state[e.target.name] = e.target.value)
    })
  }

  return (
    <div className="container mt-4">
      <div className="row" style={{}}>
        <div className="col-12">
          <h6>Create a user</h6>
          <p>{BASE_URL}</p>
        </div>

        <div className="col-6">
          <div className="w-100 my-2">
            <p className="p-small">Name</p>
            <input type="text" name="name" value={state.name} className="ant-input" onChange={onChange} />
          </div>

          <div className="w-100 my-2">
            <p className="p-small">Email</p>
            <input type="text" name="email" value={state.email} className="ant-input" onChange={onChange} />
          </div>

          <div className="w-100 my-2">
            <p className="p-small">language</p>
            <input type="text" name="language" value={state.language} className="ant-input" onChange={onChange} />
          </div>

          <div className="w-100 my-2">
            <p className="p-small">zip</p>
            <input type="text" name="zip" value={state.zip} className="ant-input" onChange={onChange} />
          </div>

          <div className="w-100 my-2">
            <p className="p-small">locationId</p>
            <input type="text" name="locationId" value={state.locationId} className="ant-input" onChange={onChange} />
          </div>

          <div className="w-100 my-2">
            <p className="p-small">project ref</p>
            <input type="text" name="projectRef" value={state.projectRef} className="ant-input" onChange={onChange} />
          </div>

          <div className="mt-3">
            <Button type="primary" size="large" onClick={handleClick}>
              signup user
            </Button>
          </div>
        </div>
      </div>

      {result ? (
        <div className="col-12 mt-5">
          <h3 className="">Success</h3>
          <pre>{JSON.stringify(state, null, 4)}</pre>
          <pre>{result}</pre>

          <div className="">
            <p>{url}</p>
          </div>
        </div>
      ) : null}

      {error ? (
        <div className="col-12">
          <h3 className="text-danger">Error</h3>
          <pre>{error}</pre>
        </div>
      ) : null}
    </div>
  )
}

//es/?authChallenge=fce59333-9d81-45d6-9c5e-81612d930269&email=asdasd3434%40asds.dk                                 &projectId=62a87b336eefefc6dc519abd
//en/?authChallenge=677d6f26-325a-4a40-90c0-814157f06914&email=test+1665758290924987.3242083280741_@studiesandme.com&projectId=62a87b336eefefc6dc519abd
