import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import { SmileOutlined } from '@ant-design/icons'
import notification from 'antd/lib/notification'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { getFormType } from '../../components/form/formFactory'
import { useInvestigator } from '../../hooks/useInvestigator'
import { investigatorForm } from './InvestigatorFormConfiguration'
import {
  createInvestigator,
  createInvestigator_endpoint,
  deleteInvestigator,
  deleteInvestigator_endpoint,
  getInvestigators_endpoint,
  sendInvestigatorEmail,
} from '../../service/api-gateway'
import { mutate } from 'swr'
import Drawer from 'antd/lib/drawer'
import hrt from 'human-readable-time'
import { isValidEmail } from '../../utils/isValidEmail'
const formSiteId: any = 'siteId'

type Props = { isView: boolean; closeDrawer: any; showDrawer: boolean; returnToInvestigators: () => void }

export const InvestigatorEditChildDrawer = ({ closeDrawer, showDrawer, returnToInvestigators, isView }: Props) => {
  const { investigatorId, siteId } = useParams() as { investigatorId: string; siteId: string }
  const { data } = useInvestigator(investigatorId || '')

  const defaultObj = isView
    ? {
        email: data.email,
        name: data.name,
      }
    : {}

  const formHook = useForm({
    defaultValues: defaultObj,
  })

  const { handleSubmit, register, setValue } = formHook

  const [errorState, setErrorState] = useState<any>()

  useEffect(() => {
    register(formSiteId)
    setValue(formSiteId, siteId)
  }, [register, setValue, siteId])

  const onSubmit = (data, e) => {
    e.preventDefault()

    if (!isValidEmail(data.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
    } else {
      return createInvestigator(data)
        .then((result) => {
          closeDrawer()
          setErrorState(undefined)
          openNotification('The investigator is created', '', 'happy')
          mutate(createInvestigator_endpoint)
        })
        .catch((error) => {
          console.log('error = ', error)
          setErrorState(error)
        })
    }
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const sendInviteClick = () => {
    sendInvestigatorEmail(investigatorId)
      .then((result) => {
        mutate(getInvestigators_endpoint)
        openNotification('The email has been sent to the investigator.', '', 'happy')
        closeDrawer()
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  const openNotification = (
    msg: string = 'Email sent',
    description: string = 'The email has been sent to the investigator.',
    smiley: 'happy' | 'sad' | '' = ''
  ) => {
    notification.open({
      duration: 2.5,
      message: msg,
      description: description,
      icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
    })
  }

  const deleteInvestigatorClick = () => {
    deleteInvestigator(investigatorId, { siteId: siteId })
      .then((result) => {
        console.log(' InvestigatorEditChildDrawer > result = ', result)
        mutate(deleteInvestigator_endpoint(investigatorId))
        mutate(getInvestigators_endpoint)

        const msg = result.data.email + ' was deleted from this site'
        const description =
          'The investigtor can be assigned to multiple sites, but have only been removed from this one'
        openNotification(msg, description)
        returnToInvestigators()
        return result
      })
      .catch((error) => {
        console.log('error = ', error)
        alert(error)
      })
  }

  const inviteSent = data?.inviteSent
    ? 'Invite sent: ' + hrt(new Date(data.inviteSent), 'At %hh%:%mm%:%ss% on %D% %month% %YYYY%')
    : 'Invite not sent'

  console.log(' InvestigatorEditChildDrawer > data = ', data)

  return (
    <Drawer
      afterVisibleChange={returnToInvestigators}
      title={
        <div className="w-100 d-flex justify-content-between align-items-center">
          <p className="">Investigator</p>
          {isView ? (
            <Button onClick={deleteInvestigatorClick} type="primary" danger>
              Delete
            </Button>
          ) : null}
        </div>
      }
      destroyOnClose={true}
      width={'30%'}
      closable={true}
      onClose={closeDrawer}
      visible={showDrawer}
    >
      <>
        {!isView ? (
          <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="col-12">
              {investigatorForm.map((item, index) => {
                const comp = getFormType(item, index, formHook)
                return comp
              })}
            </div>
            <div className="col-12 mt-3">
              {errorState ? (
                <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
              ) : (
                <></>
              )}
            </div>

            <div className="col-12">
              <p className="opacity-75">
                Please note when the investigator has been created, you also have to trigger an invite
              </p>
            </div>

            <div className="col-12 d-flex justify-content-end mt-3">
              <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
                Create investigator
              </Button>
            </div>
          </form>
        ) : (
          <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="col-12">
              {investigatorForm.map((item, index) => {
                const comp = getFormType(item, index, formHook)
                return comp
              })}
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center  mt-3">
              <div className="">
                <p className="mb-0 p-small alpha-60 ">{inviteSent}</p>
              </div>

              <Button type="primary" onClick={sendInviteClick}>
                Send invite
              </Button>
            </div>
          </form>
        )}
      </>
    </Drawer>
  )
}
