/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/07/2022.
 */
import { Button } from 'antd'
import React, { Suspense } from 'react'
import { GroupHeader } from '../../../components/display/GroupHeader'
import Spinner from '../../../components/spinner/Spinner'
import { ProjectEvaluatorDrawer } from './ProjectEvaluatorDrawer'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { CreateAddEvaluator } from './CreateAddEvaluator'
import { EditEvaluator } from './EditEvaluator'
import { useEvaluatorsByProjectId } from '../../../hooks/useEvaluatorsByProjectId'

type Props = { formHook: any; isLoading: boolean }

export const ProjectImageEvaluator = ({ formHook, isLoading }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useEvaluatorsByProjectId(projectId || '')

  return (
    <>
      <Routes>
        <Route
          path="/evaluators/create"
          element={
            <Suspense
              fallback={
                <div className="d-flex justify-content-center ">
                  <Spinner></Spinner>
                </div>
              }
            >
              <CreateAddEvaluator></CreateAddEvaluator>
            </Suspense>
          }
        />

        <Route
          path="/evaluators/:evaluatorId"
          element={
            <Suspense
              fallback={
                <div className="d-flex justify-content-center">
                  <Spinner></Spinner>
                </div>
              }
            >
              <ProjectEvaluatorDrawer>
                <EditEvaluator></EditEvaluator>
              </ProjectEvaluatorDrawer>
            </Suspense>
          }
        />
      </Routes>

      <GroupHeader
        label="Evaluators"
        header={
          <div>
            {/*<Link className="me-2" to={`/project/${projectId}/evaluators/edit`}>
              <Button type="default">
                <p className="p-small mb-0">Edit Evaluators</p>
              </Button>
            </Link>*/}
            <Link className="" to={`/project/${projectId}/evaluators/create`}>
              <Button type="primary">
                <p className="p-small mb-0">Add evaluator</p>
              </Button>
            </Link>
          </div>
        }
      >
        <div className="container-fluid">
          <div className="row" style={{}}>
            {data?.map((item, index) => {
              return (
                <Link
                  to={`/project/${projectId}/evaluators/${item._id}`}
                  key={index}
                  className="col-12 py-4 text-decoration-none border-bottom"
                >
                  <div className="row text-black ">
                    <div className="col-4 ">
                      <p className="p-small ms-2">name</p>
                      <p className="fw-bold ms-2">{item.name}</p>
                    </div>
                    <div className="col-4 ">
                      <p className="p-small">email</p>
                      <p>{item.email}</p>
                    </div>
                    <div className="col-2 ">
                      <p className="p-small">invite status</p>
                      <p>{item.inviteSent ? 'Sent' : '-'}</p>
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                      <Button>Edit</Button>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </GroupHeader>
    </>
  )
}

/*
<div className="col-12 mb-3 bg-white mt-1 d-flex align-items-center">
  {/!*<FormTextField data={obj} formHook={formHook}></FormTextField>*!/}
  <div className="ps-2">
    <Button onClick={submit} className="mt-4" style={{ width: 100 }} type="primary" disabled={isLoading}>
      {!isLoading ? (
        'Save'
      ) : (
        <div className="d-flex justify-content-center mb-4">
          <Spinner size={3} style={{ color: 'white' }}></Spinner>
        </div>
      )}
    </Button>
  </div>
</div>*/
