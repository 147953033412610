export function QuestionBody(props) {
  const questionBodyStyle = {
    marginBottom: '1.5rem',
    maxWidth: '600px',
    fontSize: '18px',
    fontWeight: '400',
  }

  return (
    <div style={questionBodyStyle}>
      <p style={{ whiteSpace: 'pre-wrap' }}>{props.children}</p>
    </div>
  )
}
