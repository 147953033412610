import { useState } from 'react'
import { LanguageISOCode, TextQuestion } from '../../../Types'
import { SubmitButton } from './SubmitButton'
import { TextInput } from './TextInput'
import { QuestionTitle } from './QuestionTitle'
import { CheckOutlined } from '@ant-design/icons'
import { QuestionBody } from './QuestionBody'

export interface TextQuestionFormProps {
  language: LanguageISOCode
  question: TextQuestion
  onSubmit: (answer: string) => void
}

export function TextQuestionForm({ question, language, onSubmit }: TextQuestionFormProps) {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [value, setValue] = useState('')
  const [touched, setTouched] = useState(false)
  const fallbackMinLength = 0
  const fallbackMaxLength = 255

  const handleChange = (s: string) => {
    setValue(s)
    setTouched(true)
  }

  const submit = () => {
    const answer = value.trim()
    const minLength = question.minLength || fallbackMinLength
    const maxLength = question.maxLength || fallbackMaxLength

    const noValueErrorMsg = 'Empty answers cannot be submitted'
    const lengthErrorMsg = 'Answer must be between |MIN| and |MAX| characters'
      .replace('|MIN|', minLength.toString())
      .replace('|MAX|', maxLength.toString())

    if (question.required && !touched) {
      setErrorMsg(noValueErrorMsg)
    } else if (question.required) {
      if (minLength > answer.length || maxLength < answer.length) {
        setErrorMsg(lengthErrorMsg)
      } else if (answer === '') {
        setErrorMsg(noValueErrorMsg)
      } else {
        onSubmit(answer)
        setErrorMsg('')
      }
    } else if (maxLength < answer.length) {
      // disallow infinitely long answers in all cases
      setErrorMsg(lengthErrorMsg)
    } else {
      onSubmit(answer)
      setErrorMsg('')
    }
  }

  return (
    <div>
      <QuestionTitle>{question.title[language]}</QuestionTitle>
      <QuestionBody>{question.body ? question.body[language] : ''}</QuestionBody>
      <TextInput
        placeholder={question.placeholder ? question.placeholder[language] : ''}
        onChange={handleChange}
        value={value}
        autoFocus={true}
      />
      {errorMsg ? (
        <div className="mt-3" style={{ color: 'red' }}>
          {errorMsg}
        </div>
      ) : null}
      <SubmitButton onClick={submit}>
        {question.continuation[language]} <CheckOutlined style={{ marginLeft: '.5em', marginBottom: '2px' }} />
      </SubmitButton>
    </div>
  )
}
