/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/06/2022.
 */
import { Button } from 'antd'
import React, { useState } from 'react'

type Node = {
  title: string
  kids: number
}

const nodeItem = { title: 'start', kids: 1 }

export const FlowBuilder = () => {
  const [state, setState] = useState<Node[]>([nodeItem])

  const add = (options: number) => {
    const n: Node = { title: state.length + ' node', kids: options }
    const arr = state.concat([n])
    setState(arr)
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{}}></div>
      <div className="row" style={{}}>
        <div className="col-6 ">
          {state.map((item, index) => {
            return <MyNode key={index} data={item} add={add}></MyNode>
          })}
        </div>
      </div>
    </div>
  )
}

const MyNode = ({ data, add }) => {
  const col = 'col-' + 12 / data.kids
  const arr = Array(data.kids).fill('asdas')

  return (
    <div className="row" style={{}}>
      {arr.map((item, kidIndex) => {
        return (
          <div key={kidIndex} className={'p-4 bg-white mt-3 ' + col}>
            <h4>{data.title}</h4>

            <Button
              className="me-1"
              onClick={() => {
                add(1)
              }}
              size="small"
            >
              Add 1
            </Button>
            <Button
              className="me-1"
              onClick={() => {
                add(2)
              }}
              size="small"
            >
              Add 2
            </Button>
            <Button
              className="me-1"
              onClick={() => {
                add(3)
              }}
              size="small"
            >
              Add 3
            </Button>
          </div>
        )
      })}
    </div>
  )
}
