import React from 'react'
import { message, Popconfirm } from 'antd'
import { GroupHeader } from '../../components/display/GroupHeader'
import { FileOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { fileRemove, fileUpload } from './ProjectsAPI'
import { useProjectGetFiles } from '../../hooks/useProject'
import { UploadBtn } from '../../components/UploadBtn'
import { downloadFile } from '../../service/api-gateway'

export const ProjectFileUpload = () => {
  const params: any = useParams()

  const { data, mutate } = useProjectGetFiles(params.projectId || '')
  const [messageApi, contextHolder] = message.useMessage()

  const messageType = (type, content) => {
    messageApi.open({
      type: type,
      content: content,
      className: 'custom-class',
    })
  }

  const setSelectedFile = (file) => {
    if (!validateFile(file)) {
      console.log(' ProjectFileUpload >  file could not be validated  ')
      return
    }
    buildAndSendFormData(file)
  }

  const validateFile = (file: File) => {
    const isPDF = file.type === 'application/pdf'
    const fileSize = file.size
    const fileSizeInMB = fileSize / (1024 * 1024) // Convert bytes to megabytes

    if (!isPDF) {
      messageType('error', `${file.name} is not a pdf file`)
      return false
    }
    if (fileSizeInMB > 50) {
      messageType('warning', `File is above 50 MB`)
      return false
    }

    return true
  }

  const buildAndSendFormData = (file: any) => {
    const formData = new FormData()
    formData.append('originFile', file)

    fileUpload(params.projectId, formData)
      .then(() => {
        messageType('success', `File uploaded successfully`)
        mutate()
      })
      .catch((error) => {
        messageType('error', `Error while uploading file. ${error.message}`)
        mutate()
      })
  }

  const removeFile = (file: any) => {
    fileRemove(params.projectId, { fileName: file.name, fileUid: file.uid })
      .then(() => {
        message.success(`File removed successfully`)
        mutate()
      })
      .catch((error) => {
        message.error(`File could not be removed due to `, error)
        mutate()
      })
  }

  return (
    <GroupHeader label="Project file upload" header={<UploadBtn setFile={setSelectedFile}></UploadBtn>}>
      <div className="col-12 mb-3 mt-3 bg-white ">
        {contextHolder}

        <div className="row" style={{}}>
          {data.map((item, index) => {
            return <FileItem key={index} item={item} removeFile={removeFile} projectId={params.projectId} />
          })}
        </div>
      </div>
    </GroupHeader>
  )
}

const FileItem = ({ item, removeFile, projectId }) => {
  const deleteFileClick = () => {
    removeFile(item)
  }

  const viewFile = () => {
    const data = {
      projectId: projectId,
      fileUid: item.uid,
      fileName: item.name,
    }

    downloadFile(data)
      .then((result) => {
        if (result) window.open(result.data.signedURL)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  return (
    <div className="col-4 ">
      <div className="d-flex justify-content-between border py-4 ps-3 pe-4 mb-3">
        <div className="d-flex w-100">
          <FileOutlined style={{ fontSize: '1.5rem' }}></FileOutlined>
          <div className=" " style={{ maxWidth: '80%' }}>
            <p className="ms-2 mt-1 p-small text-truncate ">{item.name}</p>

            <div className="d-flex">
              <p className="ms-2 mt-1 p-small text-primary pointer noselect" onClick={viewFile}>
                View file
              </p>
              <p className="ms-2 mt-1 p-small pointer ">|</p>
              <Popconfirm title="Delete the file" okText="Yes" cancelText="cancel" onConfirm={deleteFileClick}>
                {/*<DeleteOutlined className="mt-1 opacity-75 " />*/}
                <p className="pointer ms-2 mt-1 p-small text-danger noselect">Delete </p>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
