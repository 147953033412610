import { GroupHeader } from '../../components/display/GroupHeader'
import { Button, Switch, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import {
  QUESTIONNAIRE_PAGE_EDIT,
  QUESTIONNAIRE_PAGE_FLOW,
  QUESTIONNAIRE_PAGE_STATS,
  QUESTIONNAIRE_PAGE_TEST,
} from '../../constants'
import { post } from '../../service/API'
import {
  createQuestionnaire_endpoint,
  duplicateQuestionnaire_endpoint,
  getProject_endpoint,
  getProjectSettings_endpoint,
  updateQuestionnaire,
} from '../../service/api-gateway'
import { mutate } from 'swr'
import { Questionnaire } from '../../Types'
import React from 'react'

const ProjectQuestionnaire = ({ project }) => {
  const handleCreateClick = () => {
    const name = prompt('Please enter a descriptive name for the questionnaire!')

    post(createQuestionnaire_endpoint, { projectId: project._id, name: name })
      .then((_) => mutate(getProject_endpoint(project._id)))
      .catch((err) => alert(err.message))
  }

  const handleDuplicateClick = (questionnaireId: string) => () => {
    const name = prompt('Please enter a descriptive name for the questionnaire!')

    post(duplicateQuestionnaire_endpoint(questionnaireId), { name: name })
      .then((_) => mutate(getProject_endpoint(project._id)))
      .catch((err) => alert(err.message))
  }

  const activateQuestionnaire = (questionnaireId: string) => (activate: boolean) => {
    // Need to activate first since we can't deactivate last questionnaire

    console.log(' ProjectQuestionnaire > activate = ', activate, questionnaireId)

    let questionnaire = project.questionnaires.filter((q: Questionnaire) => q._id === questionnaireId)[0]

    questionnaire.active = activate

    updateQuestionnaire(questionnaireId, questionnaire)
      .then((result) => {
        console.log('ProjectQuestionnaire > result = ', result)
        mutate(getProject_endpoint(project._id))
        mutate(getProjectSettings_endpoint(project._id))
        return result
      })
      .catch((error) => {
        console.log('ProjectQuestionnaire > error = ', error)
        return Promise.reject(error)
      })

    /* if (activate) {
      let questionnaire = project.questionnaires.filter((q: Questionnaire) => q._id === questionnaireId)[0]

      updateQuestionnaire(questionnaire._id, {
        ...questionnaire,
        active: true,
      })
        .then(() => {
          return Promise.all(
            project.questionnaires
              .filter((q: Questionnaire) => q._id !== questionnaireId)
              .map((q: Questionnaire) => {
                let newQuestionnaire = {
                  ...q,
                  active: false,
                }

                return updateQuestionnaire(q._id, newQuestionnaire)
              })
          )
        })
        .then(() => {
          mutate(getProject_endpoint(project._id))
          mutate(getProjectSettings_endpoint(project._id))
        })
        .catch((err) => alert(err.message))
    } else {
      Promise.all(
        project.questionnaires.map((q: Questionnaire) => {
          let isActive = q._id === questionnaireId && activate
          let newQuestionnaire = {
            ...q,
            active: isActive,
          }

          return updateQuestionnaire(q._id, newQuestionnaire)
        })
      )
        .then(() => {
          mutate(getProject_endpoint(project._id))
          mutate(getProjectSettings_endpoint(project._id))
        })
        .catch((err) => alert(err.message))
    }*/
  }

  /*const handleDeleteClick = (questionnaireId: string) => {
    const input = prompt(
      'You are about to permanently delete this questionnaire and ALL related data including questions, answers and enrollments. Are you absolutely sure this is what you want? type "confirm" below to complete this action, this cannot be undone!'
    )

    if (input?.toLowerCase() !== 'confirm') return

    deleteQuestionnaire(questionnaireId)
      .then((_: any) => mutate(getProject_endpoint(project._id)))
      .catch((error) => {
        console.error(error)
        alert(error.message)
      })
  }*/

  //const secondaryBtnColor = '#d7dfe5'
  const secondaryBtnColor = '#dee5ee'

  return (
    <GroupHeader
      label="Questionnaire"
      header={
        <Button type="primary" onClick={handleCreateClick}>
          Create Questionnaire
        </Button>
      }
    >
      <div className="col-12 mb-3 bg-white mt-3">
        {project.questionnaires.map((questionnaire: Questionnaire, index: number) => (
          <div key={index} className="mb-2 py-3 px-3 d-flex justify-content-between border align-items-center">
            <div className="d-flex align-items-center">
              <Tooltip overlay="Activate/Deactivate Questionnaire">
                <Switch checked={questionnaire.active === true} onChange={activateQuestionnaire(questionnaire._id)} />
              </Tooltip>
              <div className="">
                <p className="mx-3 p-mini opacity-50 mb-0">{questionnaire._id}</p>
                <p className="mx-3">{questionnaire.name}</p>
              </div>

              {!questionnaire.active ? (
                <div className="mr-3 px-1 pointer d-flex flex-column justify-content-center">
                  {/*<Tooltip overlay="Delete Questionnaire">
                    <Button
                      danger
                      onClick={(e) => handleDeleteClick(questionnaire._id)}
                      style={{
                        border: 'none',
                        padding: '0',
                        marginBottom: '2px',
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: '1.5rem' }} />
                    </Button>
                  </Tooltip>*/}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="d-flex">
              {questionnaire.questions.length > 0 && questionnaire.end && questionnaire.start ? (
                <>
                  <div className="me-2 pointer">
                    <Tooltip overlay="Duplicate This Questionnaire">
                      <Link className="text-decoration-none" onClick={handleDuplicateClick(questionnaire._id)} to=".">
                        <Button className="d-flex">
                          {/*<CopyOutlined className="me-1" style={{ fontSize: '1.2rem' }} />*/}
                          <p className="text-decoration-none fw-bold p-small">Duplicate</p>
                        </Button>
                      </Link>
                    </Tooltip>
                  </div>

                  <div className="me-2  pointer ">
                    <Tooltip overlay="Questionnaire Statistics">
                      <Link
                        className="text-decoration-none d-flex"
                        to={QUESTIONNAIRE_PAGE_STATS.to
                          .replace(':projectId', project._id)
                          .replace(':questionnaireId', questionnaire._id)}
                      >
                        <Button>
                          <p className="text-decoration-none p-small fw-bold">View Stats</p>
                        </Button>

                        {/*<LineChartOutlined style={{ fontSize: '1.5rem' }} />*/}
                      </Link>
                    </Tooltip>
                  </div>

                  <div className="mr-3 pointer">
                    <Tooltip overlay="Test Questionnaire">
                      <Link
                        to={QUESTIONNAIRE_PAGE_TEST.to
                          .replace(':questionnaireId', questionnaire._id)
                          .replace(':language', project.languages[0])}
                      >
                        {/*<DoubleRightOutlined style={{ fontSize: '1.5rem' }} />*/}
                        <Button>
                          <p className="text-decoration-none p-small fw-bold">Test flow</p>
                        </Button>
                      </Link>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="mx-3 opacity-50">|</div>

              {questionnaire.active === true ? (
                <></>
              ) : (
                <div className="me-2 pointer">
                  <Tooltip overlay="Edit Question Order">
                    <Link
                      to={QUESTIONNAIRE_PAGE_FLOW.to
                        .replace(':projectId', project._id)
                        .replace(':questionnaireId', questionnaire._id)}
                    >
                      {/*<ApartmentOutlined style={{ fontSize: '1.5rem' }} />*/}
                      {/*style={{ background: '#6a3ce0' }}*/}
                      {/*#FFC301  #3ce0af*/}
                      <Button style={{ background: secondaryBtnColor }}>
                        <p className=" text-decoration-none p-small fw-bold">Change order</p>
                      </Button>
                    </Link>
                  </Tooltip>
                </div>
              )}

              <div className="mr-3 px-1 pointer">
                <Tooltip overlay="Edit Questions">
                  <Link
                    to={QUESTIONNAIRE_PAGE_EDIT.to
                      .replace(':projectId', project._id)
                      .replace(':questionnaireId', questionnaire._id)}
                  >
                    {/*<EditOutlined style={{ fontSize: '1.5rem' }} />*/}
                    {/*#e0933c*/}
                    <Button style={{ background: secondaryBtnColor }}>
                      <p className=" text-decoration-none p-small fw-bold">Edit Questions</p>
                    </Button>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
    </GroupHeader>
  )
}
export default ProjectQuestionnaire
