import { useEffect, useState } from 'react'
import { LanguageISOCode, Question } from '../../../Types'
import { QuestionForm } from './QuestionForm'
import { ProgressBar } from './ProgressBar'
import { useNavigate, useParams } from 'react-router'
import { getQuestionnaireFirstQuestion, submitTestAnswerAndGetNext } from '../../../service/api-gateway'

export const QuestionnaireTester = () => {
  const navigate = useNavigate()
  const onQuestionnaireEnd = () => navigate('/projects')
  const { language, questionnaireId } = useParams() as { language: LanguageISOCode; questionnaireId: string }
  const [{ question, progress }, setState] = useState<QuestionnaireTesterState>({ progress: 0 })

  useEffect(() => {
    getQuestionnaireFirstQuestion(questionnaireId)
      .then((result: ApiReturn<EnrollmentQuestionnaireStatus>) =>
        setState({
          question: result.data.nextQuestion,
          progress: result.data.progress,
        })
      )
      .catch((err) => alert(err.message))
  }, [setState, questionnaireId])

  const onSubmit = (answerValue: string) => {
    if (progress === 1) {
      return onQuestionnaireEnd()
    }

    let q = question!

    let answer = {
      questionId: q._id,
      value: answerValue,
    }

    submitTestAnswerAndGetNext(questionnaireId, answer)
      .then((data) => {
        console.log('resp', data)
        return data
      })
      .then(({ data: { nextQuestion, progress } }: ApiReturn<EnrollmentQuestionnaireStatus>) => {
        setState({
          question: nextQuestion,
          progress: progress,
        })
      })
      .catch((err) => {
        alert(err.message)
        console.error(err)
      })
  }

  return question !== null && question !== undefined ? (
    <div
      style={{
        height: '80%',
      }}
    >
      <ProgressBar progress={progress} />
      <div className="container mx-auto col-12 col-md-8" style={{ marginBottom: '10em', marginTop: '7em' }}>
        <div className="d-flex flex-column justify-content-center" style={{ minHeight: '50vh' }}>
          <QuestionForm
            language={language}
            question={question}
            onSubmit={onSubmit}
            key={question._id}
            isEndScreen={progress === 1}
          />
        </div>
      </div>
    </div>
  ) : (
    <>Loading</>
  )
}

interface QuestionnaireTesterState {
  question?: Question
  progress: number
}

interface ApiReturn<T> {
  result: boolean
  data: T
  type?: string
}

interface EnrollmentQuestionnaireStatus {
  nextQuestion: Question
  progress: number
}
